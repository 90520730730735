
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-dark: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(75, 75, 75, 0.7) !default;
$emu-semantic-colors-actions-primary-light: #001450 !default;
$emu-semantic-colors-actions-primary-dark: #001450 !default;
$emu-semantic-colors-actions-primary-default-light: #001450 !default;
$emu-semantic-colors-actions-primary-default-dark: #001450 !default;
$emu-semantic-colors-actions-primary-hover-light: #A6DDEA !default;
$emu-semantic-colors-actions-primary-hover-dark: #001450 !default;
$emu-semantic-colors-actions-primary-active-light: #001450 !default;
$emu-semantic-colors-actions-primary-active-dark: #001450 !default;
$emu-semantic-colors-actions-primary-focus-light: #001450 !default;
$emu-semantic-colors-actions-primary-focus-dark: #001450 !default;
$emu-semantic-colors-actions-primary-disabled-light: #001450 !default;
$emu-semantic-colors-actions-primary-disabled-dark: #001450 !default;
$emu-semantic-colors-actions-primary-error-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-error-dark: #FF7F7A !default;
$emu-semantic-colors-actions-on-primary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-primary-dark: #000000 !default;
$emu-semantic-colors-actions-secondary-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-dark: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-default-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-default-dark: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-hover-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-hover-dark: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-active-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-active-dark: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-focus-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-focus-dark: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-disabled-light: #00AFD0 !default;
$emu-semantic-colors-actions-secondary-disabled-dark: #00AFD0 !default;
$emu-semantic-colors-actions-on-secondary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-secondary-dark: #000000 !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-0: #FFFFFF !default;
$emu-semantic-colors-primary-50: #D3EFF4 !default;
$emu-semantic-colors-primary-100: #A6DDEA !default;
$emu-semantic-colors-primary-200: #A6DDEA1A !default;
$emu-semantic-colors-primary-300: #00AFC8 !default;
$emu-semantic-colors-primary-400: #00AEC7 !default;
$emu-semantic-colors-primary-500: #00AFD0 !default;
$emu-semantic-colors-primary-600: #00305C !default;
$emu-semantic-colors-primary-700: #001451 !default;
$emu-semantic-colors-primary-800: #3A2522 !default;
$emu-semantic-colors-primary-900: #1D1211 !default;
$emu-semantic-colors-primary-950: #100A09 !default;
$emu-semantic-colors-primary-light: #DEEEF2 !default;
$emu-semantic-colors-primary-dark: #001450 !default;
$emu-semantic-colors-secondary-50: #4EACA7 !default;
$emu-semantic-colors-secondary-100: #FDFCFC !default;
$emu-semantic-colors-secondary-200: #F4EBEF !default;
$emu-semantic-colors-secondary-300: #EDDEE4 !default;
$emu-semantic-colors-secondary-400: #E6D1D9 !default;
$emu-semantic-colors-secondary-500: #DDC1CC !default;
$emu-semantic-colors-secondary-600: #C08CA1 !default;
$emu-semantic-colors-secondary-700: #A15876 !default;
$emu-semantic-colors-secondary-800: #693A4D !default;
$emu-semantic-colors-secondary-900: #351D26 !default;
$emu-semantic-colors-secondary-950: #1A0E13 !default;
$emu-semantic-colors-secondary-light: #2FB9A3 !default;
$emu-semantic-colors-secondary-dark: #23576D !default;
$emu-semantic-colors-tertiary-50: #E3EBFC !default;
$emu-semantic-colors-tertiary-100: #BED1F9 !default;
$emu-semantic-colors-tertiary-200: #6A97F1 !default;
$emu-semantic-colors-tertiary-300: #1B60E9 !default;
$emu-semantic-colors-tertiary-400: #0F3D9A !default;
$emu-semantic-colors-tertiary-500: #071D49 !default;
$emu-semantic-colors-tertiary-600: #06183C !default;
$emu-semantic-colors-tertiary-700: #04112A !default;
$emu-semantic-colors-tertiary-800: #030B1C !default;
$emu-semantic-colors-tertiary-900: #01060E !default;
$emu-semantic-colors-tertiary-950: #010409 !default;
$emu-semantic-colors-tertiary-light: #63666A !default;
$emu-semantic-colors-tertiary-dark: #6A97F1 !default;
$emu-semantic-colors-error-50: #FFF5F5 !default;
$emu-semantic-colors-error-100: #FFDCDB !default;
$emu-semantic-colors-error-200: #FFABA8 !default;
$emu-semantic-colors-error-300: #FF7F7A !default;
$emu-semantic-colors-error-400: #FF4E47 !default;
$emu-semantic-colors-error-500: #FF1D15 !default;
$emu-semantic-colors-error-600: #DB0700 !default;
$emu-semantic-colors-error-700: #A30500 !default;
$emu-semantic-colors-error-800: #700400 !default;
$emu-semantic-colors-error-900: #380200 !default;
$emu-semantic-colors-error-950: #190100 !default;
$emu-semantic-colors-error-light: #FF1D15 !default;
$emu-semantic-colors-error-dark: #FFABA8 !default;
$emu-semantic-colors-background-light: #FAF6F5 !default;
$emu-semantic-colors-background-dark: #F2D9D0 !default;
$emu-semantic-colors-brand-yellow-50: #C29538 !default;
$emu-semantic-font-weight-200: 200 !default;
$emu-semantic-font-weight-325: 325 !default;
$emu-semantic-font-weight-350: 350 !default;
$emu-semantic-font-weight-500: 500 !default;
$emu-semantic-font-weight-600: 600 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'Montserrat', sans-serif !default;
$emu-semantic-font-families-heading: 'collier', sans-serif !default;
$emu-semantic-font-families-mono: 'Montserrat', sans-serif !default;
$emu-semantic-font-families-collier-italic: 'collier', sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 34px !default;
$emu-semantic-spacing-one-line-height-narrow: 26.82px !default;
$emu-semantic-spacing-two-line-height-wide: calc(34px*2) !default;
$emu-semantic-spacing-two-line-height-narrow: calc(26.82px*2) !default;
$emu-semantic-spacing-three-line-height-wide: calc(34px*3) !default;
$emu-semantic-spacing-three-line-height-narrow: calc(26.82px*3) !default;
$emu-semantic-spacing-rem-horizontal: 0px !default;
$emu-semantic-spacing-horizontal-narrow: 0px !default;
$emu-semantic-spacing-horizontal-wide: 0px !default;
$emu-semantic-spacing-horizontal-onerem-narrow: 1rem !default;
$emu-semantic-spacing-horizontal-onerem-wide: 1rem !default;
$emu-semantic-spacing-horizontal-tworem-narrow: calc(1rem*2) !default;
$emu-semantic-spacing-horizontal-tworem-wide: calc(1rem*2) !default;
$emu-semantic-spacing-rem-vertical: 0px !default;
$emu-semantic-spacing-vertical-narrow: 0px !default;
$emu-semantic-spacing-vertical-wide: 0px !default;
$emu-semantic-spacing-half-line-height-wide: calc(34px*.5) !default;
$emu-semantic-spacing-half-line-height-narrow: calc(26.82px*.5) !default;
$emu-semantic-spacing-quarter-line-height-wide: calc(34px*.25) !default;
$emu-semantic-spacing-quarter-line-height-narrow: calc(26.82px*.25) !default;
$emu-semantic-font-sizes-narrow-medium: 20px !default;
$emu-semantic-font-sizes-narrow-large: 25.00px !default;
$emu-semantic-font-sizes-narrow-xl: 22px !default;
$emu-semantic-font-sizes-narrow-xxl: 20px !default;
$emu-semantic-font-sizes-narrow-xxxl: 28px !default;
$emu-semantic-font-sizes-narrow-small: 22px !default;
$emu-semantic-font-sizes-narrow-xs: 10px !default;
$emu-semantic-font-sizes-wide-medium: 22.50px !default;
$emu-semantic-font-sizes-wide-large: 28.13px !default;
$emu-semantic-font-sizes-wide-xl: 28px !default;
$emu-semantic-font-sizes-wide-xxl: 30px !default;
$emu-semantic-font-sizes-wide-xxxl: 48px !default;
$emu-semantic-font-sizes-wide-small: 28px !default;
$emu-semantic-font-sizes-wide-xs: 14px !default;
$emu-semantic-line-heights-narrow-large: calc(26.82px*1.5) !default;
$emu-semantic-line-heights-narrow-medium: calc(26.82px*1.5) !default;
$emu-semantic-line-heights-narrow-xl: 26.82px !default;
$emu-semantic-line-heights-narrow-xxl: 24px !default;
$emu-semantic-line-heights-narrow-xxxl: 33.6px !default;
$emu-semantic-line-heights-narrow-small: 26.82px !default;
$emu-semantic-line-heights-narrow-xs: 12.19px !default;
$emu-semantic-line-heights-wide-large: calc(34px*1.5) !default;
$emu-semantic-line-heights-wide-medium: calc(34px*1.5) !default;
$emu-semantic-line-heights-wide-xl: 34px !default;
$emu-semantic-line-heights-wide-xxl: 34px !default;
$emu-semantic-line-heights-wide-xxxl: 50px !default;
$emu-semantic-line-heights-wide-small: 34px !default;
$emu-semantic-line-heights-wide-xs: 17.07px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 120px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1280px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-narrow: 20px !default;
$emu-semantic-sizing-one-line-height-wide: 20px !default;
$emu-semantic-sizing-two-line-height-narrow: calc(20px*2) !default;
$emu-semantic-sizing-two-line-height-wide: calc(20px*2) !default;
$emu-semantic-sizing-three-line-height-narrow: calc(20px*3) !default;
$emu-semantic-sizing-three-line-height-wide: calc(20px*3) !default;
$emu-semantic-sizing-max-width-text-narrow: 40em !default;
$emu-semantic-sizing-max-width-text-wide: 55em !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 28px/33.6px 'collier', sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 200 20px/24px 'collier', sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 22px/26.82px 'Montserrat', sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 400 25.00px/calc(26.82px*1.5) 'collier', sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 700 20px/calc(26.82px*1.5) 'collier', sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 22px/26.82px 'Montserrat', sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 400 48px/50px 'collier', sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 200 30px/34px 'collier', sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 28px/34px 'Montserrat', sans-serif !default;
$emu-semantic-typography-wide-headings-large: 400 28.13px/calc(34px*1.5) 'collier', sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 700 22.50px/calc(34px*1.5) 'collier', sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 28px/34px 'Montserrat', sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-primary-default-light,
            'dark': $emu-semantic-colors-actions-primary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-primary-hover-light,
            'dark': $emu-semantic-colors-actions-primary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-primary-active-light,
            'dark': $emu-semantic-colors-actions-primary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-primary-focus-light,
            'dark': $emu-semantic-colors-actions-primary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-primary-disabled-light,
            'dark': $emu-semantic-colors-actions-primary-disabled-dark
          ),
          'error': (
            'light': $emu-semantic-colors-actions-primary-error-light,
            'dark': $emu-semantic-colors-actions-primary-error-dark
          )
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        ),
        'secondary': (
          'light': $emu-semantic-colors-actions-secondary-light,
          'dark': $emu-semantic-colors-actions-secondary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-secondary-default-light,
            'dark': $emu-semantic-colors-actions-secondary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-secondary-hover-light,
            'dark': $emu-semantic-colors-actions-secondary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-secondary-active-light,
            'dark': $emu-semantic-colors-actions-secondary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-secondary-focus-light,
            'dark': $emu-semantic-colors-actions-secondary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-secondary-disabled-light,
            'dark': $emu-semantic-colors-actions-secondary-disabled-dark
          )
        ),
        'on-secondary': (
          'light': $emu-semantic-colors-actions-on-secondary-light,
          'dark': $emu-semantic-colors-actions-on-secondary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        '0': $emu-semantic-colors-primary-0,
        '50': $emu-semantic-colors-primary-50,
        '100': $emu-semantic-colors-primary-100,
        '200': $emu-semantic-colors-primary-200,
        '300': $emu-semantic-colors-primary-300,
        '400': $emu-semantic-colors-primary-400,
        '500': $emu-semantic-colors-primary-500,
        '600': $emu-semantic-colors-primary-600,
        '700': $emu-semantic-colors-primary-700,
        '800': $emu-semantic-colors-primary-800,
        '900': $emu-semantic-colors-primary-900,
        '950': $emu-semantic-colors-primary-950,
        'light': $emu-semantic-colors-primary-light,
        'dark': $emu-semantic-colors-primary-dark
      ),
      'secondary': (
        '50': $emu-semantic-colors-secondary-50,
        '100': $emu-semantic-colors-secondary-100,
        '200': $emu-semantic-colors-secondary-200,
        '300': $emu-semantic-colors-secondary-300,
        '400': $emu-semantic-colors-secondary-400,
        '500': $emu-semantic-colors-secondary-500,
        '600': $emu-semantic-colors-secondary-600,
        '700': $emu-semantic-colors-secondary-700,
        '800': $emu-semantic-colors-secondary-800,
        '900': $emu-semantic-colors-secondary-900,
        '950': $emu-semantic-colors-secondary-950,
        'light': $emu-semantic-colors-secondary-light,
        'dark': $emu-semantic-colors-secondary-dark
      ),
      'tertiary': (
        '50': $emu-semantic-colors-tertiary-50,
        '100': $emu-semantic-colors-tertiary-100,
        '200': $emu-semantic-colors-tertiary-200,
        '300': $emu-semantic-colors-tertiary-300,
        '400': $emu-semantic-colors-tertiary-400,
        '500': $emu-semantic-colors-tertiary-500,
        '600': $emu-semantic-colors-tertiary-600,
        '700': $emu-semantic-colors-tertiary-700,
        '800': $emu-semantic-colors-tertiary-800,
        '900': $emu-semantic-colors-tertiary-900,
        '950': $emu-semantic-colors-tertiary-950,
        'light': $emu-semantic-colors-tertiary-light,
        'dark': $emu-semantic-colors-tertiary-dark
      ),
      'error': (
        '50': $emu-semantic-colors-error-50,
        '100': $emu-semantic-colors-error-100,
        '200': $emu-semantic-colors-error-200,
        '300': $emu-semantic-colors-error-300,
        '400': $emu-semantic-colors-error-400,
        '500': $emu-semantic-colors-error-500,
        '600': $emu-semantic-colors-error-600,
        '700': $emu-semantic-colors-error-700,
        '800': $emu-semantic-colors-error-800,
        '900': $emu-semantic-colors-error-900,
        '950': $emu-semantic-colors-error-950,
        'light': $emu-semantic-colors-error-light,
        'dark': $emu-semantic-colors-error-dark
      ),
      'background': (
        'light': $emu-semantic-colors-background-light,
        'dark': $emu-semantic-colors-background-dark
      ),
      'brandYellow': (
        '50': $emu-semantic-colors-brand-yellow-50
      )
    ),
    'fontWeight': (
      '200': $emu-semantic-font-weight-200,
      '325': $emu-semantic-font-weight-325,
      '350': $emu-semantic-font-weight-350,
      '500': $emu-semantic-font-weight-500,
      '600': $emu-semantic-font-weight-600,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono,
      'collierItalic': $emu-semantic-font-families-collier-italic
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      ),
      'twoLineHeight': (
        'wide': $emu-semantic-spacing-two-line-height-wide,
        'narrow': $emu-semantic-spacing-two-line-height-narrow
      ),
      'threeLineHeight': (
        'wide': $emu-semantic-spacing-three-line-height-wide,
        'narrow': $emu-semantic-spacing-three-line-height-narrow
      ),
      '_rem_horizontal': $emu-semantic-spacing-rem-horizontal,
      'horizontal': (
        'narrow': $emu-semantic-spacing-horizontal-narrow,
        'wide': $emu-semantic-spacing-horizontal-wide,
        'onerem': (
          'narrow': $emu-semantic-spacing-horizontal-onerem-narrow,
          'wide': $emu-semantic-spacing-horizontal-onerem-wide
        ),
        'tworem': (
          'narrow': $emu-semantic-spacing-horizontal-tworem-narrow,
          'wide': $emu-semantic-spacing-horizontal-tworem-wide
        )
      ),
      '_rem_vertical': $emu-semantic-spacing-rem-vertical,
      'vertical': (
        'narrow': $emu-semantic-spacing-vertical-narrow,
        'wide': $emu-semantic-spacing-vertical-wide
      ),
      'halfLineHeight': (
        'wide': $emu-semantic-spacing-half-line-height-wide,
        'narrow': $emu-semantic-spacing-half-line-height-narrow
      ),
      'quarterLineHeight': (
        'wide': $emu-semantic-spacing-quarter-line-height-wide,
        'narrow': $emu-semantic-spacing-quarter-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl,
        'small': $emu-semantic-font-sizes-narrow-small,
        'xs': $emu-semantic-font-sizes-narrow-xs
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl,
        'small': $emu-semantic-font-sizes-wide-small,
        'xs': $emu-semantic-font-sizes-wide-xs
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl,
        'small': $emu-semantic-line-heights-narrow-small,
        'xs': $emu-semantic-line-heights-narrow-xs
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl,
        'small': $emu-semantic-line-heights-wide-small,
        'xs': $emu-semantic-line-heights-wide-xs
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'narrow': $emu-semantic-sizing-one-line-height-narrow,
        'wide': $emu-semantic-sizing-one-line-height-wide
      ),
      'twoLineHeight': (
        'narrow': $emu-semantic-sizing-two-line-height-narrow,
        'wide': $emu-semantic-sizing-two-line-height-wide
      ),
      'threeLineHeight': (
        'narrow': $emu-semantic-sizing-three-line-height-narrow,
        'wide': $emu-semantic-sizing-three-line-height-wide
      ),
      'maxWidthText': (
        'narrow': $emu-semantic-sizing-max-width-text-narrow,
        'wide': $emu-semantic-sizing-max-width-text-wide
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
