@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

// use function instead of mixin to calculate z-index, ie: z-index: z-index(ccpa + 20)
@function z-index($key) {
  @return map-deep-get($common, common, other, zIndex, $key);
}

@function breakpoints() {
  @return map-deep-get($semantic, semantic, sizing, breakpoints);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin max-container {
  max-width: var(
    --emu-common-sizing-container
  ) !important; // needed to override any default styles
  margin-left: auto !important; // needed to override any default styles
  margin-right: auto !important; // needed to override any default styles
  float: unset !important; // needed to override any default styles
}
