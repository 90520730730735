#resources {
  .ns-resources {
    &__banner {
      .content-container {
        display: none;
      }
    }

    &__heading {
      padding-top: 52px; //to match figma
      padding-right: 19px;
      padding-bottom: 54px; //to match figma
      padding-left: 19px;

      @include mq('large') {
        padding-top: 49px;
      }

      &-text {
        .cmp-title__text {
          color: var(--emu-semantic-colors-primary-dark);
          font-family: var(--emu-semantic-font-families-heading);
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          font-weight: var(--emu-common-font-weight-light);
          line-height: 33px;
          letter-spacing: 1px; //to match figma
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            letter-spacing: 1.6px; //to match figma
            text-transform: none;
            line-height: 49px;
          }
        }

        &--secondary {
          .cmp-title__text {
            color: var(--emu-semantic-colors-primary-500);
          }
        }

        sup {
          font-size: 24px; //to match figma
          top: 7px; // to match figma
          left: -2px;

          @include mq('large') {
            font-size: 40px; //to match figma
          }
        }
      }
    }

    &__cards {
      padding-left: 22px;
      padding-bottom: 15px;
      padding-right: 7px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;

      @include mq('large') {
        padding-left: 48px;
        padding-bottom: 20px;
        padding-right: 18px;
      }

      .card {
        margin-right: 15px;
        margin-bottom: 15px;

        @include mq('large') {
          margin-right: 30px;
          margin-bottom: 30px;
        }
      }

      @include mq('xx-large') {
        justify-content: left;
      }

      .card {
        display: flex;

        .emu-card {
          &__wrapper {
            border: var(--emu-semantic-border-width-thin) solid #00afd04d; //used once
            max-width: 331px;

            @include mq('large') {
              height: 100%;
              max-width: 428px;
              min-height: 668px;
              padding-top: 29px;
              padding-right: 29px;
              padding-bottom: 40px;
              padding-left: 29px;
            }
          }

          &__title-wrapper {
            display: none;
          }

          &__content-wrapper {
            display: flex;
            height: 100%;

            > div {
              display: flex;
              flex-direction: column;
            }
          }

          &__footer {
            margin-top: auto;

            .button {
              text-align: center;

              @include mq('large') {
                text-align: left;
              }

              &:not(:last-child) {
                margin-bottom: 10px;
              }

              span {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }

      &-card {
        display: flex;
        height: 100%;
      }

      &-card-img {
        margin-bottom: 13px; //to match figma
      }

      &-card-title {
        margin-bottom: 12px; //to match figma

        @include mq('large') {
          margin-top: 22px; //to match figma
          margin-bottom: 31px;
        }

        .cmp-title__text {
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: 260;

          sup {
            font-size: 20px;
            top: 5px;
          }
        }
      }

      &-card-desc {
        &__mt-lg {
          @include mq('large') {
            margin-top: var(--emu-common-spacing-xl);
          }
        }

        p {
          color: var(--emu-semantic-colors-tertiary-light);
          font-family: var(--emu-semantic-font-families-body);
          font-size: 14px;
          font-weight: var(--emu-common-font-weight-regular);
          line-height: 17.07px;
          text-transform: capitalize;
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: 20px;
            line-height: var(--emu-common-line-heights-wide-large);
            text-transform: none;
          }
        }
      }

      &-card-btns-wrapper {
        margin-top: 17px;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &-card-btn {
        min-width: 180px;
        justify-content: center;
        align-items: center;

        .cmp-button__text {
          font-family: var(--emu-semantic-font-families-body);
          font-size: 16px;
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 14px;
          text-align: center;
        }

        &--multi-text {
          height: 40px;
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-xxs);

          > span {
            padding-right: var(--emu-common-spacing-xs);
          }

          .cmp-button__text {
            display: block;
            font-size: 16px;
            line-height: 15px;
          }

          .cmp-button__icon-text {
            font-size: 13px; //to match figma
            line-height: 12px; //to match figma
            text-align: center;
          }
        }

        &:hover {
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        }

        &:active {
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }

    &__learn-more {
      padding-top: 23px;
      padding-right: 49px;
      padding-bottom: 25px;
      padding-left: 49px;
      font-family: var(--emu-semantic-font-families-heading);
      font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
      font-weight: 260;
      line-height: var(--emu-semantic-line-heights-narrow-xxxl);

      @include mq('large') {
        padding-top: 58px;
        padding-right: 40px;
        padding-bottom: 60px;
        padding-left: 40px;
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        letter-spacing: -0.8px;
      }

      p,
      li,
      ul,
      ol {
        margin-bottom: var(--emu-common-spacing-none);
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-family: inherit;

        @include mq('large') {
          max-width: 824px; // from live site
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
