footer {
  .upper_section > .footer__logo {
    display: none;
  }

  .footer {
    &__container {
      isolation: isolate;
      background-color: var(--emu-semantic-colors-primary-dark);
      padding-top: 40px;
      padding-right: 20px;
      padding-bottom: 40px;
      padding-left: 20px;

      @include mq('large') {
        padding-top: 70px;
        padding-right: 85px;
        padding-bottom: 150px;
        padding-left: 86px;
      }

      .text {
        &:not(:last-of-type) {
          margin-bottom: 20px;

          @include mq('large') {
            margin-bottom: 12px;
          }
        }
      }

      .emu-navigation__content-wrapper {
        position: static; //to overwrite default position absolute

        nav {
          a {
            border: none;

            &:hover {
              background-color: var(--emu-common-colors-transparent);
            }
          }
        }
      }
    }

    &__logo {
      margin-bottom: var(--emu-common-spacing-medium);
      text-align: center;

      @include mq('large') {
        margin-bottom: 36px;
        text-align: left;
      }

      a {
        display: inline-block;
      }

      img {
        margin-right: auto;
        margin-left: auto;

        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
        }
      }
    }

    &__content {
      p {
        font-family: var(--emu-semantic-font-families-mono);
        font-size: var(--emu-common-line-heights-narrow-large);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
        letter-spacing: -0.5px; //to match figma
        margin-bottom: var(--emu-common-spacing-none);
        text-align: center;

        @include mq('large') {
          font-size: 24px;
          line-height: 29.26px;
          text-align: left;
          letter-spacing: normal;
        }
      }
    }

    &__content-call {
      p {
        letter-spacing: normal;
      }
    }

    &__content-trademarks {
      br {
        &:first-child {
          @include mq('large') {
            display: none;
          }
        }
      }
    }

    &__navigation {
      z-index: var(--emu-common-other-z-index-base);
      background-color: var(--emu-semantic-colors-primary-dark);

      ul {
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
        }
      }

      li {
        margin-bottom: 5px;

        &:not(:last-of-type) {
          @include mq('large') {
            border-right: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-white);
          }
        }

        &:not(:first-of-type) {
          a {
            @include mq('large') {
              padding-left: 9px;
            }
          }
        }

        &:not(:last-of-type) {
          a {
            @include mq('large') {
              padding-right: 9px;
            }
          }
        }

        a {
          font-family: var(--emu-semantic-font-families-mono);
          font-size: var(--emu-common-line-heights-narrow-large);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: var(--emu-common-line-heights-wide-large);
          text-align: center;
          text-decoration: underline;

          @include mq('large') {
            font-size: 24px;
            line-height: 29.26px;
            text-align: left;
          }
        }
      }
    }
  }
}
