@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
@font-face {
  font-family: Collier-Bold;
  src: url("resources/fonts/Collier-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Collier-Book;
  src: url("resources/fonts/Collier-Book.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Collier-Extra-Condensed-Italic;
  src: url("resources/fonts/Collier-Extra-Condensed-Italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Gotham-Bold;
  src: url("Gotham-Bold.ed446654.otf") format("otf");
  font-display: swap;
}

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham-Book.fe034cb5.otf") format("otf");
  font-display: swap;
}

html, body {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-regular);
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1024px) {
  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 1024px) {
  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

sup {
  vertical-align: super;
  top: var(--emu-common-spacing-none);
  font-size: 50%;
  line-height: 0;
  position: relative;
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1440px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-modal-backdrop: #4b4b4bb3;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: #d3eff4;
  --emu-semantic-colors-primary-100: #a6ddea;
  --emu-semantic-colors-primary-200: #a6ddea1a;
  --emu-semantic-colors-primary-300: #00afc8;
  --emu-semantic-colors-primary-400: #00aec7;
  --emu-semantic-colors-primary-500: #00afd0;
  --emu-semantic-colors-primary-600: #00305c;
  --emu-semantic-colors-primary-700: #001451;
  --emu-semantic-colors-primary-800: var(--emu-common-colors-medium-brown-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-medium-brown-900);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: #deeef2;
  --emu-semantic-colors-primary-dark: #001450;
  --emu-semantic-colors-secondary-50: #4eaca7;
  --emu-semantic-colors-secondary-100: var(--emu-common-colors-blush-100);
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-blush-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-blush-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: #2fb9a3;
  --emu-semantic-colors-secondary-dark: #23576d;
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: #63666a;
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-brand-yellow-50: #c29538;
  --emu-semantic-font-weight-200: 200;
  --emu-semantic-font-weight-325: 325;
  --emu-semantic-font-weight-350: 350;
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-600: 600;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "Montserrat", sans-serif;
  --emu-semantic-font-families-heading: "collier", sans-serif;
  --emu-semantic-font-families-mono: "Montserrat", sans-serif;
  --emu-semantic-font-families-collier-italic: "collier", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 20px;
  --emu-semantic-font-sizes-narrow-large: 25px;
  --emu-semantic-font-sizes-narrow-xl: 22px;
  --emu-semantic-font-sizes-narrow-xxl: 20px;
  --emu-semantic-font-sizes-narrow-xxxl: 28px;
  --emu-semantic-font-sizes-narrow-small: 22px;
  --emu-semantic-font-sizes-narrow-xs: 10px;
  --emu-semantic-font-sizes-wide-medium: 22.5px;
  --emu-semantic-font-sizes-wide-large: 28.13px;
  --emu-semantic-font-sizes-wide-xl: 28px;
  --emu-semantic-font-sizes-wide-xxl: 30px;
  --emu-semantic-font-sizes-wide-xxxl: 48px;
  --emu-semantic-font-sizes-wide-small: 28px;
  --emu-semantic-font-sizes-wide-xs: 14px;
  --emu-semantic-line-heights-narrow-large: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-medium: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-xl: 26.82px;
  --emu-semantic-line-heights-narrow-xxl: 24px;
  --emu-semantic-line-heights-narrow-xxxl: 33.6px;
  --emu-semantic-line-heights-narrow-small: 26.82px;
  --emu-semantic-line-heights-narrow-xs: 12.19px;
  --emu-semantic-line-heights-wide-large: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-medium: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-xl: 34px;
  --emu-semantic-line-heights-wide-xxl: 34px;
  --emu-semantic-line-heights-wide-xxxl: 50px;
  --emu-semantic-line-heights-wide-small: 34px;
  --emu-semantic-line-heights-wide-xs: 17.07px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-200) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-200) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: 20px;
  --emu-component-cards-card-padding-top-wide: 20px;
  --emu-component-cards-card-padding-right-narrow: 20px;
  --emu-component-cards-card-padding-right-wide: 20px;
  --emu-component-cards-card-padding-bottom-narrow: 18px;
  --emu-component-cards-card-padding-bottom-wide: 19px;
  --emu-component-cards-card-padding-left-narrow: 20px;
  --emu-component-cards-card-padding-left-wide: 20px;
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-none) var(--emu-common-spacing-none);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.6667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: 0px 22px;
  --emu-component-lists-accordion-margin-wide: 0px 117px;
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: 10px 0px 0px;
  --emu-component-lists-accordion-item-body-padding-wide: 2px 0px 0px;
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: 9px 11px 0px 18px;
  --emu-component-lists-accordion-item-header-padding-wide: 24px 22px 0px 40px;
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 30px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 30px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: 30px;
  --emu-component-lists-accordion-item-header-icon-size-width-wide: 30px;
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-none);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-500) 16px / 12px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-500) 16px / 14px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: 0;
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: 0;
  --emu-component-actions-button-outline-width-secondary-outline: 0;
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-component-actions-button-color-border-primary-outline-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-component-actions-button-color-border-primary-outline-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-component-actions-button-color-border-primary-outline-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-component-actions-button-color-border-primary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: #ffffff1a;
  --emu-component-actions-button-color-background-primary-outline-default-dark: #ffffff1a;
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-component-actions-button-color-background-secondary-filled-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-component-actions-button-color-background-secondary-filled-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-component-actions-button-color-background-secondary-filled-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-component-actions-button-color-background-secondary-filled-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: 10px;
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: 13px;
  --emu-component-actions-button-padding-top-wide: 13px;
  --emu-component-actions-button-padding-right-narrow: 34.5px;
  --emu-component-actions-button-padding-right-wide: 34.5px;
  --emu-component-actions-button-padding-bottom-narrow: 13px;
  --emu-component-actions-button-padding-bottom-wide: 13px;
  --emu-component-actions-button-padding-left-narrow: 34.5px;
  --emu-component-actions-button-padding-left-wide: 34.5px;
  --emu-component-actions-button-padding-narrow: 13px 34.5px;
  --emu-component-actions-button-padding-wide: 13px 34.5px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 10px;
  --emu-component-containers-isi-header-padding-top-wide: 9px;
  --emu-component-containers-isi-header-padding-right-narrow: 15px;
  --emu-component-containers-isi-header-padding-right-wide: 86px;
  --emu-component-containers-isi-header-padding-bottom-narrow: 10px;
  --emu-component-containers-isi-header-padding-bottom-wide: 9px;
  --emu-component-containers-isi-header-padding-left-narrow: 15px;
  --emu-component-containers-isi-header-padding-left-wide: 86px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: 21px;
  --emu-component-containers-isi-body-padding-top-wide: 24px;
  --emu-component-containers-isi-body-padding-right-narrow: 21px;
  --emu-component-containers-isi-body-padding-right-wide: 86px;
  --emu-component-containers-isi-body-padding-bottom-narrow: 21px;
  --emu-component-containers-isi-body-padding-bottom-wide: 24px;
  --emu-component-containers-isi-body-padding-left-narrow: 21px;
  --emu-component-containers-isi-body-padding-left-wide: 86px;
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 412px;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: #fff6;
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: #fff6;
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: #fff6;
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: #fff6;
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: 5px;
  --emu-component-containers-carousel-indicators-dots-size-wide: 10px;
  --emu-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-carousel-indicators-dots-gap-wide: 10px;
  --emu-component-containers-carousel-indicators-dots-padding-narrow: 5px 15px;
  --emu-component-containers-carousel-indicators-dots-padding-wide: 10px 27.5px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 794px;
  --emu-component-layers-modal-body-padding-top-narrow: 20px;
  --emu-component-layers-modal-body-padding-top-wide: 40px;
  --emu-component-layers-modal-body-padding-right-narrow: 24px;
  --emu-component-layers-modal-body-padding-right-wide: 40px;
  --emu-component-layers-modal-body-padding-bottom-narrow: 20px;
  --emu-component-layers-modal-body-padding-bottom-wide: 40px;
  --emu-component-layers-modal-body-padding-left-narrow: 24px;
  --emu-component-layers-modal-body-padding-left-wide: 40px;
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

p, li {
  margin-top: var(--emu-common-spacing-none);
}

li {
  margin-bottom: var(--emu-common-spacing-none);
}

p {
  margin-bottom: 30px;
}

ul, ol {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: 20px;
}

a[href^="tel:"] {
  pointer-events: none;
  text-decoration: none;
  color: inherit !important;
}

main {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.references-in-view [data-component="container-isi-v2"] .isi-container, .footer-in-view [data-component="container-isi-v2"] .isi-container {
  transform: translateY(100%);
}

:root {
  --isi-content-height: 219px;
}

:root.isi-expanded-once {
  --isi-content-height: var(--emu-common-sizing-none);
}

:root.isi-expanded-once.isi-expanded {
  --isi-content-height: var(--emu-component-containers-isi-open-content-size, 140px);
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block !important;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view {
  display: block !important;
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view {
  display: block !important;
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block !important;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-content--centered {
  text-align: center;
}

.u-bg--primary-light {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg--primary-dark {
  background-color: var(--emu-semantic-colors-primary-dark);
}

.u-bg--primary-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.u-bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--primary-200 {
  background-color: var(--emu-semantic-colors-primary-200);
}

.u-bg--primary-300 {
  background-color: var(--emu-semantic-colors-primary-300);
}

.u-bg--primary-400 {
  background-color: var(--emu-semantic-colors-primary-400);
}

.u-bg--primary-500 {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg--primary-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--primary-700 {
  background-color: var(--emu-semantic-colors-primary-700);
}

.u-bg--secondary-light {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg--secondary-dark {
  background-color: var(--emu-semantic-colors-secondary-dark);
}

.u-bg--secondary-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-bg--tertiary-light {
  background-color: var(--emu-semantic-colors-tertiary-light);
}

.u-bg--brandYellow-50 {
  background-color: var(--emu-semantic-colors-brandYellow-50);
}

[data-component="title"].u-text--color-white, [data-component="text"].u-text--color-white {
  color: var(--emu-common-colors-white);
}

[data-component="title"].u-text--color-black, [data-component="text"].u-text--color-black {
  color: var(--emu-common-colors-black);
}

[data-component="title"].u-text--color-primary-light, [data-component="text"].u-text--color-primary-light {
  color: var(--emu-semantic-colors-primary-light);
}

[data-component="title"].u-text--color-primary-dark, [data-component="text"].u-text--color-primary-dark {
  color: var(--emu-semantic-colors-primary-dark);
}

[data-component="title"].u-text--color-primary-50, [data-component="text"].u-text--color-primary-50 {
  color: var(--emu-semantic-colors-primary-50);
}

[data-component="title"].u-text--color-primary-100, [data-component="text"].u-text--color-primary-100 {
  color: var(--emu-semantic-colors-primary-100);
}

[data-component="title"].u-text--color-primary-200, [data-component="text"].u-text--color-primary-200 {
  color: var(--emu-semantic-colors-primary-200);
}

[data-component="title"].u-text--color-primary-300, [data-component="text"].u-text--color-primary-300 {
  color: var(--emu-semantic-colors-primary-300);
}

[data-component="title"].u-text--color-primary-400, [data-component="text"].u-text--color-primary-400 {
  color: var(--emu-semantic-colors-primary-400);
}

[data-component="title"].u-text--color-primary-500, [data-component="text"].u-text--color-primary-500 {
  color: var(--emu-semantic-colors-primary-500);
}

[data-component="title"].u-text--color-primary-600, [data-component="text"].u-text--color-primary-600 {
  color: var(--emu-semantic-colors-primary-600);
}

[data-component="title"].u-text--color-primary-700, [data-component="text"].u-text--color-primary-700 {
  color: var(--emu-semantic-colors-primary-700);
}

[data-component="title"].u-text--color-secondary-light, [data-component="text"].u-text--color-secondary-light {
  color: var(--emu-semantic-colors-secondary-light);
}

[data-component="title"].u-text--color-secondary-dark, [data-component="text"].u-text--color-secondary-dark {
  color: var(--emu-semantic-colors-secondary-dark);
}

[data-component="title"].u-text--color-secondary-50, [data-component="text"].u-text--color-secondary-50 {
  color: var(--emu-semantic-colors-secondary-50);
}

[data-component="title"].u-text--color-tertiary-light, [data-component="text"].u-text--color-tertiary-light {
  color: var(--emu-semantic-colors-tertiary-light);
}

[data-component="title"].u-text--color-brandYellow-50, [data-component="text"].u-text--color-brandYellow-50 {
  color: var(--emu-semantic-colors-brandYellow-50);
}

.u-container-default {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .u-container-default {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mx-auto.emu-button-v2.emu-button__primary-outline.emu-button {
  display: block;
}

.u-hide:not(.cq-Editable-dom--container .u-hide) {
  display: none !important;
}

.u-scroll-target-section {
  scroll-margin: var(--header-height, 114px);
}

.u-max-container {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-h1-typography .cmp-title__text, .u-h1-typography h2, .u-h1-typography h3, .u-h1-typography h4, .u-h1-typography h5 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  .u-h1-typography .cmp-title__text, .u-h1-typography h2, .u-h1-typography h3, .u-h1-typography h4, .u-h1-typography h5 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

.u-hide-br-in-desktop br {
  display: block;
}

@media (min-width: 1024px) {
  .u-hide-br-in-desktop br {
    display: none;
  }
}

.u-hide-br-in-mobile br {
  display: none;
}

@media (min-width: 1024px) {
  .u-hide-br-in-mobile br {
    display: block;
  }
}

#portfolio-page .portfolio__vision {
  padding: 30px 22px 27.2px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__vision {
    align-items: center;
    padding: 80px 117px 40px;
    display: flex;
  }
}

#portfolio-page .portfolio__vision .embed {
  min-height: 199px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__vision .embed {
    min-width: 538px;
    min-height: 326px;
    margin-right: 60px;
  }
}

#portfolio-page .portfolio__vision-title {
  margin-top: 13px;
  margin-right: 28px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__vision-title {
    max-width: 514px;
    margin-top: -15px;
  }

  #portfolio-page .portfolio__vision-title .cmp-title__text {
    line-height: 44px;
  }
}

#portfolio-page .portfolio__safety {
  flex-direction: column;
  padding: 30px 20px 24px;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety {
    flex-direction: row;
    align-items: center;
    padding: 59px 114px 62px 120px;
  }
}

#portfolio-page .portfolio__safety > .container {
  flex-grow: 1;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-content {
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  #portfolio-page .portfolio__safety-content > .title {
    margin-top: auto;
  }

  #portfolio-page .portfolio__safety-content > .text {
    margin-bottom: auto;
  }
}

#portfolio-page .portfolio__safety-title .cmp-title__text {
  line-height: 33px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-title .cmp-title__text {
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
  }
}

#portfolio-page .portfolio__safety-title sup {
  font-size: 16px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-title sup {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

#portfolio-page .portfolio__safety-desc {
  margin-top: 18px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-desc {
    margin-top: 23px;
  }
}

#portfolio-page .portfolio__safety-desc p {
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  margin-bottom: 9px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-desc p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    margin-bottom: 11px;
  }
}

#portfolio-page .portfolio__safety-desc ul {
  padding-left: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-desc ul {
    padding-left: 24px;
  }

  #portfolio-page .portfolio__safety-desc ul li sup {
    font-size: 20px;
  }
}

#portfolio-page .portfolio__safety-desc ul li::marker {
  font-size: 14px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-desc ul li::marker {
    font-size: 20px;
  }
}

#portfolio-page .portfolio__safety-desc li {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-200);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-desc li {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#portfolio-page .portfolio__safety-desc li sup {
  font-size: .65em;
}

#portfolio-page .portfolio__safety-years-exp-img img {
  max-width: 131px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__safety-years-exp-img img {
    margin-bottom: var(--emu-common-spacing-none);
    max-width: 195px;
  }
}

#portfolio-page .portfolio__patients-results {
  justify-content: center;
  margin-left: 22px;
  margin-right: 22px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__patients-results {
    margin-left: 117px;
    margin-right: 117px;
  }
}

#portfolio-page .portfolio__patients-results-img {
  margin-right: 10px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__patients-results-img {
    margin-right: 20px;
  }
}

#portfolio-page .portfolio__patients-results > .text {
  position: absolute;
  bottom: 11px;
  right: 4px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__patients-results > .text {
    bottom: 18px;
    right: 20px;
  }
}

#portfolio-page .portfolio__patients-results-text p {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-325);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 8px;
  line-height: 8px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__patients-results-text p {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 14px;
  }
}

#portfolio-page .portfolio__distinct-profiles {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles {
    padding-top: 68px;
  }
}

#portfolio-page .portfolio__distinct-profiles-title {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #portfolio-page .portfolio__distinct-profiles-title .cmp-title__text {
    line-height: 46px;
  }
}

@media (max-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-title--px-lg-mobile {
    padding-left: 42px;
    padding-right: 42px;
  }
}

#portfolio-page .portfolio__distinct-profiles-title sup {
  font-size: .725em;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-title sup {
    font-size: .6em;
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper {
    flex-direction: row-reverse;
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper > .image {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper > .image {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper > .image img {
  margin-bottom: 21px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper > .image img {
    margin-bottom: var(--emu-common-spacing-none);
    max-width: 190px;
    margin-left: 115px;
    margin-right: 78px;
    padding-top: 10px;
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper-desc {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper-desc {
    max-width: 800px;
    margin: 25px auto 65px;
    padding-left: 44px;
    padding-right: 40px;
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper-desc p {
  color: var(--emu-semantic-colors-tertiary-light);
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: var(--emu-semantic-line-heights-wide-xs);
  margin-bottom: 25px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper-desc p {
    font-size: 18px;
    line-height: 1.2;
  }
}

#portfolio-page .portfolio__distinct-profiles-info-wrapper-desc p sup {
  font-size: .65em;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__distinct-profiles-info-wrapper-desc p sup {
    font-size: .7em;
  }
}

#portfolio-page .portfolio__flippable-cards {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards {
    padding-top: 19px;
  }
}

#portfolio-page .portfolio__flippable-cards > .container, #portfolio-page .portfolio__flippable-cards > .text {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards > .container {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards > .container {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: 17px;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards > .container:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards > .container:last-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-semantic-colors-primary-500);
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card {
    min-width: clamp(310px, 25.7vw, 370px);
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__centered-image, #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__back-images img {
  z-index: -1;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: linear;
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__centered-image {
  z-index: var(--emu-common-other-z-index-base);
  background-color: #b8dbd6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition-delay: .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(0);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__centered-image {
    align-items: flex-start;
    padding-top: 120px;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__centered-image img {
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  max-height: 100%;
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__back-images img {
  object-fit: contain;
  object-position: center;
  background-color: var(--emu-semantic-colors-primary-light);
  width: 100%;
  height: 100%;
  transition-delay: 0s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(270deg);
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flipper {
  z-index: calc(var(--emu-common-other-z-index-base)  + 1);
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-dark);
  padding: var(--emu-common-spacing-none);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flipper svg {
  margin: var(--emu-common-spacing-none);
  position: absolute;
  top: 20px;
  right: 22px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flipper svg {
    min-width: 20px;
    min-height: 20px;
    right: 20px;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__title {
  padding-left: var(--emu-common-spacing-small);
  padding-top: 18px;
  padding-right: var(--emu-common-spacing-small);
  background-color: var(--emu-common-colors-white);
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__title {
    padding-top: 29px;
    padding-bottom: 27px;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-size: 26px;
  line-height: 30px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__title .cmp-title__text sup {
  vertical-align: baseline;
  font-size: 1em;
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section {
  isolation: isolate;
  min-height: 328px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section {
    min-height: 421px;
  }
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section.flipped .flippable-card__centered-image {
  transition-duration: var(--emu-common-other-time-transition-base);
  transition-delay: var(--emu-common-other-time-delay-none);
  transform: rotateY(90deg);
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section.flipped .flippable-card__back-images img {
  transition-delay: var(--emu-common-other-time-transition-base);
  transform: rotateY(360deg);
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section.flipped .flippable-card__flipper {
  color: var(--emu-semantic-colors-primary-500);
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section > .image {
  width: 100%;
  height: 100%;
}

#portfolio-page .portfolio__flippable-cards .flippable-cards .flippable-card__flip-section > .button {
  position: static;
}

#portfolio-page .portfolio__flippable-cards-disclaimer {
  margin-top: 27px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards-disclaimer {
    margin-top: 18px;
  }
}

#portfolio-page .portfolio__flippable-cards-disclaimer p {
  color: var(--emu-semantic-colors-tertiary-light);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: 1.2;
  font-weight: var(--emu-semantic-font-weight-600);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards-disclaimer p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
  }
}

#portfolio-page .portfolio__flippable-cards-disclaimer p b {
  font-weight: var(--emu-semantic-font-weight-600);
}

#portfolio-page .portfolio__flippable-cards-extra-info {
  margin-top: 6px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards-extra-info {
    margin-top: var(--emu-common-spacing-xxs);
  }
}

#portfolio-page .portfolio__flippable-cards-extra-info ul {
  padding-left: var(--emu-common-spacing-none);
  list-style: none;
}

#portfolio-page .portfolio__flippable-cards-extra-info ul li {
  color: var(--emu-semantic-colors-tertiary-light);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  padding-left: var(--emu-common-spacing-xs);
  line-height: 1.2;
  font-weight: var(--emu-common-font-weight-light);
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards-extra-info ul li {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    padding-left: 7px;
  }
}

#portfolio-page .portfolio__flippable-cards-extra-info ul li u {
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
}

#portfolio-page .portfolio__flippable-cards-extra-info ul li .emphasis {
  font-size: .75em;
  line-height: 1;
  position: absolute;
  top: 1.5px;
  left: 0;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__flippable-cards-extra-info ul li .emphasis {
    top: .5px;
  }
}

#portfolio-page .portfolio__flippable-cards-extra-info ul li b {
  font-weight: var(--emu-common-font-weight-regular);
}

#portfolio-page .portfolio__easy-steps {
  background: linear-gradient(180deg, var(--emu-semantic-colors-primary-light) 0%, var(--emu-common-colors-white) 100%);
  padding: 23px 20px 30px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps {
    padding: 90px 117px 76px;
  }
}

#portfolio-page .portfolio__easy-steps > .container {
  width: 100%;
}

#portfolio-page .portfolio__easy-steps-title {
  padding-left: 48px;
  padding-right: 48px;
}

#portfolio-page .portfolio__easy-steps-title--secondary {
  padding-left: 18px;
  padding-right: 18px;
}

#portfolio-page .portfolio__easy-steps-title--secondary .cmp-title__text sup {
  font-size: 16px;
  top: 0;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-title--secondary .cmp-title__text sup {
    font-size: 24px;
  }
}

#portfolio-page .portfolio__easy-steps-title sup {
  font-size: 26px;
  top: 8px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-title sup {
    font-size: 45px;
    top: 15px;
  }
}

#portfolio-page .portfolio__easy-steps-img {
  margin-top: 23px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-img {
    margin-top: 25px;
  }
}

#portfolio-page .portfolio__easy-steps-img img {
  max-width: min(100%, 984px);
  margin-left: auto;
  margin-right: auto;
}

#portfolio-page .portfolio__easy-steps-info-wrapper {
  margin-top: 22px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-info-wrapper {
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 27px;
    display: flex;
  }

  #portfolio-page .portfolio__easy-steps-info-wrapper > .container {
    width: 50%;
  }
}

#portfolio-page .portfolio__easy-steps-info-wrapper > .container:first-child {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-info-wrapper > .container:first-child {
    margin-right: 20px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__easy-steps-info-box {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-500);
  height: 100%;
  padding: 25px 25px 23px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-info-box {
    padding-top: 41px;
    padding-right: var(--emu-common-spacing-xl);
    padding-bottom: 41px;
    padding-left: var(--emu-common-spacing-xl);
    max-width: none;
  }

  #portfolio-page .portfolio__easy-steps-info-box--secondary {
    padding: 24px 42px 37px;
  }
}

#portfolio-page .portfolio__easy-steps-info-box .emu-image a:after {
  content: "";
  z-index: var(--emu-common-other-z-index-layer);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#portfolio-page .portfolio__easy-steps-info-box img {
  margin: 5px auto 13px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-info-box img {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 23px;
  }
}

#portfolio-page .portfolio__easy-steps-info-text p {
  color: inherit;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__easy-steps-info-text p {
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#portfolio-page .portfolio__tissue-expander {
  position: relative;
}

#portfolio-page .portfolio__tissue-expander-background-gradient-box {
  background: linear-gradient(to bottom, var(--emu-semantic-colors-primary-light), #deeef2e5, #fff0);
  z-index: var(--emu-common-other-z-index-behind);
  width: 100%;
  position: absolute;
  top: 402px;
  bottom: 0;
}

#portfolio-page .portfolio__tissue-expander > .teaser {
  width: 100%;
  position: absolute;
  top: 0;
}

#portfolio-page .portfolio__tissue-expander > .teaser .content-container {
  display: none;
}

#portfolio-page .portfolio__tissue-expander .title, #portfolio-page .portfolio__tissue-expander .text {
  position: relative;
}

#portfolio-page .portfolio__tissue-expander-logo {
  margin-left: var(--emu-common-spacing-small);
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-logo {
    margin-left: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__tissue-expander-teaser .cmp-teaser__image .cmp-image__image {
  object-fit: cover;
  object-position: top;
  width: 100%;
  max-width: none;
  height: auto;
  min-height: auto;
  max-height: 621px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-teaser .cmp-teaser__image .cmp-image__image {
    max-height: unset;
  }
}

#portfolio-page .portfolio__tissue-expander-title-wrapper {
  margin-top: 26px;
  margin-bottom: 42px;
  margin-left: var(--emu-common-spacing-small);
  z-index: var(--emu-common-other-z-index-layer);
  max-width: 218px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-title-wrapper {
    margin-top: 62px;
    margin-bottom: 84px;
    margin-left: var(--emu-common-spacing-none);
    max-width: 652px;
  }
}

#portfolio-page .portfolio__tissue-expander-title .cmp-title__text {
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: .8px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-title .cmp-title__text {
    letter-spacing: 1.4px;
  }
}

#portfolio-page .portfolio__tissue-expander-content {
  padding: 30px 22px 25px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-content {
    padding-top: 105px;
    padding-right: 117px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 117px;
  }
}

#portfolio-page .portfolio__tissue-expander-content-gradient-box {
  background-image: linear-gradient(to bottom, var(--emu-common-colors-white), #fff0);
  width: 100%;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-content-gradient-box {
    height: 180px;
  }
}

#portfolio-page .portfolio__tissue-expander-content-gradient-box--secondary {
  background-image: linear-gradient(to top, var(--emu-common-colors-white), #fff0);
  top: auto;
  bottom: 0;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-content-gradient-box--secondary {
    height: 282px;
  }
}

#portfolio-page .portfolio__tissue-expander-disclaimer {
  z-index: var(--emu-common-other-z-index-layer);
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-disclaimer {
    margin-bottom: var(--emu-common-spacing-none);
    max-width: 386px;
  }
}

#portfolio-page .portfolio__tissue-expander-disclaimer p {
  color: inherit;
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-semantic-font-weight-325);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 12px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-disclaimer p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 18px;
  }

  #portfolio-page .portfolio__tissue-expander-info-wrapper {
    margin-bottom: 40px;
    margin-left: var(--emu-common-spacing-xs);
    align-items: stretch;
    display: flex;
  }

  #portfolio-page .portfolio__tissue-expander-info-wrapper > .card {
    flex-basis: 384px;
    margin-right: 20px;
  }
}

#portfolio-page .portfolio__tissue-expander-info-wrapper > .card > div, #portfolio-page .portfolio__tissue-expander-info-wrapper > .card article {
  height: 100%;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-info-wrapper > .text {
    margin-top: auto;
  }
}

#portfolio-page .portfolio__tissue-expander-card {
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-layer);
  margin-bottom: 15px;
  box-shadow: 2px 2px 14px #0000001a;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-card {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__tissue-expander-card img {
  margin-left: auto;
  margin-right: auto;
}

#portfolio-page .portfolio__tissue-expander-card .emu-card__wrapper {
  height: 100%;
  padding: 24px 24px 30px;
  box-shadow: 0 1.37px 3.148px #00000005;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-card .emu-card__wrapper {
    padding-top: 50px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .portfolio__tissue-expander-card .emu-card__title-wrapper {
  display: none;
}

#portfolio-page .portfolio__tissue-expander-card-desc {
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-card-desc {
    margin-bottom: -15px;
  }

  #portfolio-page .portfolio__tissue-expander-card-desc--has-desk-max-width {
    max-width: 245px;
    margin: auto;
  }

  #portfolio-page .portfolio__tissue-expander-card-desc.pt-sm {
    padding-top: 10px;
  }
}

#portfolio-page .portfolio__tissue-expander-card-desc p {
  color: inherit;
  margin-bottom: var(--emu-common-spacing-none);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-card-desc p {
    letter-spacing: .5px;
    font-size: 18px;
    line-height: 22px;
  }
}

#portfolio-page .portfolio__tissue-expander-methodology {
  z-index: var(--emu-common-other-z-index-layer);
}

#portfolio-page .portfolio__tissue-expander-methodology p {
  margin-bottom: var(--emu-common-spacing-none);
  color: inherit;
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-semantic-font-weight-350);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  text-indent: -3px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__tissue-expander-methodology p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    text-indent: -4px;
    line-height: 16.8px;
  }
}

#portfolio-page .portfolio__fill-system {
  padding: 22px 21px 23px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system {
    padding: 90px 117px 18px;
  }
}

#portfolio-page .portfolio__fill-system-title-wrapper {
  margin-bottom: 23px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-title-wrapper {
    margin-bottom: 50px;
  }
}

#portfolio-page .portfolio__fill-system-title .cmp-title__text {
  letter-spacing: .5px;
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-title .cmp-title__text {
    letter-spacing: 2.2px;
  }
}

#portfolio-page .portfolio__fill-system-title .cmp-title__text u {
  text-decoration: none;
}

#portfolio-page .portfolio__fill-system-title--secondary {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-title--secondary {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #portfolio-page .portfolio__fill-system-title--secondary .cmp-title__text {
    letter-spacing: normal;
  }
}

#portfolio-page .portfolio__fill-system-caption {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-caption {
    padding: var(--emu-common-spacing-none);
    margin-top: 28px;
  }
}

#portfolio-page .portfolio__fill-system-caption .cmp-title__text {
  font-weight: var(--emu-semantic-font-weight-500);
}

#portfolio-page .portfolio__fill-system-caption u {
  text-decoration: none;
}

#portfolio-page .portfolio__fill-system-text {
  margin-top: 22px;
  padding-left: 5px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-text {
    margin-top: 40px;
  }
}

#portfolio-page .portfolio__fill-system-text ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

#portfolio-page .portfolio__fill-system-text li {
  color: inherit;
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  margin-bottom: var(--emu-common-spacing-none);
  text-indent: -4px;
}

@media (min-width: 1024px) {
  #portfolio-page .portfolio__fill-system-text li {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

#portfolio-page .portfolio__fill-system-title-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

#portfolio-page .pms-accordion {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion {
    margin-bottom: 20px;
  }
}

#portfolio-page .pms-accordion__adverse-events-text, #portfolio-page .pms-accordion__adverse-events, #portfolio-page .pms-accordion__events-percent, #portfolio-page .pms-accordion__additional-info {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-events-text, #portfolio-page .pms-accordion__adverse-events, #portfolio-page .pms-accordion__events-percent, #portfolio-page .pms-accordion__additional-info {
    padding-left: 118px;
    padding-right: 118px;
  }

  #portfolio-page .pms-accordion__adverse-events-text {
    padding-left: 40px;
    padding-right: 30px;
  }
}

#portfolio-page .pms-accordion__adverse-events-text p {
  color: var(--emu-semantic-colors-tertiary-light);
  margin-bottom: 18px;
  padding-top: 17px;
  font-size: 14px;
  line-height: 16.8px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-events-text p {
    margin-bottom: 47px;
    padding-top: 26px;
    font-size: 18px;
    line-height: 21.6px;
  }
}

#portfolio-page .pms-accordion__adverse-events {
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-events {
    flex-flow: wrap;
    justify-content: space-between;
    margin-bottom: 38px;
    padding-left: 120px;
    padding-right: 120px;
  }
}

#portfolio-page .pms-accordion__adverse-events > .container {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-events > .container {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-small);
  }
}

#portfolio-page .pms-accordion__adverse-events > .container:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-events > .container:last-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .pms-accordion__adverse-event {
  align-items: center;
  display: flex;
}

#portfolio-page .pms-accordion__adverse-event > .image, #portfolio-page .pms-accordion__adverse-event > .text {
  flex-shrink: 0;
}

#portfolio-page .pms-accordion__adverse-event > .image {
  margin-right: 10px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-event > .image {
    margin-right: 20px;
  }
}

#portfolio-page .pms-accordion__adverse-event-img img {
  object-fit: contain;
  width: 30px;
  height: 30px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-event-img img {
    width: auto;
    height: auto;
  }
}

#portfolio-page .pms-accordion__adverse-event-text p {
  color: var(--emu-semantic-colors-tertiary-light);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 14px;
  line-height: 16.8px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__adverse-event-text p {
    font-size: 18px;
    line-height: 21.6px;
  }
}

#portfolio-page .pms-accordion__events-percent {
  background-color: #a6ddea1a;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__events-percent {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: 40px;
  }
}

#portfolio-page .pms-accordion__events-percent .cmp-image__image {
  background-color: var(--emu-common-colors-transparent);
}

#portfolio-page .pms-accordion__events-percent-img-with-title {
  padding-left: var(--emu-common-spacing-xs);
  flex-direction: column;
  margin-bottom: 12px;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__events-percent-img-with-title {
    padding-left: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-large);
    flex-direction: row;
    align-items: center;
  }
}

#portfolio-page .pms-accordion__events-percent-img-with-title > .image, #portfolio-page .pms-accordion__events-percent-img-with-title > .text {
  flex-shrink: 0;
}

#portfolio-page .pms-accordion__events-percent-img {
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__events-percent-img {
    margin-right: 48px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .pms-accordion__events-percent-title {
  padding-right: var(--emu-common-spacing-small);
}

#portfolio-page .pms-accordion__events-percent-title:not([class*="u-text--color"]) {
  color: var(--emu-semantic-colors-tertiary-light);
}

#portfolio-page .pms-accordion__events-surveillance-data-info ul {
  padding-left: var(--emu-common-spacing-xs);
  list-style: none;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__events-surveillance-data-info ul {
    padding-left: var(--emu-common-spacing-none);
    position: relative;
    left: -2px;
  }
}

#portfolio-page .pms-accordion__events-surveillance-data-info li {
  color: var(--emu-semantic-colors-tertiary-light);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
  padding-left: 4.5px;
  padding-right: var(--emu-common-spacing-medium);
  line-height: 12px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__events-surveillance-data-info li {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 16.8px;
  }
}

#portfolio-page .pms-accordion__events-surveillance-data-info li u {
  text-decoration: none;
  position: absolute;
  top: 0;
  left: -1px;
}

#portfolio-page .pms-accordion__events-surveillance-data-info li > .emphasis {
  font-size: 8px;
  line-height: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

#portfolio-page .pms-accordion__additional-info {
  padding-top: var(--emu-common-spacing-medium);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info {
    background-color: #f5f5f5;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 40px;
  }
}

#portfolio-page .pms-accordion__additional-info > .container:first-child {
  flex-shrink: 0;
  margin-bottom: 14px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info > .container:first-child {
    margin-bottom: var(--emu-common-spacing-none);
    padding-bottom: 28px;
    padding-right: 5px;
  }

  #portfolio-page .pms-accordion__additional-info > .container:last-child {
    margin-right: 47px;
  }
}

#portfolio-page .pms-accordion__additional-info-title {
  margin-bottom: 12px;
  padding-left: 4px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info-title {
    padding-left: var(--emu-common-spacing-none);
    margin-bottom: 10px;
  }
}

#portfolio-page .pms-accordion__additional-info-title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-weight: var(--emu-semantic-font-weight-regular);
}

#portfolio-page .pms-accordion__additional-info-img {
  padding-left: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info-img {
    padding-left: var(--emu-common-spacing-none);
  }
}

#portfolio-page .pms-accordion__additional-info-text {
  padding-left: var(--emu-common-spacing-xs);
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info-text {
    padding-left: var(--emu-common-spacing-none);
  }
}

#portfolio-page .pms-accordion__additional-info-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-200);
  margin-bottom: 9.5px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info-text p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#portfolio-page .pms-accordion__additional-info-text p sup {
  font-size: .6em;
}

#portfolio-page .pms-accordion__additional-info-text p:last-child {
  margin-bottom: 23px;
}

@media (min-width: 1024px) {
  #portfolio-page .pms-accordion__additional-info-text p:last-child {
    margin-bottom: 40px;
  }
}

#portfolio-page .advance-tech {
  padding-top: 17px;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech {
    padding-top: 84px;
    padding-left: 117px;
    padding-right: 117px;
  }
}

#portfolio-page .advance-tech__title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-semantic-font-families-collier-italic);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__title .cmp-title__text {
    font-size: 48px;
    line-height: 56px;
    font-weight: var(--emu-common-font-weight-regular);
  }
}

#portfolio-page .advance-tech__images {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-semantic-colors-primary-500);
  margin-top: 24px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__images {
    margin-top: 70px;
  }
}

#portfolio-page .advance-tech__images img {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

#portfolio-page .advance-tech__info {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-semantic-colors-primary-500);
  margin-top: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info {
    margin-top: 19px;
  }
}

#portfolio-page .advance-tech__info sup {
  font-size: .675em;
}

#portfolio-page .advance-tech__info-title {
  background-color: var(--emu-semantic-colors-primary-500);
  padding: 18px 56px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-title {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

#portfolio-page .advance-tech__info-title .cmp-title__text {
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-body);
}

#portfolio-page .advance-tech__info-description {
  padding: 22px 29px 25px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-description {
    padding: 61px 66px;
  }
}

#portfolio-page .advance-tech__info-description--padding-lg {
  padding: 19px 40px 17px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-description--padding-lg {
    padding: 61px 140px;
  }
}

#portfolio-page .advance-tech__info-description p {
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-dark);
}

#portfolio-page .advance-tech__info-bar {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-bar {
    margin-top: 20px;
    display: flex;
  }

  #portfolio-page .advance-tech__info-bar > .text:last-child {
    flex-grow: 1;
  }
}

#portfolio-page .advance-tech__info-bar-title {
  background-color: var(--emu-semantic-colors-primary-dark);
  color: var(--emu-common-colors-white);
  padding: 28px 24px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-bar-title {
    padding-left: var(--emu-common-spacing-large);
    padding-top: 18px;
    padding-right: var(--emu-common-spacing-large);
    align-items: center;
    min-height: 100%;
    padding-bottom: 18px;
    display: flex;
  }
}

#portfolio-page .advance-tech__info-bar-title sup {
  vertical-align: baseline;
  font-size: 1em;
}

#portfolio-page .advance-tech__info-bar-title .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  font-family: var(--emu-semantic-font-families-collier-italic);
  font-weight: var(--emu-semantic-font-weight-200);
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-bar-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    white-space: nowrap;
    line-height: 1;
  }
}

#portfolio-page .advance-tech__info-bar-description {
  background-color: var(--emu-semantic-colors-primary-500);
  padding: 15px 40px;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-bar-description {
    align-items: center;
    min-height: 100%;
    padding-left: 34px;
    padding-right: 34px;
    display: flex;
  }
}

#portfolio-page .advance-tech__info-bar-description sup {
  font-size: .7em;
}

#portfolio-page .advance-tech__info-bar-description p {
  color: var(--emu-common-colors-white);
  margin-bottom: var(--emu-common-spacing-none);
}

#portfolio-page .advance-tech__info-details {
  color: var(--emu-semantic-colors-tertiary-light);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-top: 14px;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-details {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    margin-top: 38px;
    position: relative;
    left: -4.5px;
  }
}

#portfolio-page .advance-tech__info-details ul {
  padding-left: var(--emu-common-spacing-none);
  list-style: none;
}

#portfolio-page .advance-tech__info-details li {
  font-weight: var(--emu-common-font-weight-light);
  padding-left: 3px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .advance-tech__info-details li {
    padding-left: 4.5px;
  }
}

#portfolio-page .advance-tech__info-details li .emphasis {
  font-size: .65em;
  line-height: 1;
  position: absolute;
  top: 1.5px;
  left: 0;
}

#portfolio-page .profile-img-maps {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 22px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .profile-img-maps {
    margin-bottom: 70px;
  }

  #portfolio-page .profile-img-maps > .container {
    margin-right: 20px;
  }

  #portfolio-page .profile-img-maps > .container:last-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .profile-img-maps > .container:last-child .profile-img-map {
  margin-top: -8px;
  margin-bottom: 6px;
}

@media (min-width: 516px) {
  #portfolio-page .profile-img-maps > .container:last-child .profile-img-map {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#portfolio-page .profile-img-map {
  margin-bottom: var(--emu-common-spacing-small);
  flex-shrink: 0;
  min-width: 147px;
  min-height: 160px;
  position: relative;
}

#portfolio-page .profile-img-map .emu-mapping-marker {
  color: var(--emu-semantic-colors-primary-500);
  width: auto;
  height: auto;
}

#portfolio-page .profile-img-map .emu-mapping-marker span:before, #portfolio-page .profile-img-map .emu-mapping-marker span:after {
  display: none;
}

#portfolio-page .profile-img-map .emu-mapping-marker svg {
  margin: var(--emu-common-spacing-none);
}

#portfolio-page .profile-img-map .emu-mapping-marker[aria-pressed="true"] svg {
  color: var(--emu-common-colors-white);
}

#portfolio-page .profile-img-map .emu-mapping-marker[aria-pressed="true"] svg circle {
  fill: var(--emu-semantic-colors-primary-500);
  stroke: var(--emu-semantic-colors-primary-500);
}

#portfolio-page .profile-img-map__content {
  background-color: var(--emu-semantic-colors-primary-500);
  padding-left: var(--emu-common-spacing-xs);
  padding-right: var(--emu-common-spacing-xs);
  z-index: var(--emu-common-other-z-index-layer);
  min-width: 100px;
  display: none;
  position: absolute;
}

@media (min-width: 1024px) {
  #portfolio-page .profile-img-map__content {
    min-width: 182px;
  }
}

#portfolio-page .profile-img-map__content p {
  color: var(--emu-common-colors-white);
  font-weight: var(--emu-semantic-font-weight-500);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.1;
}

@media (min-width: 1024px) {
  #portfolio-page .profile-img-map__content p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    letter-spacing: -.035em;
    margin-top: 22px;
    margin-bottom: 22px;
    line-height: 30px;
  }
}

#portfolio-page .profile-img-map__content p .emphasis {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 1;
}

@media (min-width: 1024px) {
  #portfolio-page .profile-img-map__content p .emphasis {
    margin-top: var(--emu-common-spacing-xs);
    letter-spacing: normal;
    font-size: 18px;
    font-weight: var(--emu-common-font-weight-regular);
  }
}

#portfolio-page .profile-img-map__content--active {
  display: revert;
  transform: translate(calc(-50% + var(--marker-width, 0) / 2), calc(19px + var(--marker-height, 0)  - 4px));
}

#portfolio-page .profile-img-map__content:before {
  content: "";
  clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
  background-color: inherit;
  width: 25px;
  height: 19px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(4px - 100%));
}

@media (min-width: 1024px) {
  #portfolio-page .profile-img-map__content:before {
    width: 21px;
    transform: translate(-50%, calc(1px - 100%));
  }
}

#portfolio-page .warranty {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty {
    padding-top: 87px;
    padding-left: 117px;
    padding-right: 117px;
  }
}

#portfolio-page .warranty__title {
  margin-bottom: 29px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 900px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

#portfolio-page .warranty__title .cmp-title__text > p {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-500);
  padding-left: 26px;
  padding-right: 26px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__title .cmp-title__text > p {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .warranty__title .cmp-title__text > p:first-child {
  color: var(--emu-semantic-colors-primary-dark);
}

#portfolio-page .warranty__title .cmp-title__text > p:last-child {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

#portfolio-page .warranty__title .cmp-title__text > p sup {
  font-size: .57em;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__title .cmp-title__text > p sup {
    font-size: .59em;
  }
}

#portfolio-page .warranty__description {
  margin-bottom: 25px;
  padding-left: 12.5px;
  padding-right: 12.5px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__description {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: 54px;
  }
}

#portfolio-page .warranty__description > p {
  color: var(--emu-semantic-colors-primary-500);
  font-family: var(--emu-semantic-font-families-heading);
  font-size: 18px;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__description > p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
  }
}

#portfolio-page .warranty__description > p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#portfolio-page .warranty__description > p sup {
  vertical-align: baseline;
  font-size: 1em;
}

#portfolio-page .warranty__accordion {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  margin-bottom: 19px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion {
    margin-bottom: 20px;
  }
}

#portfolio-page .warranty__accordion .aaaem-accordion__header {
  padding-left: 18px;
  padding-right: 16px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion .aaaem-accordion__header {
    padding-left: 39px;
    padding-right: 22px;
  }
}

#portfolio-page .warranty__accordion .aaaem-accordion__title {
  letter-spacing: -.6px;
}

#portfolio-page .warranty__accordion-content {
  padding-left: 15px;
  padding-right: 19px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-content {
    padding-left: 39px;
    padding-right: 42px;
  }
}

#portfolio-page .warranty__accordion-content > :first-child {
  padding-top: 20px;
  padding-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-content > :first-child {
    padding-top: 40px;
    padding-bottom: 34px;
  }
}

#portfolio-page .warranty__accordion-content-intro > p, #portfolio-page .warranty__accordion-plans > p {
  margin-bottom: var(--emu-common-spacing-none);
}

#portfolio-page .warranty__accordion-content-intro p, #portfolio-page .warranty__accordion-content-intro li, #portfolio-page .warranty__accordion-plans p, #portfolio-page .warranty__accordion-plans li {
  color: var(--emu-semantic-colors-tertiary-light);
  font-size: 14px;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-content-intro p, #portfolio-page .warranty__accordion-content-intro li, #portfolio-page .warranty__accordion-plans p, #portfolio-page .warranty__accordion-plans li {
    letter-spacing: -.54px;
    font-size: 18px;
  }
}

#portfolio-page .warranty__accordion-content-intro b, #portfolio-page .warranty__accordion-plans b {
  color: var(--emu-semantic-colors-primary-dark);
}

#portfolio-page .warranty__accordion-content-intro > p {
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-content-intro > p {
    margin-bottom: 22px;
  }
}

#portfolio-page .warranty__accordion-content-intro > p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#portfolio-page .warranty__accordion-content-intro b sup {
  font-size: 80%;
}

#portfolio-page .warranty__accordion-content-intro sup {
  font-size: 70%;
}

#portfolio-page .warranty__accordion-plans {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-plans {
    margin-bottom: 33px;
  }
}

#portfolio-page .warranty__accordion-plans > ul {
  padding-left: var(--emu-common-spacing-none);
  list-style-type: none;
}

#portfolio-page .warranty__accordion-plans > ul > li {
  margin-bottom: 6px;
  padding-left: 9px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-plans > ul > li {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .warranty__accordion-plans > ul > li:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#portfolio-page .warranty__accordion-plans > ul > li:before {
  content: "";
  background-color: var(--emu-common-colors-black);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: inline-block;
  position: absolute;
  top: .45em;
  left: 2px;
}

#portfolio-page .warranty__accordion-plans sup {
  font-size: 70%;
}

#portfolio-page .warranty__accordion-plans > p > b > .emphasis {
  margin-top: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-small);
  display: inline-block;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-plans > p > b > .emphasis {
    margin-top: 23px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#portfolio-page .warranty__accordion-full-details {
  background-color: var(--emu-semantic-colors-primary-dark);
  flex-direction: column;
  margin-bottom: 18px;
  padding: 20px 30px;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-full-details {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 40px;
    padding-right: 21px;
  }
}

#portfolio-page .warranty__accordion-full-details > * {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-full-details > * {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .warranty__accordion-full-details .cmp-title__text {
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 22px;
  font-weight: var(--emu-semantic-font-weight-600);
  margin-bottom: 17px;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-full-details .cmp-title__text {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-small);
    letter-spacing: -.54px;
    font-size: 18px;
  }
}

#portfolio-page .warranty__accordion-full-details .cmp-title__text sup {
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-full-details .cmp-title__text sup {
    font-size: 65%;
  }
}

#portfolio-page .warranty__accordion-full-details > .button .emu-button {
  padding-left: 43px;
  padding-right: 43px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-full-details > .button .emu-button {
    padding-left: 42px;
    padding-right: 42px;
  }
}

#portfolio-page .warranty__accordion-full-details > .button .emu-button .cmp-button__text {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 18px;
  line-height: 14px;
  font-weight: var(--emu-semantic-font-weight-500);
}

#portfolio-page .warranty__accordion-extra-info > p {
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-tertiary-light);
  font-weight: var(--emu-common-font-weight-light);
  padding-left: 7px;
  font-size: 10px;
  line-height: 1.2;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__accordion-extra-info > p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
  }
}

#portfolio-page .warranty__accordion-extra-info > p > .emphasis {
  font-size: .6em;
  position: absolute;
  top: 0;
  left: 1px;
}

#portfolio-page .warranty__ad {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__ad {
    margin-left: 117px;
    margin-right: 117px;
  }
}

#portfolio-page .warranty__ad-img img {
  width: 100%;
}

#portfolio-page .warranty__ad-img-info {
  position: absolute;
  bottom: 8px;
  right: 14px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__ad-img-info {
    bottom: 10px;
    right: 20px;
  }
}

#portfolio-page .warranty__ad-img-info > p {
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__ad-img-info > p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 22px;
  }

  #portfolio-page .warranty__ad-img-info > p > br {
    display: none;
  }
}

#portfolio-page .warranty__ad-img-info b {
  font-weight: var(--emu-semantic-font-weight-600);
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__ad-img-info b {
    font-weight: var(--emu-semantic-font-weight-500);
  }
}

#portfolio-page .warranty__tag {
  position: absolute;
  top: 0;
  right: 14px;
}

@media (min-width: 1024px) {
  #portfolio-page .warranty__tag {
    right: 26px;
  }
}

#portfolio-page .warranty__tag img {
  max-width: 163px;
}

#portfolio-page .ns-patch {
  background: linear-gradient(0deg, var(--emu-semantic-colors-primary-light) 0%, var(--emu-common-colors-white) 100%);
  padding-top: 52px;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch {
    padding-left: var(--emu-common-spacing-none);
    padding-top: 90px;
    padding-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .ns-patch__title {
  margin-bottom: var(--emu-common-spacing-medium);
  padding-left: 47px;
  padding-right: 47px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__title {
    margin-bottom: 40px;
    padding-left: 149px;
    padding-right: 149px;
  }
}

#portfolio-page .ns-patch__title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: 57.6px;
  }

  #portfolio-page .ns-patch__title .cmp-title__text sup {
    font-size: 55%;
  }

  #portfolio-page .ns-patch__img-with-description {
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 215px;
    padding-right: 92px;
    display: flex;
  }
}

#portfolio-page .ns-patch__img-with-description > .container {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img-with-description > .container {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    flex-shrink: 0;
    width: auto;
  }

  #portfolio-page .ns-patch__img-with-description > .text {
    margin-right: 41px;
  }
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content {
  padding: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img-with-description .profile-img-map__content {
    width: max-content;
    padding: 21px 30px;
  }
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content--active {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img-with-description .profile-img-map__content--active {
    transform: translate(calc(-100% + 105px), calc(19px + var(--marker-height, 0)));
    flex-direction: row-reverse;
    align-items: center;
  }

  #portfolio-page .ns-patch__img-with-description .profile-img-map__content--active:before {
    left: auto;
    right: 90px;
    transform: translate(50%, calc(1px - 100%));
  }
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content img {
  border-radius: 10px;
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text, #portfolio-page .ns-patch__img-with-description .profile-img-map__content > .image {
  flex-shrink: 0;
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  max-width: 90px;
  margin-top: 20px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    max-width: 228px;
  }
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text p {
    letter-spacing: -1px;
    text-align: left;
    font-size: 18px;
  }
}

#portfolio-page .ns-patch__img-with-description .profile-img-map__content > .text p sup {
  font-size: 75%;
}

#portfolio-page .ns-patch__img {
  margin-bottom: 37px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__img {
    margin-bottom: 48px;
  }
}

#portfolio-page .ns-patch__description {
  margin-bottom: 36.86px;
  padding-left: 21px;
  padding-right: 14px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__description {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#portfolio-page .ns-patch__description p {
  color: var(--emu-semantic-colors-primary-dark);
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__description p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    letter-spacing: -1px;
    line-height: 36.5px;
  }
}

#portfolio-page .ns-patch__description p sup {
  font-size: 60%;
}

#portfolio-page .ns-patch__carousel.emu-carousel {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-500);
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel.emu-carousel {
    margin-left: 148px;
    margin-right: 148px;
  }
}

#portfolio-page .ns-patch__carousel .emu-carousel__item-container {
  background-color: var(--emu-common-colors-white);
  padding-top: 49px;
  padding-bottom: 112px;
  padding-left: 40px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel .emu-carousel__item-container {
    padding-left: var(--emu-common-spacing-none);
    justify-content: center;
    align-items: center;
    padding-top: 110px;
    padding-bottom: 98px;
    display: flex;
  }

  #portfolio-page .ns-patch__carousel .emu-carousel__item-container > .title, #portfolio-page .ns-patch__carousel .emu-carousel__item-container > .image {
    flex: 1 0 0;
  }

  #portfolio-page .ns-patch__carousel .emu-carousel__item-container > .title {
    margin-right: var(--emu-common-spacing-small);
  }
}

#portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav {
  -webkit-backdrop-filter: blur(19.5px);
  backdrop-filter: blur(19.5px);
  column-gap: var(--emu-common-spacing-none);
  background: #00afd01a;
  border-radius: 120px;
  padding: 7px 10px;
  bottom: 28px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav {
    padding-left: 20px;
    padding-right: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

#portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav button {
  color: var(--emu-semantic-colors-primary-500);
  opacity: .4;
  background-color: currentColor;
  border-color: currentColor;
  margin-right: 6px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav button {
    margin-right: 10px;
  }
}

#portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav button:last-child {
  margin-right: var(--emu-common-spacing-none);
}

#portfolio-page .ns-patch__carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav button.tns-nav-active {
  opacity: 1;
}

#portfolio-page .ns-patch__carousel-slide-title {
  min-height: 2lh;
  margin-bottom: 49px;
  padding-left: 3px;
  padding-right: 43px;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel-slide-title {
    margin-bottom: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-large);
    min-height: auto;
    margin-top: -10px;
    padding-left: 121px;
  }
}

#portfolio-page .ns-patch__carousel-slide-title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  letter-spacing: -1px;
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel-slide-title .cmp-title__text {
    text-align: left;
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 36px;
  }
}

#portfolio-page .ns-patch__carousel-slide-img {
  max-width: fit-content;
  height: 115px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel-slide-img {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    max-width: none;
    height: 170px;
  }
}

#portfolio-page .ns-patch__carousel-slide-img:after {
  content: "";
  background: linear-gradient(270deg, #f6fcfd 45.71%, #f6fcfd00 100%);
  width: 132px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #portfolio-page .ns-patch__carousel-slide-img:after {
    background: linear-gradient(270deg, var(--emu-common-colors-white) 34%, #fff0 100%);
    width: 335px;
    right: -9px;
  }
}

#portfolio-page .ns-patch__carousel-slide-img img {
  width: auto;
  max-width: none;
  height: 100%;
}

.cq-Editable-dom--container .profile-img-map__content, .cq-Editable-dom--container .flippable-card *, .cq-Editable-dom--container .img-map__main, .cq-Editable-dom--container .img-map__main > .container {
  opacity: 1 !important;
  width: 100% !important;
  display: block !important;
  position: static !important;
  transform: none !important;
}

#resources .ns-resources__banner .content-container {
  display: none;
}

#resources .ns-resources__heading {
  padding: 52px 19px 54px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__heading {
    padding-top: 49px;
  }
}

#resources .ns-resources__heading-text .cmp-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: 1px;
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 33px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__heading-text .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    letter-spacing: 1.6px;
    text-transform: none;
    line-height: 49px;
  }
}

#resources .ns-resources__heading-text--secondary .cmp-title__text {
  color: var(--emu-semantic-colors-primary-500);
}

#resources .ns-resources__heading-text sup {
  font-size: 24px;
  top: 7px;
  left: -2px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__heading-text sup {
    font-size: 40px;
  }
}

#resources .ns-resources__cards {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 15px;
  padding-left: 22px;
  padding-right: 7px;
  display: flex;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards {
    padding-bottom: 20px;
    padding-left: 48px;
    padding-right: 18px;
  }
}

#resources .ns-resources__cards .card {
  margin-bottom: 15px;
  margin-right: 15px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards .card {
    margin-bottom: 30px;
    margin-right: 30px;
  }
}

@media (min-width: 1440px) {
  #resources .ns-resources__cards {
    justify-content: left;
  }
}

#resources .ns-resources__cards .card {
  display: flex;
}

#resources .ns-resources__cards .card .emu-card__wrapper {
  border: var(--emu-semantic-border-width-thin) solid #00afd04d;
  max-width: 331px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards .card .emu-card__wrapper {
    max-width: 428px;
    height: 100%;
    min-height: 668px;
    padding: 29px 29px 40px;
  }
}

#resources .ns-resources__cards .card .emu-card__title-wrapper {
  display: none;
}

#resources .ns-resources__cards .card .emu-card__content-wrapper {
  height: 100%;
  display: flex;
}

#resources .ns-resources__cards .card .emu-card__content-wrapper > div {
  flex-direction: column;
  display: flex;
}

#resources .ns-resources__cards .card .emu-card__footer {
  margin-top: auto;
}

#resources .ns-resources__cards .card .emu-card__footer .button {
  text-align: center;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards .card .emu-card__footer .button {
    text-align: left;
  }
}

#resources .ns-resources__cards .card .emu-card__footer .button:not(:last-child) {
  margin-bottom: 10px;
}

#resources .ns-resources__cards .card .emu-card__footer .button span {
  width: 100%;
  display: block;
}

#resources .ns-resources__cards-card {
  height: 100%;
  display: flex;
}

#resources .ns-resources__cards-card-img {
  margin-bottom: 13px;
}

#resources .ns-resources__cards-card-title {
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards-card-title {
    margin-top: 22px;
    margin-bottom: 31px;
  }
}

#resources .ns-resources__cards-card-title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: 260;
}

#resources .ns-resources__cards-card-title .cmp-title__text sup {
  font-size: 20px;
  top: 5px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards-card-desc__mt-lg {
    margin-top: var(--emu-common-spacing-xl);
  }
}

#resources .ns-resources__cards-card-desc p {
  color: var(--emu-semantic-colors-tertiary-light);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-regular);
  text-transform: capitalize;
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 17.07px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards-card-desc p {
    font-size: 20px;
    line-height: var(--emu-common-line-heights-wide-large);
    text-transform: none;
  }
}

#resources .ns-resources__cards-card-btns-wrapper {
  margin-top: 17px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__cards-card-btns-wrapper {
    margin-top: var(--emu-common-spacing-none);
  }
}

#resources .ns-resources__cards-card-btn {
  justify-content: center;
  align-items: center;
  min-width: 180px;
}

#resources .ns-resources__cards-card-btn .cmp-button__text {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 16px;
  font-weight: var(--emu-semantic-font-weight-500);
  text-align: center;
  line-height: 14px;
}

#resources .ns-resources__cards-card-btn--multi-text {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-xxs);
  height: 40px;
}

#resources .ns-resources__cards-card-btn--multi-text > span {
  padding-right: var(--emu-common-spacing-xs);
}

#resources .ns-resources__cards-card-btn--multi-text .cmp-button__text {
  font-size: 16px;
  line-height: 15px;
  display: block;
}

#resources .ns-resources__cards-card-btn--multi-text .cmp-button__icon-text {
  text-align: center;
  font-size: 13px;
  line-height: 12px;
}

#resources .ns-resources__cards-card-btn:hover {
  box-shadow: 0 0 4px #00000040;
}

#resources .ns-resources__cards-card-btn:active {
  box-shadow: 2px 2px 8px #0000004d;
}

#resources .ns-resources__learn-more {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  font-weight: 260;
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  padding: 23px 49px 25px;
}

@media (min-width: 1024px) {
  #resources .ns-resources__learn-more {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    letter-spacing: -.8px;
    padding: 58px 40px 60px;
  }
}

#resources .ns-resources__learn-more p, #resources .ns-resources__learn-more li, #resources .ns-resources__learn-more ul, #resources .ns-resources__learn-more ol {
  margin-bottom: var(--emu-common-spacing-none);
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}

@media (min-width: 1024px) {
  #resources .ns-resources__learn-more p, #resources .ns-resources__learn-more li, #resources .ns-resources__learn-more ul, #resources .ns-resources__learn-more ol {
    max-width: 824px;
    margin-left: auto;
    margin-right: auto;
  }
}

#error-page .error {
  padding-top: var(--emu-common-spacing-xl);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-medium);
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #error-page .error {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}

#error-page .error__title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-body);
  color: var(--emu-semantic-colors-primary-400);
  font-size: 96px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -.9px;
  margin-bottom: var(--emu-common-spacing-large);
  line-height: 89px;
}

@media (min-width: 1024px) {
  #error-page .error__title .cmp-title__text {
    letter-spacing: -1.4px;
    margin-bottom: var(--emu-common-spacing-xl);
    font-size: 124px;
    line-height: 111px;
  }
}

#error-page .error__description p {
  color: var(--emu-semantic-colors-primary-600);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: var(--emu-common-spacing-large);
  line-height: 28px;
}

@media (min-width: 1024px) {
  #error-page .error__description p {
    margin-bottom: var(--emu-common-spacing-xl);
  }
}

#error-page .error__description a {
  color: inherit;
}

#error-page .error .button {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

#error-page .error__home-btn {
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
}

#error-page .error__home-btn .cmp-button__text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 13px;
}

.aaaem-accordion {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-none);
  transition: all var(--emu-common-other-time-transition-short) linear var(--emu-common-other-time-duration-instant);
}

.aaaem-accordion.aaaem-accordion--has-expanded-item {
  border-color: var(--emu-semantic-colors-primary-500);
  transition: none;
}

.aaaem-accordion .image {
  line-height: 0;
}

.aaaem-accordion__header {
  background-color: var(--emu-semantic-colors-primary-dark);
  position: relative;
}

.aaaem-accordion__header:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-500);
  opacity: 0;
  transition: all var(--emu-common-other-time-transition-short) linear;
  width: calc(100% - 30px);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 15px;
}

@media (min-width: 1024px) {
  .aaaem-accordion__header:after {
    width: calc(100% - 70px);
    left: 40px;
  }
}

.aaaem-accordion__header.-ba-is-active {
  background-color: var(--emu-common-colors-white);
}

.aaaem-accordion__header.-ba-is-active:after {
  opacity: 1;
  bottom: -3px;
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__title {
  color: var(--emu-semantic-colors-primary-dark);
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
  border-color: var(--emu-component-lists-accordion-item-header-icon-color-fill-open-light);
}

.aaaem-accordion__button {
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  .aaaem-accordion__button {
    padding-bottom: 21px;
  }
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
  border: var(--emu-common-border-width-thin) solid var(--emu-component-lists-accordion-item-header-icon-color-fill-closed-light);
  border-radius: 50%;
  flex-shrink: 0;
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon:before, .aaaem-accordion__button.caret-icon .aaaem-accordion__icon:after {
  height: 40%;
  margin-top: 25%;
}

.aaaem-accordion__title {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-common-colors-white);
  line-height: var(--emu-common-line-heights-wide-large);
}

@media (min-width: 1024px) {
  .aaaem-accordion__title {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    letter-spacing: -.84px;
    line-height: 33.6px;
  }
}



.emu-carousel[data-component="carousel-v2"] {
  background-color: var(--emu-semantic-colors-primary-light);
}

.emu-carousel[data-component="carousel-v2"][data-nav="true"] {
  padding-bottom: var(--emu-common-spacing-none);
}

.emu-carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav {
  background-color: #ffffff1a;
  border-radius: 100px;
  bottom: 20px;
}

@media (min-width: 1024px) {
  .emu-carousel[data-component="carousel-v2"][data-nav="true"] .tns-nav {
    border-radius: 120px;
    bottom: 80px;
    left: 8.25%;
    transform: none;
  }
}

.checklist-modal .modal-content {
  padding: var(--emu-common-spacing-none);
  max-height: 100%;
  overflow: auto;
}

.checklist-modal .modal-content button.main-close.close {
  z-index: var(--emu-common-other-z-index-layer);
  right: 18px;
}

@media (min-width: 1024px) {
  .checklist-modal .modal-content button.main-close.close {
    right: 31px;
  }
}

.checklist-modal .modal-content > .container {
  max-height: min(368px, 100vh - 16px);
  padding: 31px 13.5px 20px;
  overflow: auto;
}

@media (min-width: 1024px) {
  .checklist-modal .modal-content > .container {
    max-height: min(622px, 100vh - 16px);
    padding: 72px 75px 50px;
  }
}

.checklist-modal__title .emu-title__text {
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-semantic-font-weight-600);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-bottom: var(--emu-common-spacing-medium);
  padding-left: 55px;
  padding-right: 55px;
  line-height: 14px;
}

@media (min-width: 1024px) {
  .checklist-modal__title .emu-title__text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    color: var(--emu-semantic-colors-primary-dark);
    line-height: normal;
  }
}

.checklist-modal__text {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 14px;
}

@media (min-width: 1024px) {
  .checklist-modal__text {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 1.2;
  }
}

.checklist-modal__text .emphasis {
  display: inline-block;
}

.checklist-modal__text p {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .checklist-modal__text p {
    margin-bottom: 17px;
  }
}

.checklist-modal__text p:last-child {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.checklist-modal__text p, .checklist-modal__text li {
  font: inherit;
}

.checklist-modal__text ul {
  margin-bottom: 12px;
  padding-left: 7.5px;
  list-style: none;
}

@media (min-width: 1024px) {
  .checklist-modal__text ul {
    margin-bottom: 15px;
    padding-left: 13.5px;
  }
}

.checklist-modal__text li {
  margin-bottom: var(--emu-common-spacing-xs);
  position: relative;
}

@media (min-width: 1024px) {
  .checklist-modal__text li {
    margin-bottom: 9px;
  }
}

.checklist-modal__text li:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  top: 3px;
  left: -8px;
}

@media (min-width: 1024px) {
  .checklist-modal__text li:before {
    top: 5px;
    left: -13px;
  }
}

.checklist-modal__text a, .checklist-modal__text a:hover, .checklist-modal__text a:focus, .checklist-modal__text a:active {
  color: var(--emu-semantic-colors-primary-dark);
  text-decoration: underline;
}

.emu-modal.connect-with-us-modal .modal-content {
  max-width: 400px;
}

.emu-modal.connect-with-us-modal .modal-content > .container > .cmp-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.emu-modal.connect-with-us-modal .modal-content p {
  font-weight: var(--emu-semantic-font-weight-500);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 14px;
  line-height: 16.8px;
}

@media (min-width: 1024px) {
  .emu-modal.connect-with-us-modal .modal-content p {
    font-size: 20px;
    line-height: var(--emu-common-line-heights-wide-medium);
  }
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__container > .container {
  width: 100%;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__img {
  margin-bottom: 20px;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__img img {
  width: 30px;
}

@media (min-width: 1024px) {
  .emu-modal.connect-with-us-modal .connect-with-us-modal__img img {
    width: 48px;
  }
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__text {
  margin-bottom: 20px;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__links {
  flex-direction: column;
  display: flex;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__links > .button {
  width: 100%;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__links > .button:not(:first-child) {
  margin-top: 10px;
}

.emu-modal.connect-with-us-modal .connect-with-us-modal__links > .button .emu-button {
  font-size: 14px;
  font-family: var(--emu-semantic-font-families-body);
  padding-left: var(--emu-common-spacing-medium);
  padding-top: 14px;
  padding-right: var(--emu-common-spacing-medium);
  font-weight: var(--emu-semantic-font-weight-600);
  justify-content: center;
  width: 100%;
  max-width: none;
  padding-bottom: 14px;
}

@media (min-width: 1024px) {
  .emu-modal.connect-with-us-modal .connect-with-us-modal__links > .button .emu-button {
    font-size: var(--emu-common-font-sizes-wide-medium);
    padding: 13px 28px;
  }
}

[data-component="container-isi-v2"] {
  z-index: var(--emu-common-other-z-index-isi);
  position: relative;
}

[data-component="container-isi-v2"] .isi-container {
  border: none;
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content {
  background-color: var(--emu-common-colors-transparent);
}

[data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content, [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content.js-expand {
  height: var(--isi-content-height, 0);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .ns-isi__title {
    margin-bottom: 10px;
  }

  [data-component="container-isi-v2"] .isi-container .aaaem-isi-container__isi-content .ns-isi__warnings-box ul {
    margin-top: 10px;
  }
}

[data-component="container-isi-v2"].aaaem-isi-holder .aaaem-isi-container__isi-content > div:first-child {
  padding: 21px;
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"].aaaem-isi-holder .aaaem-isi-container__isi-content > div:first-child {
    padding: 24px 86px;
  }
}

[data-component="container-isi-v2"].aaaem-isi-holder.js-content-shown .aaaem-isi-container__isi-content > div:first-child {
  padding: 30px 16.5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"].aaaem-isi-holder.js-content-shown .aaaem-isi-container__isi-content > div:first-child {
    padding: 100px 86px;
  }
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-primary-700);
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__banner-content p {
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 16px;
  font-weight: var(--emu-semantic-font-weight-600);
  padding-right: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 19.5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__banner-content p {
    font-size: 20px;
    line-height: 24.38px;
  }
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  width: 100%;
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button {
  text-align: left;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button {
    padding: 9px 86px;
  }
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon {
  margin-right: var(--emu-common-spacing-none);
  align-self: flex-start;
  width: 16px;
  height: 16px;
  position: relative;
  right: 4px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon {
    align-self: center;
    right: -13px;
  }
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon:before, [data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon:after {
  background-color: var(--emu-common-colors-white);
  height: var(--emu-common-sizing-small);
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .cmp-button__text {
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 16px;
  line-height: 19.5px;
  font-weight: var(--emu-semantic-font-weight-600);
  margin-right: 34px;
  margin-bottom: var(--emu-common-spacing-none);
  display: block;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .cmp-button__text {
    font-size: 20px;
    line-height: var(--emu-common-line-heights-wide-large);
  }
}

[data-component="container-isi-v2"] .ns-isi__container .aaaem-isi-container__isi-content .ns-isi__title-main {
  display: none;
}

[data-component="container-isi-v2"] .ns-isi__content {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__content {
    margin-bottom: 30px;
  }
}

[data-component="container-isi-v2"] .ns-isi__title {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__title {
    margin-bottom: 12px;
  }
}

[data-component="container-isi-v2"] .ns-isi__title .emu-title__text {
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 18px;
  font-weight: var(--emu-semantic-font-weight-600);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 21.94px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__title .emu-title__text {
    letter-spacing: 1.2px;
  }
}

[data-component="container-isi-v2"] .ns-isi__title-main {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__title-main {
    margin-bottom: 28px;
  }
}

[data-component="container-isi-v2"] .ns-isi__info p, [data-component="container-isi-v2"] .ns-isi__info li {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 18px;
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 21.94px;
}

[data-component="container-isi-v2"] .ns-isi__info p {
  margin-bottom: var(--emu-common-spacing-none);
  padding-right: 7px;
}

[data-component="container-isi-v2"] .ns-isi__info ul {
  padding-left: var(--emu-common-spacing-medium);
  margin-top: 5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__info ul {
    margin-top: 14px;
  }
}

[data-component="container-isi-v2"] .ns-isi__info li:not(:last-child) {
  margin-bottom: var(--emu-common-spacing-small);
}

[data-component="container-isi-v2"] .ns-isi__info li b {
  font-weight: var(--emu-semantic-font-weight-600);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__info li {
    letter-spacing: .1px;
  }
}

[data-component="container-isi-v2"] .ns-isi__warnings-box {
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  padding: 18px 9.38px 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__warnings-box {
    padding: 36px 37px;
  }
}

[data-component="container-isi-v2"] .ns-isi__warnings-box .cmp-title__text {
  letter-spacing: normal;
  color: var(--emu-common-colors-black);
}

[data-component="container-isi-v2"] .ns-isi__warnings-box ul {
  padding-left: 18px;
}

[data-component="container-isi-v2"] .ns-isi__warnings-box li {
  letter-spacing: .3px;
}

[data-component="container-isi-v2"] .ns-isi__warnings-box li:not(:last-child) {
  margin-bottom: 11px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__warnings-box li {
    letter-spacing: normal;
    margin-bottom: 11px;
  }

  [data-component="container-isi-v2"] .ns-isi__contraindications .cmp-title {
    margin-bottom: 20px;
  }

  [data-component="container-isi-v2"] .ns-isi__contraindications ul {
    margin-top: var(--emu-common-spacing-small);
  }
}

[data-component="container-isi-v2"] .ns-isi__contraindications li:not(:last-child) {
  margin-bottom: 6px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__contraindications li:not(:last-child) {
    margin-bottom: 11px;
  }
}

[data-component="container-isi-v2"] .ns-isi__additional-warnings li:not(:last-child) {
  margin-bottom: 6px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__additional-warnings li:not(:last-child) {
    margin-bottom: 10px;
  }

  [data-component="container-isi-v2"] .ns-isi__additional-warnings li {
    letter-spacing: .05px;
  }

  [data-component="container-isi-v2"] .ns-isi__precautions ul {
    margin-top: 15px;
  }
}

[data-component="container-isi-v2"] .ns-isi__precautions li:not(:last-child) {
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__precautions li:not(:last-child) {
    margin-bottom: 10px;
  }
}

[data-component="container-isi-v2"] .ns-isi__adverse-events p:not(:last-child) {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__adverse-events p:not(:last-child) {
    margin-bottom: 30px;
  }
}

[data-component="container-isi-v2"] .ns-isi__adverse-events p a {
  color: var(--emu-semantic-colors-primary-dark);
}

[data-component="container-isi-v2"] .ns-isi__adverse-events p .emphasis {
  letter-spacing: .9px;
  color: var(--emu-semantic-colors-primary-dark);
  line-height: 25px;
}

[data-component="container-isi-v2"] .ns-isi__indications p {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__indications p {
    padding-top: 6px;
  }

  [data-component="container-isi-v2"] .ns-isi__indications ul {
    margin-top: 10px;
  }
}

[data-component="container-isi-v2"] .ns-isi__indications li:not(:last-child) {
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__indications li:not(:last-child) {
    margin-bottom: 10px;
  }
}

[data-component="container-isi-v2"] .ns-isi__safety .cmp-title__text p {
  color: inherit;
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: inherit;
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 24.38px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__safety .cmp-title__text p {
    color: var(--emu-semantic-colors-primary-dark);
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    letter-spacing: normal;
  }
}

[data-component="container-isi-v2"] .ns-isi__safety .cmp-title__text p:not(:last-child) {
  margin-bottom: var(--emu-common-spacing-small);
}

[data-component="container-isi-v2"] .ns-isi__safety li:not(:last-child) {
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__safety li:not(:last-child) {
    margin-bottom: 10px;
  }
}

[data-component="container-isi-v2"] .ns-isi__warnings li:not(:last-child) {
  margin-bottom: 6px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__warnings li:not(:last-child) {
    margin-bottom: 10px;
  }

  [data-component="container-isi-v2"] .ns-isi__warnings {
    margin-bottom: 40px;
  }
}

[data-component="container-isi-v2"] .ns-isi__adverse-reactions p a {
  color: var(--emu-semantic-colors-primary-dark);
}

[data-component="container-isi-v2"] .ns-isi__adverse-reactions p:not(:last-child) {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .ns-isi__adverse-reactions p:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.js-modal-opened .isi-scrollable .isi-container {
  z-index: var(--emu-common-other-z-index-isi);
}

.plyr {
  --plyr-color-main: var(--emu-semantic-colors-primary-500);
  justify-content: center;
  align-items: center;
  display: flex;
}

.plyr--stopped .plyr__controls {
  visibility: hidden;
}

.plyr__control {
  --plyr-color-main: #00145099;
}

footer .upper_section > .footer__logo {
  display: none;
}

footer .footer__container {
  isolation: isolate;
  background-color: var(--emu-semantic-colors-primary-dark);
  padding: 40px 20px;
}

@media (min-width: 1024px) {
  footer .footer__container {
    padding: 70px 85px 150px 86px;
  }
}

footer .footer__container .text:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  footer .footer__container .text:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

footer .footer__container .emu-navigation__content-wrapper {
  position: static;
}

footer .footer__container .emu-navigation__content-wrapper nav a {
  border: none;
}

footer .footer__container .emu-navigation__content-wrapper nav a:hover {
  background-color: var(--emu-common-colors-transparent);
}

footer .footer__logo {
  margin-bottom: var(--emu-common-spacing-medium);
  text-align: center;
}

@media (min-width: 1024px) {
  footer .footer__logo {
    text-align: left;
    margin-bottom: 36px;
  }
}

footer .footer__logo a {
  display: inline-block;
}

footer .footer__logo img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  footer .footer__logo img {
    margin-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
  }
}

footer .footer__content p {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-common-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  letter-spacing: -.5px;
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
}

@media (min-width: 1024px) {
  footer .footer__content p {
    text-align: left;
    letter-spacing: normal;
    font-size: 24px;
    line-height: 29.26px;
  }
}

footer .footer__content-call p {
  letter-spacing: normal;
}

@media (min-width: 1024px) {
  footer .footer__content-trademarks br:first-child {
    display: none;
  }
}

footer .footer__navigation {
  z-index: var(--emu-common-other-z-index-base);
  background-color: var(--emu-semantic-colors-primary-dark);
}

footer .footer__navigation ul {
  flex-direction: column;
}

@media (min-width: 1024px) {
  footer .footer__navigation ul {
    flex-direction: row;
  }
}

footer .footer__navigation li {
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  footer .footer__navigation li:not(:last-of-type) {
    border-right: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  }

  footer .footer__navigation li:not(:first-of-type) a {
    padding-left: 9px;
  }

  footer .footer__navigation li:not(:last-of-type) a {
    padding-right: 9px;
  }
}

footer .footer__navigation li a {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-common-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: var(--emu-common-line-heights-wide-large);
  text-align: center;
  text-decoration: underline;
}

@media (min-width: 1024px) {
  footer .footer__navigation li a {
    text-align: left;
    font-size: 24px;
    line-height: 29.26px;
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  margin-bottom: 40px;
  position: sticky;
  top: 0;
  float: none !important;
}

@media (min-width: 582px) {
  header.experiencefragment {
    margin-bottom: 25px;
  }
}

@media (min-width: 1024px) {
  header.experiencefragment {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#main-header-wrapper .main-header {
  background-color: var(--emu-common-colors-white);
  padding: 20px 18px 20px 21px;
  position: relative;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header {
    padding-left: var(--emu-common-spacing-large);
    padding-top: 54px;
    padding-right: var(--emu-common-spacing-large);
    border-bottom: var(--emu-common-border-width-medium) solid #e5e5e5;
    padding-bottom: 5px;
  }
}

@media (min-width: 1280px) {
  #main-header-wrapper .main-header {
    padding-bottom: 18px;
  }
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__inner {
    align-items: center;
    display: flex;
  }

  #main-header-wrapper .main-header__inner > .container:last-child {
    flex: 1;
  }

  #main-header-wrapper .main-header__logo {
    margin-right: 10px;
  }
}

@media (min-width: 1440px) {
  #main-header-wrapper .main-header__logo {
    margin-right: 35px;
  }
}

#main-header-wrapper .main-header__logo img {
  height: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__logo img {
    height: 27px;
  }
}

#main-header-wrapper .main-header__toggler-logo-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#main-header-wrapper .main-header__toggler-logo-container > .button {
  font-size: 0;
  line-height: 0;
}

#main-header-wrapper .main-header__mobile-nav-visibility-toggler {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  border-width: var(--emu-common-border-width-none);
  background-image: url("resources/images/hamburger_menu.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__mobile-nav-visibility-toggler {
    display: none;
  }
}

#main-header-wrapper .main-header__mobile-nav-visibility-toggler.js-toggle-on {
  background-image: url("resources/images/close_x.svg");
}

#main-header-wrapper .main-header__links {
  height: calc(100vh - 60px - var(--isiBannerHeight, 0)  - var(--isi-content-height, 0));
  display: none;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__links {
    justify-content: space-between;
    align-items: center;
    height: auto;
    display: flex;
    overflow-y: visible;
  }
}

@media (max-width: 1023px) {
  #main-header-wrapper .main-header__links.js-toggle-on {
    display: revert;
    background-color: var(--emu-semantic-colors-primary-light);
    z-index: var(--emu-common-other-z-index-layer);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__links > .container:nth-child(2) {
    margin-left: auto;
  }
}

#main-header-wrapper .main-header__see-full-information {
  background-color: var(--emu-semantic-colors-primary-dark);
  padding-left: var(--emu-common-spacing-medium);
  padding-top: 5px;
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__see-full-information {
    max-width: fit-content;
    padding: 12px 35px 13px;
    left: 32px;
  }
}

@media (min-width: 1440px) {
  #main-header-wrapper .main-header__see-full-information {
    margin-left: calc(50vw - 720px);
  }
}

#main-header-wrapper .main-header__see-full-information > .text {
  margin-left: auto;
}

#main-header-wrapper .main-header__see-full-information > .text p {
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 15px;
}

#main-header-wrapper .main-header__see-full-information > .text p a {
  font-weight: var(--emu-semantic-font-weight-600);
}

#main-header-wrapper .main-header__see-full-information > .text p a, #main-header-wrapper .main-header__see-full-information > .text p a:hover, #main-header-wrapper .main-header__see-full-information > .text p a:focus, #main-header-wrapper .main-header__see-full-information > .text p a:active {
  color: inherit;
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  text-decoration: underline;
}

#main-header-wrapper .main-header .emu-navigation__content-wrapper {
  position: static;
}

#main-header-wrapper .main-header__primary-links {
  padding-left: 20px;
  padding-top: var(--emu-common-spacing-xxs);
  background-color: var(--emu-common-colors-white);
  padding-bottom: 7px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__primary-links {
    padding: var(--emu-common-spacing-none);
  }
}

#main-header-wrapper .main-header__primary-links > ul {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__primary-links > ul {
    flex-direction: row;
  }
}

#main-header-wrapper .main-header__primary-links > ul li {
  justify-content: center;
  align-items: center;
  display: flex;
}

#main-header-wrapper .main-header__primary-links > ul li:not(:last-child) {
  margin-bottom: 1px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__primary-links > ul li:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: 5px;
  }
}

@media (min-width: 1280px) {
  #main-header-wrapper .main-header__primary-links > ul li:not(:last-child) {
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1440px) {
  #main-header-wrapper .main-header__primary-links > ul li:not(:last-child) {
    margin-right: 10px;
  }
}

#main-header-wrapper .main-header__primary-links a {
  padding-top: 15px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 19px;
  padding-left: var(--emu-common-spacing-small);
  border-width: var(--emu-common-border-width-none);
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--emu-semantic-font-weight-600);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__primary-links a {
    padding: 7px 10px;
    font-size: 12px;
    line-height: 1.1;
  }
}

@media (min-width: 1280px) {
  #main-header-wrapper .main-header__primary-links a {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  #main-header-wrapper .main-header__primary-links a {
    padding-left: 15.85px;
    padding-right: 15.85px;
  }
}

#main-header-wrapper .main-header__primary-links a, #main-header-wrapper .main-header__primary-links a:hover, #main-header-wrapper .main-header__primary-links a:focus, #main-header-wrapper .main-header__primary-links a:active, #main-header-wrapper .main-header__primary-links a:visited {
  background-color: inherit;
  color: var(--emu-semantic-colors-primary-dark);
}

#main-header-wrapper .main-header__primary-links a.scroll-section-active {
  color: var(--emu-semantic-colors-primary-500);
}

#main-header-wrapper .main-header__revealable-resource-links-container {
  background-color: var(--emu-common-colors-white);
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__revealable-resource-links-container {
    background-color: var(--emu-common-colors-transparent);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    position: relative;
  }

  #main-header-wrapper .main-header__external-revealable-links .button {
    line-height: 17px;
    top: -2px;
  }
}

#main-header-wrapper .main-header__resource-links-visibility-toggler, #main-header-wrapper .main-header__dfu-links-visibility-toggler, #main-header-wrapper .main-header__external-links-visibility-toggler {
  font-family: var(--emu-semantic-font-families-body);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
  align-items: center;
}

#main-header-wrapper .main-header__resource-links-visibility-toggler .cmp-button__text, #main-header-wrapper .main-header__dfu-links-visibility-toggler .cmp-button__text, #main-header-wrapper .main-header__external-links-visibility-toggler .cmp-button__text {
  margin-right: 10px;
}

#main-header-wrapper .main-header__resource-links-visibility-toggler > svg, #main-header-wrapper .main-header__dfu-links-visibility-toggler > svg, #main-header-wrapper .main-header__external-links-visibility-toggler > svg {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  transition: transform .3s;
}

#main-header-wrapper .main-header__resource-links-visibility-toggler.js-toggle-on > svg, #main-header-wrapper .main-header__dfu-links-visibility-toggler.js-toggle-on > svg, #main-header-wrapper .main-header__external-links-visibility-toggler.js-toggle-on > svg {
  transform: rotate(-180deg);
}

#main-header-wrapper .main-header__resource-links-visibility-toggler {
  padding-top: 12px;
  padding-bottom: var(--emu-common-spacing-small);
  font-weight: var(--emu-semantic-font-weight-600);
  justify-content: center;
  width: 100%;
  max-width: none;
  padding-right: 6px;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-links-visibility-toggler {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    margin-top: var(--emu-common-spacing-xxs);
    width: auto;
    padding: 10px 23px;
  }
}

#main-header-wrapper .main-header__resource-links-visibility-toggler .cmp-button__text {
  margin-right: 15px;
  display: inline-block;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-links-visibility-toggler .cmp-button__text {
    margin-right: 9px;
  }
}

#main-header-wrapper .main-header__resource-links-visibility-toggler svg {
  margin-top: 6px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-links-visibility-toggler svg {
    margin-top: var(--emu-common-spacing-xs);
    width: 11px;
  }
}

#main-header-wrapper .main-header__resource-links, #main-header-wrapper .main-header #main-header__dfu-links, #main-header-wrapper .main-header #main-header__external-links-container {
  display: none;
}

#main-header-wrapper .main-header__resource-links.js-toggle-on, #main-header-wrapper .main-header #main-header__dfu-links.js-toggle-on, #main-header-wrapper .main-header #main-header__external-links-container.js-toggle-on {
  display: revert;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-links {
    max-height: calc(100vh - 96px - var(--isiBannerHeight, 0)  - var(--isi-content-height, 0));
    width: 270px;
    position: absolute;
    top: 40px;
    right: 0;
    overflow-y: auto;
  }
}

#main-header-wrapper .main-header__resource-primary-links {
  background-color: var(--emu-common-colors-transparent);
}

#main-header-wrapper .main-header__resource-primary-links > ul {
  color: var(--emu-common-colors-white);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-primary-links > ul {
    color: var(--emu-common-colors-transparent);
  }

  #main-header-wrapper .main-header__resource-primary-links li {
    margin-bottom: 1px;
  }
}

#main-header-wrapper .main-header__resource-primary-links a {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--emu-semantic-font-weight-500);
  border-width: .5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__resource-primary-links a {
    font-size: 12px;
    line-height: 18px;
  }
}

#main-header-wrapper .main-header__resource-primary-links a, #main-header-wrapper .main-header__resource-primary-links a:hover, #main-header-wrapper .main-header__resource-primary-links a:focus, #main-header-wrapper .main-header__resource-primary-links a:visited, #main-header-wrapper .main-header__resource-primary-links a:active {
  background-color: var(--emu-common-colors-white);
  border-color: var(--emu-semantic-colors-primary-light);
  color: var(--emu-semantic-colors-primary-dark);
}

#main-header-wrapper .main-header__dfu-links-visibility-toggler {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-dark);
  border-width: .5px;
  border-color: var(--emu-semantic-colors-primary-light);
  font-weight: var(--emu-semantic-font-weight-500);
  justify-content: center;
  width: 100%;
  max-width: none;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__dfu-links-visibility-toggler {
    background-color: var(--emu-common-colors-white);
    margin-top: 1px;
    font-size: 12px;
    line-height: 19px;
  }
}

#main-header-wrapper .main-header__dfu-links-visibility-toggler.js-toggle-on {
  background-color: var(--emu-semantic-colors-primary-100);
}

#main-header-wrapper .main-header__dfu-links {
  background-color: var(--emu-common-colors-transparent);
}

#main-header-wrapper .main-header__dfu-links > ul {
  background-color: var(--emu-common-colors-white);
  flex-direction: column;
  padding-top: 1px;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__dfu-links > ul {
    background-color: var(--emu-common-colors-transparent);
  }
}

#main-header-wrapper .main-header__dfu-links li {
  margin-bottom: 1px;
}

#main-header-wrapper .main-header__dfu-links a {
  border-width: var(--emu-common-border-width-none);
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--emu-semantic-font-weight-500);
  padding: 10px 34px;
  overflow-x: hidden;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__dfu-links a {
    white-space: wrap;
    padding: 17px 18px;
    font-size: 12px;
    line-height: 16px;
  }
}

#main-header-wrapper .main-header__dfu-links a, #main-header-wrapper .main-header__dfu-links a:hover, #main-header-wrapper .main-header__dfu-links a:focus, #main-header-wrapper .main-header__dfu-links a:active, #main-header-wrapper .main-header__dfu-links a:visited {
  color: var(--emu-semantic-colors-primary-dark);
}

#main-header-wrapper .main-header__dfu-links a, #main-header-wrapper .main-header__dfu-links a:active {
  background-color: var(--emu-semantic-colors-primary-light);
}

#main-header-wrapper .main-header__dfu-links a:hover {
  background-color: var(--emu-semantic-colors-primary-100);
}

#main-header-wrapper .main-header__non-resource-links {
  background-color: var(--emu-semantic-colors-primary-light);
  padding: 6px 40px 38px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__non-resource-links {
    padding-left: var(--emu-common-spacing-large);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 6px;
    padding-right: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  #main-header-wrapper .main-header__non-resource-links-inner {
    align-items: center;
    display: flex;
  }

  #main-header-wrapper .main-header__non-resource-links-inner > div:last-child {
    margin-left: auto;
  }
}

#main-header-wrapper .main-header__external-links-visibility-toggler {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-dark);
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--emu-common-font-weight-regular);
  font-family: var(--emu-semantic-font-families-body);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  justify-content: space-between;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links-visibility-toggler {
    white-space: nowrap;
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    justify-content: flex-end;
    width: auto;
    margin-right: 52px;
    line-height: 17px;
  }
}

#main-header-wrapper .main-header__external-links-visibility-toggler .cmp-button__text {
  margin-right: var(--emu-common-spacing-xs);
}

#main-header-wrapper .main-header__external-links-visibility-toggler > svg {
  width: var(--emu-common-sizing-small);
  height: auto;
  margin-top: 10px;
  margin-right: -4px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links-visibility-toggler > svg {
    width: 12px;
    margin-top: 7px;
  }
}

#main-header-wrapper .main-header #main-header__external-links-container {
  background-color: var(--emu-common-colors-transparent);
  margin-top: 3px;
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header #main-header__external-links-container {
    background-color: var(--emu-semantic-colors-primary-light);
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

#main-header-wrapper .main-header #main-header__external-links-container > .button:last-child {
  position: static;
}

#main-header-wrapper .main-header__external-links {
  background-color: var(--emu-common-colors-transparent);
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links {
    background-color: var(--emu-semantic-colors-primary-light);
    padding-top: var(--emu-common-spacing-xs);
  }
}

#main-header-wrapper .main-header__external-links > ul {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links > ul {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  #main-header-wrapper .main-header__external-links li {
    margin-top: var(--emu-common-spacing-small);
    margin-bottom: var(--emu-common-spacing-small);
    border-right-width: var(--emu-common-border-width-thin);
    border-right-style: solid;
    border-right-color: var(--emu-semantic-colors-primary-dark);
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
  }
}

#main-header-wrapper .main-header__external-links li:not(:last-child) {
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links li:not(:last-child) {
    margin-bottom: 12px;
  }

  #main-header-wrapper .main-header__external-links li:nth-child(4n) {
    border-right-width: var(--emu-common-border-width-none);
  }
}

#main-header-wrapper .main-header__external-links a {
  border-width: var(--emu-common-border-width-none);
  padding-left: 20px;
  font-size: 18px;
  line-height: 1em;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links a {
    padding-left: var(--emu-common-spacing-none);
    text-align: center;
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 16.8px;
  }
}

#main-header-wrapper .main-header__external-links a, #main-header-wrapper .main-header__external-links a:hover, #main-header-wrapper .main-header__external-links a:focus, #main-header-wrapper .main-header__external-links a:active, #main-header-wrapper .main-header__external-links a:visited {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-dark);
}

#main-header-wrapper .main-header__external-links a i {
  font-style: normal;
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-top: 1px;
  line-height: 14px;
  display: block;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__external-links a i {
    font-size: 10px;
    line-height: 12px;
  }
}

#main-header-wrapper .main-header__close-external-links-btn {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
  z-index: var(--emu-common-other-z-index-header);
  display: none;
  position: absolute;
  top: 12px;
  right: 32px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__close-external-links-btn {
    display: revert;
  }
}

#main-header-wrapper .main-header__close-external-links-btn img {
  width: 12px;
}

#main-header-wrapper .main-header__internal-info-or-link {
  border-bottom-width: var(--emu-common-border-width-thin);
  border-bottom-style: solid;
  border-bottom-color: var(--emu-semantic-colors-primary-dark);
  padding-bottom: 12px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__internal-info-or-link {
    padding-bottom: var(--emu-common-spacing-none);
    border-bottom-width: var(--emu-common-border-width-none);
  }
}

#main-header-wrapper .main-header__internal-info-or-link > ul {
  padding-left: var(--emu-common-spacing-none);
  flex-direction: column;
  list-style: none;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__internal-info-or-link > ul {
    margin: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

#main-header-wrapper .main-header__internal-info-or-link > ul li:first-child {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__internal-info-or-link > ul li:first-child {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: 60px;
  }
}

#main-header-wrapper .main-header__internal-info-or-link > ul li {
  color: var(--emu-semantic-colors-primary-dark);
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__internal-info-or-link > ul li {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 17px;
  }
}

#main-header-wrapper .main-header__internal-info-or-link > ul a, #main-header-wrapper .main-header__internal-info-or-link > ul a:hover, #main-header-wrapper .main-header__internal-info-or-link > ul a:active, #main-header-wrapper .main-header__internal-info-or-link > ul a:visited {
  color: inherit;
  font: inherit;
  text-decoration: none;
}

#main-header-wrapper .main-header__social-links {
  align-items: center;
  min-width: max-content;
  margin-top: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  #main-header-wrapper .main-header__social-links {
    margin-top: var(--emu-common-spacing-none);
    min-height: 100%;
    position: relative;
    top: -2px;
  }
}

#main-header-wrapper .main-header__social-links > div {
  flex-shrink: 0;
}

#main-header-wrapper .main-header__social-links > div:not(:first-child) {
  margin-left: 18px;
}

#main-header-wrapper .main-header__social-links .button {
  font-size: 0;
  line-height: 0;
}

#main-header-wrapper .main-header__social-links button {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
}

#main-header-wrapper .main-header__social-links button svg {
  margin: var(--emu-common-spacing-none);
}

.emu-mapping-marker {
  color: var(--emu-semantic-colors-primary-500);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  width: auto;
  height: auto;
}

.emu-mapping-marker[aria-pressed="true"] span {
  transform: rotate(-90deg);
}

.emu-mapping-marker[aria-pressed="true"] span svg {
  filter: drop-shadow(-3px 0 2px #00000040);
}

.emu-mapping-marker span {
  transition: all var(--emu-common-other-time-duration-instant);
  transform: rotate(0);
}

.emu-mapping-marker span:before, .emu-mapping-marker span:after {
  display: none;
}

.emu-mapping-marker svg {
  margin: var(--emu-common-spacing-none);
  filter: drop-shadow(0 4px 2px #00000040);
}

.emu-mapping-marker--active svg {
  color: var(--emu-common-colors-white);
}

.emu-mapping-marker--active svg circle {
  fill: var(--emu-semantic-colors-primary-500);
  stroke: var(--emu-semantic-colors-primary-500);
}

.img-map__main {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.img-map__main > .container {
  height: 100%;
  position: absolute;
  top: 0;
}

.img-map__main .emu-image-map, .img-map__main img {
  width: 100%;
}

.img-map__content {
  pointer-events: none;
  opacity: 0;
  z-index: var(--emu-common-other-z-index-layer);
  transition: opacity var(--emu-common-other-time-duration-instant) ease-in-out;
  width: 100%;
  min-width: 130px;
  position: absolute;
}

@media (min-width: 1024px) {
  .img-map__content {
    transition-delay: var(--emu-common-other-time-delay-none);
    min-width: 309px;
  }
}

.cq-Editable-dom--container .img-map__content {
  opacity: 1;
  visibility: visible;
  position: static;
}

.img-map__content--active {
  pointer-events: initial;
  opacity: 1;
}

@media (min-width: 1024px) {
  .img-map__content--active {
    transition-delay: var(--emu-common-other-time-delay-long);
  }
}

.img-map__content--site {
  transform: translateY(66px);
}

@media (min-width: 1024px) {
  .img-map__content--site {
    min-width: 440px;
    transform: translateY(0);
  }
}

@media (max-width: 1023px) {
  .img-map__content--site .img-map__content-triangle--left-on-mobile {
    transform: translateX(-50%)translateY(-66px);
  }
}

@media (min-width: 1024px) {
  .img-map__content--design {
    min-width: 211px;
    transform: translateY(77px);
  }

  .img-map__content--design .img-map__content-triangle--right-on-desktop {
    transform: translateX(-50%)translateY(-73px);
  }

  .img-map__content--port {
    transform: translateY(132px);
  }

  .img-map__content--port .img-map__content-triangle--right-on-desktop {
    transform: translateX(-50%)translateY(-128px);
  }
}

.img-map__content-triangle {
  position: absolute;
  top: -18px;
  bottom: -.5px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1023px) {
  .img-map__content-triangle--left-on-mobile {
    top: 50%;
    right: -25px;
    bottom: unset;
    left: unset;
    position: absolute;
    transform: translateX(-50%)translateY(-50%);
  }

  .img-map__content-triangle--left-on-mobile img {
    transform: rotate(-30deg);
  }
}

@media (min-width: 1024px) {
  .img-map__content-triangle--right-on-desktop {
    top: 50%;
    bottom: unset;
    position: absolute;
    left: -4px;
    transform: translateX(-50%)translateY(-50%);
  }

  .img-map__content-triangle--right-on-desktop img {
    transform: rotate(30deg);
  }

  .img-map__content-triangle--bottom-on-desktop {
    top: unset;
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
  }

  .img-map__content-triangle--bottom-on-desktop img {
    transform: rotate(60deg);
  }
}

.img-map__card .emu-card__wrapper {
  padding: var(--emu-common-spacing-none);
}

.img-map__card .emu-card__title-wrapper {
  display: none;
}

@media (min-width: 1024px) {
  .img-map__card-img-text-container {
    display: flex;
  }
}

.img-map__card-img {
  margin-bottom: 12.43px;
}

.img-map__card-img__mx-sm {
  margin-left: 7px;
  margin-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .img-map__card-img__mx-lg {
    margin-left: 11px;
    margin-right: 11px;
  }
}

.img-map__card-img__mb-lg {
  margin-bottom: 21px;
}

.img-map__card-img img {
  margin-left: auto;
  margin-right: auto;
}

.cq-Editable-dom--container .img-map__card-img img {
  max-width: 200px;
}

.img-map__card-title {
  margin-bottom: var(--emu-common-spacing-small);
}

.img-map__card-title .cmp-title__text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: 14.63px;
}

@media (min-width: 1024px) {
  .img-map__card-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: 30px;
  }
}

.img-map__card-title--design {
  padding-left: 7px;
  padding-right: var(--emu-common-spacing-small);
}

.img-map__card-title--site {
  padding-left: var(--emu-common-spacing-small);
  padding-right: 9px;
}

@media (min-width: 1024px) {
  .img-map__card-title--site {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: 22px;
  }
}

.img-map__card-title--port {
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .img-map__card-title--port {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.img-map__card-desc p {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-semantic-font-weight-50);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .img-map__card-desc p {
    font-size: 17px;
    line-height: 23px;
  }
}

.img-map__card-desc--site p {
  text-align: center;
}

@media (min-width: 1024px) {
  .img-map__card-desc--site p {
    text-align: left;
    max-width: 208px;
    margin-right: 20px;
  }
}

.img-map__card--surface {
  padding: 13px 13px 13px 12px;
}

@media (min-width: 1024px) {
  .img-map__card--surface {
    padding: 25px 27px;
  }
}

.img-map__card--secure {
  padding: 13px 7px 14px;
}

@media (min-width: 1024px) {
  .img-map__card--secure {
    padding-top: 47px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 42px;
    padding-left: var(--emu-common-spacing-medium);
  }
}

.img-map__card--site {
  padding: 13px 13px 13px 12px;
}

@media (min-width: 1024px) {
  .img-map__card--site {
    padding: 30px 26px 17px;
  }
}

.img-map__card--design {
  padding: 13px 13px 21px 12px;
}

@media (min-width: 1024px) {
  .img-map__card--design {
    padding: 30px 26px;
  }
}

.img-map__card--line {
  padding: 14px 11px 16px 14px;
}

@media (min-width: 1024px) {
  .img-map__card--line {
    padding: 41px 16px;
  }
}

.img-map__card--port {
  padding: 12px 13px 22px 12px;
}

@media (min-width: 1024px) {
  .img-map__card--port {
    max-width: 211px;
    padding: 30px 18px 27px;
  }
}

.emu-modal.u-modal--hide-close button.main-close {
  display: none;
}

.emu-modal .modal-wrapper.is-open {
  padding-left: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
  justify-content: center;
  display: flex;
}

.emu-modal .modal-content {
  margin-left: var(--emu-common-spacing-none);
  margin-top: auto;
  margin-right: var(--emu-common-spacing-none);
  margin-bottom: auto;
}

.emu-modal .modal-content button.main-close {
  opacity: 1;
  background-image: url("resources/images/modal_close_x.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px;
  width: 9px;
  height: 9px;
  top: 17px;
  right: 24px;
}

@media (min-width: 1024px) {
  .emu-modal .modal-content button.main-close {
    top: 30px;
    right: 30px;
  }
}

.emu-modal .modal-content button.main-close span {
  display: none;
}

.ns-references {
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .ns-references {
    padding-bottom: 70px;
    padding-left: 85.5px;
    padding-right: 85.5px;
  }
}

.ns-references__text {
  font-size: 18px;
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 21.94px;
}

@media (min-width: 1024px) {
  .ns-references__text {
    font-size: 24px;
    line-height: 28px;
  }
}

.ns-references__text p {
  margin-bottom: 10px;
}

.ns-references__text p, .ns-references__text ol, .ns-references__text li {
  font-size: inherit;
  line-height: inherit;
  word-break: break-all;
}

@media (min-width: 1024px) {
  .ns-references__text p, .ns-references__text ol, .ns-references__text li {
    word-break: initial;
  }
}

.ns-references__text p a, .ns-references__text ol a, .ns-references__text li a {
  color: inherit;
  word-wrap: break-word;
  text-decoration: none;
}

.ns-references__text p .emphasis, .ns-references__text ol .emphasis, .ns-references__text li .emphasis {
  display: inline-block;
}

.ns-references__text ol {
  padding-left: var(--emu-common-spacing-large);
}

.teaser .emu-teaser-v3 {
  position: relative;
}

.teaser .emu-teaser-v3 .emu-teaser__image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 500px;
  min-height: 375px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__image img {
    min-height: none;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    max-width: none;
  }
}

.teaser .emu-teaser-v3 .teaser-container {
  position: relative;
}

.teaser .emu-teaser-v3 .content-container {
  position: static;
}

.teaser .emu-teaser-v3 .emu-teaser__disclaimer {
  padding: var(--emu-common-spacing-none);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  bottom: -7px;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__disclaimer {
    width: calc(88.5% + 40px);
    bottom: 20px;
    left: calc(8.25% - 20px);
  }
}

.teaser .emu-teaser-v3 .emu-teaser__disclaimer p {
  font-size: 12px;
}

.teaser .emu-teaser-v3 .emu-teaser__action-container {
  position: absolute;
  bottom: 57px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__action-container {
    margin-left: var(--emu-common-spacing-none);
    width: fit-content;
    bottom: 153px;
    left: 8.25%;
    transform: none;
  }
}

.teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 {
  font-family: var(--emu-semantic-font-families-body);
  padding: 5px 15px;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 {
    padding: 21px 33.5px;
  }
}

.teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 .cmp-button__text {
  line-height: 30px;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 .cmp-button__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    letter-spacing: -1px;
  }
}

.teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 svg {
  fill: var(--emu-common-colors-transparent);
  transition: all var(--emu-common-other-time-transition-short) linear;
  transform-origin: 0;
  width: 25px;
  height: auto;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2 svg {
    width: 50px;
    margin-left: 20px;
  }
}

.teaser .emu-teaser-v3 .emu-teaser__action-container .emu-button-v2.emu-button-v2.emu-button-v2:hover svg {
  margin-left: 30px;
  transform: scaleX(1.25);
}



/*# sourceMappingURL=main.css.map */
