.emu-modal {
  &.u-modal--hide-close {
    button.main-close {
      display: none;
    }
  }

  .modal-wrapper.is-open {
    display: flex;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
    justify-content: center;
  }

  .modal-content {
    margin-left: var(--emu-common-spacing-none);
    margin-top: auto;
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: auto;

    button.main-close {
      background-image: url('../../assets/images/modal_close_x.svg');
      background-size: 9px;
      // same as `background-size`
      width: 9px;
      height: 9px;
      background-position: center;
      background-repeat: no-repeat;
      top: 17px;
      right: 24px;
      opacity: 1;

      @include mq('large') {
        top: 30px;
        right: 30px;
      }

      span {
        display: none;
      }
    }
  }
}
