.teaser {
  .emu-teaser-v3 {
    position: relative;

    .emu-teaser__image {
      img {
        min-height: 375px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        object-fit: cover;
        object-position: center;

        @include mq('large') {
          min-height: none;
          max-width: none;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    .teaser-container {
      position: relative;
    }

    .content-container {
      position: static;
    }

    .emu-teaser__disclaimer {
      position: absolute;
      bottom: -7px;
      padding: var(--emu-common-spacing-none);
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      @include mq('large') {
        bottom: 20px;
        left: calc(8.25% - 20px);
        width: calc(100% - 11.5% + 40px); // adjusted value
      }

      p {
        font-size: 12px;
      }
    }

    .emu-teaser__action-container {
      position: absolute;
      left: 50%;
      bottom: 57px;
      transform: translateX(-50%);

      @include mq('large') {
        /*
          (119 / 1440) * 100

          119 - design's `left` value
          1440 - width of the design slide
        */
        left: 8.25%;
        margin-left: var(--emu-common-spacing-none);
        width: fit-content;
        bottom: 153px;
        transform: none;
      }

      .emu-button-v2.emu-button-v2.emu-button-v2 {
        padding-left: 15px;
        padding-top: 5px;
        padding-right: 15px;
        padding-bottom: 5px;
        font-family: var(--emu-semantic-font-families-body);

        @include mq('large') {
          padding-left: 33.5px;
          padding-top: 21px;
          padding-right: 33.5px;
          padding-bottom: 21px;
        }

        .cmp-button__text {
          line-height: 30px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            letter-spacing: -1px;
          }
        }

        svg {
          width: 25px;
          height: auto;
          fill: var(--emu-common-colors-transparent);
          transition: all var(--emu-common-other-time-transition-short) linear;
          transform-origin: left;

          @include mq('large') {
            width: 50px;
            margin-left: 20px;
          }
        }

        &:hover {
          svg {
            margin-left: 30px;
            transform: scaleX(1.25);
          }
        }
      }
    }
  }
}
