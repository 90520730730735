.emu-carousel {
  &[data-component='carousel-v2'] {
    background-color: var(--emu-semantic-colors-primary-light);

    &[data-nav='true'] {
      padding-bottom: var(--emu-common-spacing-none);

      .tns-nav {
        bottom: 20px;
        background-color: #ffffff1a;
        border-radius: 100px;

        @include mq('large') {
          border-radius: 120px;
          /*
            (119 / 1440) * 100

            119 - design's `left` value
            1440 - width of the design slide
          */
          left: 8.25%;
          transform: none;
          bottom: 80px;
        }
      }
    }
  }
}
