.checklist-modal {
  .modal-content {
    padding: var(--emu-common-spacing-none);
    max-height: 100%;
    overflow: auto;

    button.main-close.close {
      right: 18px;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('large') {
        right: 31px;
      }
    }

    > .container {
      padding-left: 13.5px;
      padding-top: 31px;
      padding-right: 13.5px;
      padding-bottom: 20px;
      overflow: auto;
      // 16px - magic number to avoid issues related to the `vh` unit
      max-height: min(368px, calc(100vh - 16px)); // value as per design

      @include mq('large') {
        max-height: min(622px, calc(100vh - 16px)); // value as per design
        padding-left: 75px;
        padding-top: 72px;
        padding-right: 75px;
        padding-bottom: 50px;
      }
    }
  }

  &__title {
    .emu-title__text {
      font-family: var(--emu-semantic-font-families-mono);
      font-weight: var(--emu-semantic-font-weight-600);
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 14px;
      margin-bottom: var(--emu-common-spacing-medium);
      padding-left: 55px;
      padding-right: 55px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: normal;
        color: var(--emu-semantic-colors-primary-dark);
      }
    }
  }

  &__text {
    font-family: var(--emu-semantic-font-families-mono);
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 14px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-xs);
      line-height: 1.2;
    }

    .emphasis {
      display: inline-block;
    }

    p {
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: 17px;
      }

      &:last-child {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    p,
    li {
      font: inherit;
    }

    ul {
      padding-left: 7.5px;
      list-style: none;
      margin-bottom: 12px;

      @include mq('large') {
        margin-bottom: 15px;
        padding-left: 13.5px;
      }
    }

    li {
      position: relative;
      margin-bottom: var(--emu-common-spacing-xs);

      @include mq('large') {
        margin-bottom: 9px;
      }

      // list bullet
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background-color: currentColor;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 3px;
        left: -8px;

        @include mq('large') {
          top: 5px;
          left: -13px;
        }
      }
    }

    a {
      &,
      &:hover,
      &:focus,
      &:active {
        color: var(--emu-semantic-colors-primary-dark);
        text-decoration: underline;
      }
    }
  }
}
