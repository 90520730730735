.emu-modal.connect-with-us-modal {
  .modal-content {
    max-width: 400px;

    > .container > .cmp-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p {
      font-weight: var(--emu-semantic-font-weight-500);
      font-size: 14px;
      line-height: 16.8px;
      margin-bottom: var(--emu-common-spacing-none);

      @include mq('large') {
        font-size: 20px;
        line-height: var(--emu-common-line-heights-wide-medium);
      }
    }
  }

  .connect-with-us-modal {
    &__container {
      > .container {
        width: 100%;
      }
    }

    &__img {
      margin-bottom: 20px;

      img {
        width: 30px;

        @include mq('large') {
          width: 48px;
        }
      }
    }

    &__text {
      margin-bottom: 20px;
    }

    &__links {
      display: flex;
      flex-direction: column;

      > .button {
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }

        .emu-button {
          max-width: none;
          width: 100%;
          font-size: 14px;
          font-family: var(--emu-semantic-font-families-body);
          padding-left: var(--emu-common-spacing-medium);
          padding-top: 14px;
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: 14px;
          font-weight: var(--emu-semantic-font-weight-600);
          justify-content: center;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-medium);
            padding-left: 28px;
            padding-top: 13px;
            padding-right: 28px;
            padding-bottom: 13px;
          }
        }
      }
    }
  }
}
