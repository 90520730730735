#portfolio-page {
  .portfolio {
    &__vision {
      padding-top: 30px;
      padding-right: 22px;
      padding-bottom: 27.2px;
      padding-left: 22px;

      @include mq('large') {
        padding-top: 80px;
        padding-right: 117px;
        padding-bottom: 40px;
        padding-left: 117px;
        display: flex;
        align-items: center;
      }

      .embed {
        min-height: 199px;

        @include mq('large') {
          min-width: 538px;
          margin-right: 60px;
          min-height: 326px;
        }
      }

      &-title {
        margin-top: 13px;
        margin-right: 28px;

        @include mq('large') {
          margin-top: -15px;
          max-width: 514px;
        }

        .cmp-title__text {
          @include mq('large') {
            line-height: 44px;
          }
        }
      }
    }

    &__safety {
      padding-top: 30px;
      padding-right: 20px;
      padding-bottom: 24px; //to match figma
      padding-left: 20px;
      display: flex;
      flex-direction: column;

      @include mq('large') {
        padding-top: 59px;
        padding-right: 114px;
        padding-bottom: 62px;
        padding-left: 120px;
        flex-direction: row;
        align-items: center;
      }

      > .container {
        flex-grow: 1;
      }

      &-content {
        @include mq('large') {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        > .title {
          @include mq('large') {
            margin-top: auto;
          }
        }

        > .text {
          @include mq('large') {
            margin-bottom: auto;
          }
        }
      }

      &-title {
        .cmp-title__text {
          line-height: 33px; //to match figma

          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-wide-xxxl);
          }
        }

        sup {
          font-size: 16px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
        }
      }

      &-desc {
        margin-top: 18px;

        @include mq('large') {
          margin-top: 23px;
        }

        p {
          color: var(--emu-semantic-colors-primary-dark);
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-common-font-weight-regular);
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          margin-bottom: 9px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
            margin-bottom: 11px;
          }
        }

        ul {
          padding-left: 15px;

          @include mq('large') {
            padding-left: 24px;
          }

          li {
            sup {
              @include mq('large') {
                font-size: 20px;
              }
            }

            &::marker {
              font-size: 14px;

              @include mq('large') {
                font-size: 20px;
              }
            }
          }
        }

        li {
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-200);
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }

          sup {
            font-size: 0.65em;
          }
        }
      }

      &-years-exp-img {
        img {
          margin-right: auto;
          margin-bottom: 30px;
          margin-left: auto;
          max-width: 131px; // design has 130, but 131 makes it look closer

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
            max-width: 195px;
          }
        }
      }
    }

    &__patients-results {
      position: relative;
      margin-right: 22px;
      margin-left: 22px;
      display: flex;
      justify-content: center;

      @include mq('large') {
        margin-right: 117px;
        margin-left: 117px;
      }

      &-img {
        margin-right: 10px;

        @include mq('large') {
          margin-right: 20px;
        }
      }

      > .text {
        position: absolute;
        right: 4px;
        bottom: 11px;

        @include mq('large') {
          right: 20px;
          bottom: 18px;
        }
      }
    }

    &__patients-results-text {
      p {
        font-family: var(--emu-semantic-font-families-body);
        font-weight: var(--emu-semantic-font-weight-325);
        font-size: 8px;
        line-height: 8px;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 14px;
        }
      }
    }

    &__distinct-profiles {
      padding-top: 30px;
      padding-right: 20px;
      padding-left: 20px;

      @include mq('large') {
        padding-top: 68px;
      }

      &-title {
        padding-left: 24px;
        padding-right: 24px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        .cmp-title__text {
          @include mq('large') {
            line-height: 46px;
          }
        }

        &--px-lg-mobile {
          @include mq('1024px', 'max-width') {
            padding-left: 42px;
            padding-right: 42px;
          }
        }

        sup {
          font-size: 0.725em;

          @include mq('large') {
            font-size: 0.6em;
          }
        }
      }

      &-info-wrapper {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row-reverse;
        }

        > .image {
          margin-left: auto;
          margin-right: auto;
          display: inline-block;

          @include mq('large') {
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
          }

          img {
            margin-bottom: 21px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
              margin-left: 115px;
              margin-right: 78px;
              padding-top: 10px;
              max-width: 190px;
            }
          }
        }
      }

      &-info-wrapper-desc {
        margin-top: 20px; //to match figma
        padding-left: 10px;
        padding-right: 10px;

        @include mq('large') {
          margin-bottom: 65px;
          padding-left: 44px;
          padding-right: 40px;
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 25px;
        }

        p {
          color: var(--emu-semantic-colors-tertiary-light);
          font-family: var(--emu-semantic-font-families-body);
          font-weight: var(--emu-common-font-weight-regular);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: var(--emu-semantic-line-heights-wide-xs);
          margin-bottom: 25px;

          @include mq('large') {
            font-size: 18px;
            line-height: 1.2;
          }

          sup {
            font-size: 0.65em;

            @include mq('large') {
              font-size: 0.7em;
            }
          }
        }
      }
    }

    &__flippable-cards {
      padding-left: 20px;
      padding-right: 20px;

      @include mq('large') {
        padding-top: 19px;
      }

      > .container,
      > .text {
        max-width: 1150px;
        margin-left: auto;
        margin-right: auto;
      }

      .flippable-cards {
        @include mq('large') {
          display: flex;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }

        > .container {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
            margin-right: 17px;
          }

          &:last-child {
            margin-bottom: var(--emu-common-spacing-none);

            @include mq('large') {
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }

        .flippable-card {
          border-width: var(--emu-common-border-width-thin);
          border-style: solid;
          border-color: var(--emu-semantic-colors-primary-500);
          background-color: var(--emu-common-colors-white);

          @include mq('large') {
            min-width: clamp(310px, 25.7vw, 370px);
          }

          &__centered-image,
          &__back-images img {
            transition-property: transform;
            transition-duration: 300ms;
            transition-timing-function: linear;
            z-index: -1;
          }

          &__centered-image {
            position: absolute;
            // above the images meant to be 'back images'
            z-index: var(--emu-common-other-z-index-base);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition-delay: 300ms;
            transform: rotateY(0);
            background-color: #b8dbd6;

            @include mq('large') {
              align-items: flex-start;
              padding-top: 120px;
            }

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }

          &__back-images {
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              background-color: var(--emu-semantic-colors-primary-light);
              display: inline-block;
              transition-delay: 0ms;
              transform: rotateY(270deg);
            }
          }

          &__flipper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // above all of the images
            z-index: calc(var(--emu-common-other-z-index-base) + 1);
            background-color: var(--emu-common-colors-transparent);
            color: var(--emu-semantic-colors-primary-dark);
            padding: var(--emu-common-spacing-none);

            svg {
              margin: var(--emu-common-spacing-none);
              position: absolute;
              top: 20px;
              right: 22px;

              @include mq('large') {
                min-width: 20px;
                min-height: 20px;
                right: 20px;
              }
            }
          }

          &__title {
            padding-left: var(--emu-common-spacing-small);
            padding-top: 18px;
            padding-right: var(--emu-common-spacing-small);
            padding-bottom: 20px;
            background-color: var(--emu-common-colors-white);

            @include mq('large') {
              padding-top: 29px;
              padding-bottom: 27px;
            }

            .cmp-title__text {
              color: var(--emu-semantic-colors-primary-dark);
              font-size: 26px;
              line-height: 30px;

              @include mq('large') {
                font-size: var(--emu-semantic-font-sizes-wide-xxl);
                line-height: var(--emu-semantic-line-heights-wide-xl);
              }

              sup {
                font-size: 1em;
                vertical-align: baseline;
              }
            }
          }

          &__flip-section {
            isolation: isolate;
            // relative positioning for
            // 1. flipper
            // 2. centered image
            // 3. back images
            position: relative;
            // necessary to make sure all cards have the same height
            min-height: 328px;

            @include mq('large') {
              min-height: 421px;
            }

            &.flipped {
              .flippable-card__centered-image {
                transform: rotateY(90deg);
                transition-duration: var(
                  --emu-common-other-time-transition-base
                );
                transition-delay: var(--emu-common-other-time-delay-none);
              }

              .flippable-card {
                &__back-images {
                  img {
                    transform: rotateY(360deg);
                    transition-delay: var(
                      --emu-common-other-time-transition-base
                    );
                  }
                }

                &__flipper {
                  color: var(--emu-semantic-colors-primary-500);
                }
              }
            }

            > .image {
              width: 100%;
              height: 100%;
            }

            > .button {
              // allow positioning the flipper relative to an ancestor
              position: static;
            }
          }
        }
      }

      &-disclaimer {
        margin-top: 27px;

        @include mq('large') {
          margin-top: 18px;
        }

        p {
          color: var(--emu-semantic-colors-tertiary-light);
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: 1.2;
          font-weight: var(--emu-semantic-font-weight-600);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
          }

          b {
            font-weight: var(--emu-semantic-font-weight-600);
          }
        }
      }

      &-extra-info {
        margin-top: 6px;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-xxs);
        }

        ul {
          list-style: none;
          padding-left: var(--emu-common-spacing-none);

          li {
            position: relative;
            color: var(--emu-semantic-colors-tertiary-light);
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            padding-left: var(--emu-common-spacing-xs);
            line-height: 1.2;
            font-weight: var(--emu-common-font-weight-light);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-xs);
              padding-left: 7px;
            }

            u {
              text-decoration: none;
              position: absolute;
              top: 0;
              left: 0;
            }

            .emphasis {
              position: absolute;
              left: 0;
              top: 1.5px;
              font-size: 0.75em;
              line-height: 1;

              @include mq('large') {
                top: 0.5px;
              }
            }

            b {
              font-weight: var(--emu-common-font-weight-regular);
            }
          }
        }
      }
    }

    &__easy-steps {
      position: relative;
      padding-top: 23px; //to match figma
      padding-right: 20px;
      padding-bottom: 30px;
      padding-left: 20px;
      background: linear-gradient(
        180deg,
        var(--emu-semantic-colors-primary-light) 0%,
        var(--emu-common-colors-white) 100%
      );

      @include mq('large') {
        padding-top: 90px; //to match figma
        padding-right: 117px;
        padding-bottom: 76px; //to match figma
        padding-left: 117px;
      }

      > .container {
        width: 100%;
      }

      &-title {
        padding-left: 48px; //to match figma
        padding-right: 48px; //to match figma

        &--secondary {
          padding-left: 18px; //to match figma
          padding-right: 18px; //to match figma

          .cmp-title__text {
            sup {
              font-size: 16px;
              top: 0;

              @include mq('large') {
                font-size: 24px;
              }
            }
          }
        }

        sup {
          font-size: 26px;
          top: 8px;

          @include mq('large') {
            font-size: 45px;
            top: 15px;
          }
        }
      }

      &-img {
        margin-top: 23px;

        @include mq('large') {
          margin-top: 25px;
        }

        img {
          margin-left: auto;
          margin-right: auto;
          max-width: min(100%, 984px);
        }
      }

      &-info-wrapper {
        margin-top: 22px;

        @include mq('large') {
          display: flex;
          padding-left: 27px;
          padding-right: 27px;
          margin-top: 30px;
        }

        > .container {
          @include mq('large') {
            width: 50%;
          }

          &:first-child {
            margin-bottom: 15px;

            @include mq('large') {
              margin-right: 20px;
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-info-box {
        height: 100%;
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-500);
        padding: 25px;
        padding-right: 25px;
        padding-bottom: 23px;
        padding-left: 25px;
        position: relative;

        @include mq('large') {
          max-width: none;
          padding-top: 41px;
          padding-right: var(--emu-common-spacing-xl);
          padding-bottom: 41px; //to match figma
          padding-left: var(--emu-common-spacing-xl);
        }

        &--secondary {
          @include mq('large') {
            padding-top: 24px;
            padding-right: 42px;
            padding-bottom: 37px;
            padding-left: 42px;
          }
        }

        .emu-image {
          a {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: var(--emu-common-other-z-index-layer);
            }
          }
        }

        img {
          margin-top: 5px;
          margin-right: auto;
          margin-bottom: 13px;
          margin-left: auto;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: 23px;
          }
        }
      }

      &-info-text {
        p {
          color: inherit;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }
        }
      }
    }

    &__tissue-expander {
      position: relative;

      &-background-gradient-box {
        width: 100%;
        background: linear-gradient(
          to bottom,
          var(--emu-semantic-colors-primary-light),
          #deeef2e5,
          #ffffff00
        );
        position: absolute;
        top: 402px;
        bottom: 0;
        z-index: var(--emu-common-other-z-index-behind);
      }

      > .teaser {
        width: 100%;
        position: absolute;
        top: 0;

        .content-container {
          display: none;
        }
      }

      .title,
      .text {
        position: relative;
      }

      &-logo {
        margin-left: var(--emu-common-spacing-small);
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          margin-left: var(--emu-common-spacing-none);
        }
      }

      &-teaser {
        .cmp-teaser__image {
          .cmp-image__image {
            max-width: none; // to override default
            min-height: auto; // to override default
            max-height: 621px; //to match figma
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: auto;

            @include mq('large') {
              max-height: unset; //to match figma
            }
          }
        }
      }

      &-title-wrapper {
        max-width: 218px; // from figma
        margin-top: 26px;
        margin-bottom: 42px;
        margin-left: var(--emu-common-spacing-small);
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          max-width: 652px; // from figma
          margin-top: 62px;
          margin-bottom: 84px;
          margin-left: var(--emu-common-spacing-none);
        }
      }

      &-title {
        .cmp-title__text {
          font-weight: var(--emu-common-font-weight-light);
          letter-spacing: 0.8px;

          @include mq('large') {
            letter-spacing: 1.4px;
          }
        }
      }

      &-content {
        padding-top: 30px;
        padding-right: 22px;
        padding-bottom: 25px;
        padding-left: 22px;

        @include mq('large') {
          padding-top: 105px;
          padding-right: 117px;
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: 117px;
        }
      }

      &-content-gradient-box {
        width: 100%;
        height: 45px; //to match figma
        background-image: linear-gradient(
          to bottom,
          var(--emu-common-colors-white),
          #ffffff00
        );
        position: absolute;
        top: 0;
        left: 0;

        @include mq('large') {
          height: 180px; //to match figma
        }

        &--secondary {
          background-image: linear-gradient(
            to top,
            var(--emu-common-colors-white),
            #ffffff00
          );
          top: auto;
          bottom: 0;

          @include mq('large') {
            height: 282px; // needed to match the deisgn
          }
        }
      }

      &-disclaimer {
        margin-bottom: 12px;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          max-width: 386px; // needed to match the design
          margin-bottom: var(--emu-common-spacing-none);
        }

        p {
          color: inherit;
          font-family: var(--emu-semantic-font-families-body);
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          font-weight: var(--emu-semantic-font-weight-325);
          line-height: 12px;
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: 18px;
          }
        }
      }

      &-info-wrapper {
        @include mq('large') {
          display: flex;
          align-items: stretch;
          margin-bottom: 40px;
          margin-left: var(--emu-common-spacing-xs);
        }

        > .card {
          @include mq('large') {
            flex-basis: 384px;
            margin-right: 20px;
          }

          > div,
          article {
            height: 100%;
          }
        }

        > .text {
          @include mq('large') {
            margin-top: auto;
          }
        }
      }

      &-card {
        box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 15px;
        background-color: var(--emu-common-colors-white);
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        img {
          margin-left: auto;
          margin-right: auto;
        }

        .emu-card__wrapper {
          box-shadow: 0px 1.37px 3.148px 0px rgba(0, 0, 0, 0.02);
          height: 100%;
          padding-top: 24px;
          padding-right: 24px;
          padding-bottom: 30px;
          padding-left: 24px;

          @include mq('large') {
            padding-top: 50px; //to match figma
            padding-bottom: var(--emu-common-spacing-none); //to match figma
          }
        }

        .emu-card__title-wrapper {
          display: none;
        }
      }

      &-card-desc {
        margin-bottom: 24px;

        @include mq('large') {
          margin-bottom: -15px; //to match figma
        }

        &--has-desk-max-width {
          @include mq('large') {
            max-width: 245px;
            margin: auto;
          }
        }

        //utility class
        &.pt-sm {
          @include mq('large') {
            padding-top: 10px; //to match figma
          }
        }

        p {
          color: inherit;
          margin-bottom: var(--emu-common-spacing-none);
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.5px; //to match figma
          }
        }
      }

      &-methodology {
        z-index: var(--emu-common-other-z-index-layer);

        p {
          margin-bottom: var(--emu-common-spacing-none);
          color: inherit;
          font-family: var(--emu-semantic-font-families-body);
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          font-weight: var(--emu-semantic-font-weight-350);
          line-height: var(--emu-semantic-line-heights-narrow-xs);
          text-indent: -3px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: 16.8px;
            text-indent: -4px;
          }
        }
      }
    }

    &__fill-system {
      padding-top: 22px; //to match figma
      padding-right: 21px;
      padding-bottom: 23px;
      padding-left: 21px;

      @include mq('large') {
        padding-top: 90px; //to match figma
        padding-right: 117px;
        padding-bottom: 18px; //to match figma
        padding-left: 117px;
      }

      &-title-wrapper {
        margin-bottom: 23px; //to match figma

        @include mq('large') {
          margin-bottom: 50px; //to match figma
        }
      }

      &-title {
        .cmp-title__text {
          letter-spacing: 0.5px; //to match figma
          font-weight: var(--emu-common-font-weight-light);

          @include mq('large') {
            letter-spacing: 2.2px; //to match figma
          }

          u {
            text-decoration: none;
          }
        }

        &--secondary {
          padding-left: 20px;
          padding-right: 20px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .cmp-title__text {
            @include mq('large') {
              letter-spacing: normal;
            }
          }
        }
      }

      &-caption {
        margin-top: 20px; //to match figma
        padding-left: 10px;
        padding-right: 10px;

        @include mq('large') {
          margin-top: 28px; //to match figma
          padding: var(--emu-common-spacing-none);
        }

        .cmp-title__text {
          font-weight: var(--emu-semantic-font-weight-500);
        }

        u {
          text-decoration: none;
        }
      }

      &-text {
        margin-top: 22px; //to match figma
        padding-left: 5px;

        @include mq('large') {
          margin-top: 40px;
        }

        ul {
          list-style: none;
          padding: var(--emu-common-spacing-none);
          margin: var(--emu-common-spacing-none);
        }

        li {
          color: inherit;
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          font-weight: var(--emu-common-font-weight-light);
          line-height: var(--emu-semantic-line-heights-narrow-xs);
          margin-bottom: var(--emu-common-spacing-none);
          text-indent: -4px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: var(--emu-semantic-line-heights-wide-xs);
          }
        }
      }

      &-title-wrapper {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .pms-accordion {
    margin-bottom: 10px;

    @include mq('large') {
      margin-bottom: 20px;
    }

    &__adverse-events-text,
    &__adverse-events,
    &__events-percent,
    &__additional-info {
      padding-left: 15px;
      padding-right: 15px;

      @include mq('large') {
        padding-left: 118px;
        padding-right: 118px;
      }
    }

    // override the above desktop styles
    &__adverse-events-text {
      @include mq('large') {
        padding-left: 40px;
        padding-right: 30px;
      }
    }

    &__adverse-events-text p {
      color: var(--emu-semantic-colors-tertiary-light);
      font-size: 14px;
      line-height: 16.8px;
      margin-bottom: 18px;
      padding-top: 17px;

      @include mq('large') {
        font-size: 18px;
        line-height: 21.6px;
        margin-bottom: 47px;
        padding-top: 26px;
      }
    }

    &__adverse-events {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      @include mq('large') {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 38px;
        // override common horizontal padding
        padding-left: 120px;
        padding-right: 120px;
      }

      > .container {
        margin-bottom: 15px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-small);
        }

        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__adverse-event {
      display: flex;
      align-items: center;

      > .image,
      > .text {
        flex-shrink: 0;
      }

      > .image {
        margin-right: 10px;

        @include mq('large') {
          margin-right: 20px;
        }
      }

      &-img img {
        width: 30px;
        height: 30px;
        object-fit: contain;

        @include mq('large') {
          width: auto;
          height: auto;
        }
      }

      &-text p {
        color: var(--emu-semantic-colors-tertiary-light);
        font-size: 14px;
        line-height: 16.8px;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 18px;
          line-height: 21.6px;
        }
      }
    }

    &__events-percent {
      background-color: #a6ddea1a;
      padding-bottom: 20px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-large);
        padding-bottom: 40px;
      }

      .cmp-image__image {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    &__events-percent-img-with-title {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      padding-left: var(--emu-common-spacing-xs);

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        flex-direction: row;
        align-items: center;
        margin-bottom: var(--emu-common-spacing-large);
      }

      > .image,
      > .text {
        flex-shrink: 0;
      }
    }

    &__events-percent-img {
      margin-bottom: 12px;

      @include mq('large') {
        margin-right: 48px;
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__events-percent-title {
      padding-right: var(--emu-common-spacing-small);

      &:not([class*='u-text--color']) {
        color: var(--emu-semantic-colors-tertiary-light);
      }
    }

    &__events-surveillance-data-info {
      ul {
        list-style: none;
        padding-left: var(--emu-common-spacing-xs);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          position: relative;
          left: -2px;
        }
      }

      li {
        position: relative;
        color: var(--emu-semantic-colors-tertiary-light);
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        font-weight: var(--emu-common-font-weight-light);
        line-height: 12px;
        padding-left: 4.5px;
        padding-right: var(--emu-common-spacing-medium);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 16.8px;
        }

        u {
          text-decoration: none;
          position: absolute;
          left: -1px;
          top: 0;
        }

        // the `list-style`-like symbol
        > .emphasis {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 8px;
          line-height: 1em;
        }
      }
    }

    &__additional-info {
      padding-top: var(--emu-common-spacing-medium);
      display: flex;
      flex-direction: column;

      @include mq('large') {
        background-color: #f5f5f5; // used only once
        padding-top: 40px;
        flex-direction: row-reverse;
        align-items: center;
      }

      > .container {
        &:first-child {
          flex-shrink: 0;
          margin-bottom: 14px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
            padding-right: 5px;
            padding-bottom: 28px;
          }
        }

        &:last-child {
          @include mq('large') {
            margin-right: 47px;
          }
        }
      }

      &-title {
        margin-bottom: 12px;
        padding-left: 4px;

        @include mq('large') {
          margin-bottom: 10px;
          padding-left: var(--emu-common-spacing-none);
        }

        .cmp-title__text {
          color: var(--emu-semantic-colors-primary-dark);
          font-weight: var(--emu-semantic-font-weight-regular);
        }
      }

      &-img {
        padding-left: var(--emu-common-spacing-xs);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
        }
      }

      &-text {
        padding-left: var(--emu-common-spacing-xs);
        padding-right: 20px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
        }

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          color: var(--emu-semantic-colors-primary-dark);
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-200);
          margin-bottom: 9.5px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }

          sup {
            font-size: 0.6em;
          }

          &:last-child {
            margin-bottom: 23px;

            @include mq('large') {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

  .advance-tech {
    padding-left: 22px;
    padding-top: 17px;
    padding-right: 22px;

    @include mq('large') {
      padding-top: 84px;
      padding-right: 117px;
      padding-left: 117px;
    }

    &__title {
      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-dark);
        font-family: var(--emu-semantic-font-families-collier-italic);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);

        @include mq('large') {
          font-size: 48px;
          line-height: 56px;
          font-weight: var(--emu-common-font-weight-regular);
        }
      }
    }

    &__images {
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: var(--emu-semantic-colors-primary-500);
      margin-top: 24px;

      @include mq('large') {
        margin-top: 70px;
      }

      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &__info {
      margin-top: 15px;
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: var(--emu-semantic-colors-primary-500);

      @include mq('large') {
        margin-top: 19px;
      }

      sup {
        font-size: 0.675em;
      }

      &-title {
        background-color: var(--emu-semantic-colors-primary-500);
        padding-left: 56px;
        padding-top: 18px;
        padding-right: 56px;
        padding-bottom: 18px;

        @include mq('large') {
          padding-top: 17px;
          padding-bottom: 17px;
        }

        .cmp-title__text {
          color: var(--emu-common-colors-white);
          font-family: var(--emu-semantic-font-families-body);
        }
      }

      &-description {
        padding-top: 22px;
        padding-right: 29px;
        padding-bottom: 25px;
        padding-left: 29px;

        @include mq('large') {
          padding-top: 61px;
          padding-right: 66px;
          padding-bottom: 61px;
          padding-left: 66px;
        }

        &--padding-lg {
          padding-top: 19px;
          padding-right: 40px;
          padding-bottom: 17px;
          padding-left: 40px;

          @include mq('large') {
            padding-top: 61px;
            padding-right: 140px;
            padding-bottom: 61px;
            padding-left: 140px;
          }
        }

        p {
          margin-bottom: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }

    &__info-bar {
      margin-top: 15px;

      @include mq('large') {
        margin-top: 20px;
        display: flex;
      }

      > .text:last-child {
        @include mq('large') {
          flex-grow: 1;
        }
      }

      &-title {
        background-color: var(--emu-semantic-colors-primary-dark);
        color: var(--emu-common-colors-white);
        padding-left: 24px;
        padding-top: 28px;
        padding-right: 24px;
        padding-bottom: 28px;

        @include mq('large') {
          min-height: 100%;
          display: flex;
          align-items: center;
          padding-left: var(--emu-common-spacing-large);
          padding-top: 18px;
          padding-right: var(--emu-common-spacing-large);
          padding-bottom: 18px;
        }

        sup {
          font-size: 1em;
          vertical-align: baseline;
        }

        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxxl);
          font-family: var(--emu-semantic-font-families-collier-italic);
          font-weight: var(--emu-semantic-font-weight-200);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: 1;
            white-space: nowrap;
          }
        }
      }

      &-description {
        background-color: var(--emu-semantic-colors-primary-500);
        padding-top: 15px;
        padding-left: 40px;
        padding-bottom: 15px;
        padding-right: 40px;

        @include mq('large') {
          min-height: 100%;
          display: flex;
          align-items: center;
          padding-left: 34px;
          padding-right: 34px;
        }

        sup {
          font-size: 0.7em;
        }

        p {
          color: var(--emu-common-colors-white);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__info-details {
      margin-top: 14px;
      color: var(--emu-semantic-colors-tertiary-light);
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: 1.2;

      @include mq('large') {
        margin-top: 38px;
        position: relative;
        left: -4.5px;
        font-size: var(--emu-semantic-font-sizes-wide-xs);
      }

      ul {
        list-style: none;
        padding-left: var(--emu-common-spacing-none);
      }

      li {
        font-weight: var(--emu-common-font-weight-light);
        position: relative;
        padding-left: 3px;

        @include mq('large') {
          padding-left: 4.5px;
        }

        .emphasis {
          font-size: 0.65em;
          line-height: 1;
          position: absolute;
          top: 1.5px;
          left: 0;
        }
      }
    }
  }

  .profile-img-maps {
    /*
      30px - actual value
      8px  - `margin-bottom` of the image map
    */
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 9px;
    padding-right: 9px;

    @include mq('large') {
      margin-bottom: 70px;
    }

    > .container {
      @include mq('large') {
        margin-right: 20px;
      }

      // last child, if it is single
      &:last-child {
        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
        }

        .profile-img-map {
          margin-top: -8px;
          margin-bottom: 6px;

          // from 516 3 items starts to show side by side
          @include mq('516px') {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }
    }
  }

  .profile-img-map {
    min-width: 147px;
    min-height: 160px;
    flex-shrink: 0;
    position: relative;
    margin-bottom: var(--emu-common-spacing-small);

    .emu-mapping-marker {
      color: var(--emu-semantic-colors-primary-500);
      width: auto;
      height: auto;

      span:before,
      span:after {
        display: none;
      }

      svg {
        margin: var(--emu-common-spacing-none);
      }

      &[aria-pressed='true'] {
        svg {
          color: var(--emu-common-colors-white);

          circle {
            fill: var(--emu-semantic-colors-primary-500);
            stroke: var(--emu-semantic-colors-primary-500);
          }
        }
      }
    }

    &__content {
      position: absolute;
      display: none;
      background-color: var(--emu-semantic-colors-primary-500);
      padding-left: var(--emu-common-spacing-xs);
      padding-right: var(--emu-common-spacing-xs);
      min-width: 100px;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('large') {
        min-width: 182px;
      }

      p {
        margin-top: 10px;
        margin-bottom: 10px;
        color: var(--emu-common-colors-white);
        font-weight: var(--emu-semantic-font-weight-500);
        line-height: 1.1;
        font-size: 14px;

        @include mq('large') {
          margin-top: 22px;
          margin-bottom: 22px;
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: 30px;
          letter-spacing: -0.035em;
        }

        .emphasis {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 1;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-xs);
            font-size: 18px;
            letter-spacing: normal;
            font-weight: var(--emu-common-font-weight-regular);
          }
        }
      }

      &--active {
        display: revert;
        /*
          19px - height of the arrow
          -4px - delta to match the design
        */
        transform: translate(
          calc(-50% + var(--marker-width, 0) / 2),
          calc(19px + var(--marker-height, 0) - 4px)
        );
      }

      &:before {
        content: '';
        display: inline-block;
        width: 25px;
        height: 19px;
        clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
        background-color: inherit;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% + 4px));

        @include mq('large') {
          width: 21px;
          transform: translate(-50%, calc(-100% + 1px));
        }
      }
    }
  }

  .warranty {
    padding-left: 20px;
    padding-right: 20px;

    @include mq('large') {
      padding-top: 87px;
      padding-left: 117px;
      padding-right: 117px;
    }

    &__title {
      margin-bottom: 29px;

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }

      .cmp-title__text {
        > p {
          font-family: var(--emu-semantic-font-families-heading);
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxxl);
          font-weight: var(--emu-common-font-weight-regular);
          margin-bottom: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-primary-500);
          padding-left: 26px;
          padding-right: 26px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            line-height: var(--emu-semantic-line-heights-wide-xxxl);
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          &:first-child {
            color: var(--emu-semantic-colors-primary-dark);
          }

          &:last-child {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          sup {
            font-size: 0.57em;

            @include mq('large') {
              font-size: 0.59em;
            }
          }
        }
      }
    }

    &__description {
      padding-left: 12.5px;
      padding-right: 12.5px;
      margin-bottom: 25px;

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        margin-bottom: 54px;
      }

      > p {
        color: var(--emu-semantic-colors-primary-500);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: 18px;
        line-height: 1.2;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
        }

        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);
        }

        sup {
          vertical-align: baseline;
          font-size: 1em;
        }
      }
    }

    &__accordion {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      margin-bottom: 19px;

      @include mq('large') {
        margin-bottom: 20px;
      }

      .aaaem-accordion__header {
        padding-left: 18px;
        padding-right: 16px;

        @include mq('large') {
          padding-left: 39px;
          padding-right: 29px;
          padding-right: 22px;
        }
      }

      .aaaem-accordion__title {
        letter-spacing: -0.6px;
      }

      &-content {
        padding-left: 15px;
        padding-right: 19px;

        @include mq('large') {
          padding-left: 39px;
          padding-right: 29px;
        }
      }

      &-content {
        @include mq('large') {
          // override the above padding value
          padding-right: 42px;
        }
      }

      &-content > *:first-child {
        padding-top: 20px;
        padding-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          padding-top: 40px;
          padding-bottom: 34px;
        }
      }

      &-content-intro,
      &-plans {
        > p {
          margin-bottom: var(--emu-common-spacing-none);
        }

        p,
        li {
          font-size: 14px;
          line-height: 1.2;
          color: var(--emu-semantic-colors-tertiary-light);

          @include mq('large') {
            font-size: 18px;
            letter-spacing: -0.54px;
          }
        }

        b {
          color: var(--emu-semantic-colors-primary-dark);
        }
      }

      &-content-intro {
        > p {
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-bottom: 22px;
          }

          &:last-child {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        b {
          sup {
            font-size: 80%;
          }
        }

        sup {
          font-size: 70%;
        }
      }

      &-plans {
        margin-bottom: 20px;

        @include mq('large') {
          margin-bottom: 33px;
        }

        > ul {
          list-style-type: none;
          padding-left: var(--emu-common-spacing-none);

          > li {
            // adjusting the gap between list-style & text is difficult
            // use `:before` to add a fake list-style & adjust the gap
            position: relative;
            padding-left: 9px;
            margin-bottom: 6px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }

            &:last-child {
              margin-bottom: var(--emu-common-spacing-none);
            }

            &:before {
              content: '';
              background-color: var(--emu-common-colors-black);
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              position: absolute;
              top: 0.45em;
              left: 2px;
            }
          }
        }

        sup {
          font-size: 70%;
        }

        // the title-like text
        > p > b > .emphasis {
          display: inline-block;
          margin-top: var(--emu-common-spacing-small);
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-top: 23px;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-full-details {
        background-color: var(--emu-semantic-colors-primary-dark);
        padding-left: 30px;
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 20px;
        margin-bottom: 18px;
        display: flex;
        flex-direction: column;

        @include mq('large') {
          padding-left: 40px;
          padding-right: 21px;
          margin-bottom: 15px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        > * {
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
          }
        }

        .cmp-title__text {
          color: var(--emu-common-colors-white);
          font-family: var(--emu-semantic-font-families-body);
          font-size: 22px;
          font-weight: var(--emu-semantic-font-weight-600);
          line-height: 1.2;
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-small);
            font-size: 18px;
            letter-spacing: -0.54px;
          }

          sup {
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('large') {
              font-size: 65%;
            }
          }
        }

        > .button {
          .emu-button {
            padding-left: 43px;
            padding-right: 43px;

            @include mq('large') {
              padding-left: 42px;
              padding-right: 42px;
            }

            .cmp-button__text {
              font-family: var(--emu-semantic-font-families-body);
              font-size: 18px;
              line-height: 14px;
              font-weight: var(--emu-semantic-font-weight-500);
            }
          }
        }
      }

      &-extra-info {
        > p {
          margin-bottom: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-tertiary-light);
          font-weight: var(--emu-common-font-weight-light);
          font-size: 10px;
          line-height: 1.2;
          position: relative;
          padding-left: 7px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
          }

          // the first symbol
          > .emphasis {
            position: absolute;
            top: 0;
            left: 1px;
            font-size: 0.6em;
          }
        }
      }
    }

    &__ad {
      position: relative;
      margin-left: 20px;
      margin-right: 20px;

      @include mq('large') {
        margin-left: 117px;
        margin-right: 117px;
      }

      &-img {
        img {
          width: 100%;
        }

        &-info {
          position: absolute;
          right: 14px;
          bottom: 8px;

          @include mq('large') {
            right: 20px;
            bottom: 10px;
          }

          > p {
            margin-bottom: var(--emu-common-spacing-none);
            color: var(--emu-common-colors-white);
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            line-height: 1.2;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-xs);
              line-height: 22px;
            }

            > br {
              @include mq('large') {
                display: none;
              }
            }
          }

          b {
            font-weight: var(--emu-semantic-font-weight-600);

            @include mq('large') {
              font-weight: var(--emu-semantic-font-weight-500);
            }
          }
        }
      }
    }

    &__tag {
      position: absolute;
      top: 0;
      right: 14px;

      @include mq('large') {
        right: 26px;
      }

      img {
        max-width: 163px;
      }
    }
  }

  .ns-patch {
    padding-left: 22px;
    padding-top: 52px;
    padding-right: 22px;
    background: linear-gradient(
      0deg,
      var(--emu-semantic-colors-primary-light) 0%,
      var(--emu-common-colors-white) 100%
    );

    @include mq('large') {
      padding-left: var(--emu-common-spacing-none);
      padding-top: 90px;
      padding-right: var(--emu-common-spacing-none);
    }

    &__title {
      padding-left: 47px;
      padding-right: 47px;
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-left: 149px;
        padding-right: 149px;
        margin-bottom: 40px;
      }

      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-dark);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: 57.6px;
        }

        sup {
          @include mq('large') {
            font-size: 55%;
          }
        }
      }
    }

    &__img-with-description {
      @include mq('large') {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-left: 215px;
        padding-right: 92px;
      }

      > .container {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          flex-shrink: 0;
          width: auto;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }

      > .text {
        @include mq('large') {
          margin-right: 41px;
        }
      }

      .profile-img-map__content {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        @include mq('large') {
          padding-left: 30px;
          padding-top: 21px;
          padding-right: 30px;
          padding-bottom: 21px;
          width: max-content;
        }

        &--active {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include mq('large') {
            flex-direction: row-reverse;
            align-items: center;
            /* uses: image-map-v1 */
            transform: translate(
              calc(-100% + 105px),
              calc(19px + var(--marker-height, 0))
            );
          }

          &::before {
            @include mq('large') {
              left: auto;
              right: 90px;
              transform: translate(50%, calc(1px - 100%));
            }
          }
        }

        img {
          border-radius: 10px;
        }

        > .text,
        > .image {
          flex-shrink: 0;
        }

        > .text {
          max-width: 90px;
          padding-left: var(--emu-common-spacing-small);
          padding-right: var(--emu-common-spacing-small);
          margin-top: 20px;

          @include mq('large') {
            max-width: 228px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: var(--emu-common-spacing-none);
          }

          p {
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            line-height: 1.2;
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            text-align: center;

            @include mq('large') {
              font-size: 18px;
              letter-spacing: -1px;
              text-align: left;
            }

            sup {
              font-size: 75%;
            }
          }
        }
      }
    }

    &__img {
      margin-bottom: 37px;

      @include mq('large') {
        margin-bottom: 48px;
      }
    }

    &__description {
      margin-bottom: 36.86px;
      padding-left: 21px;
      padding-right: 14px;

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      p {
        color: var(--emu-semantic-colors-primary-dark);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: 36.5px;
          letter-spacing: -1px;
        }

        sup {
          font-size: 60%;
        }
      }
    }

    &__carousel {
      &.emu-carousel {
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-500);
        background-color: var(--emu-common-colors-white);

        @include mq('large') {
          margin-left: 148px;
          margin-right: 148px;
        }
      }

      .emu-carousel__item-container {
        padding-left: 40px;
        padding-top: 49px;
        padding-bottom: 112px;
        background-color: var(--emu-common-colors-white);
        overflow: hidden;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-top: 110px;
          padding-bottom: 98px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > .title,
        > .image {
          @include mq('large') {
            flex-shrink: 0;
            flex-basis: 0;
            flex-grow: 1;
          }
        }

        > .title {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-small);
          }
        }
      }

      &[data-component='carousel-v2'][data-nav='true'] .tns-nav {
        background: rgba(0, 175, 208, 0.1);
        backdrop-filter: blur(19.5px);
        border-radius: 120px;
        padding-left: 10px;
        padding-top: 7px;
        padding-right: 10px;
        padding-bottom: 7px;
        column-gap: var(--emu-common-spacing-none);
        bottom: 28px;

        @include mq('large') {
          left: 50%;
          transform: translateX(-50%);
          padding-left: 20px;
          padding-right: 20px;
        }

        button {
          color: var(--emu-semantic-colors-primary-500);
          background-color: currentColor;
          border-color: currentColor;
          opacity: 0.4;
          margin-right: 6px;

          @include mq('large') {
            margin-right: 10px;
          }

          &:last-child {
            margin-right: var(--emu-common-spacing-none);
          }

          &.tns-nav-active {
            opacity: 1;
          }
        }
      }

      &-slide-title {
        margin-bottom: 49px;
        padding-left: 3px;
        padding-right: 43px;
        min-height: 2lh;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          min-height: auto;
          margin-right: var(--emu-common-spacing-large);
          padding-left: 121px;
          margin-top: -10px;
        }

        .cmp-title__text {
          color: var(--emu-semantic-colors-primary-dark);
          letter-spacing: -1px;
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            text-align: left;
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: 36px;
          }
        }
      }

      &-slide-img {
        height: 115px;
        position: relative;
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          height: 170px;
          max-width: none;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 132px;
          height: 100%;
          background: linear-gradient(
            270deg,
            #f6fcfd 45.71%,
            rgba(246, 252, 253, 0) 100%
          );

          @include mq('large') {
            width: 335px;
            right: -9px;
            background: linear-gradient(
              270deg,
              var(--emu-common-colors-white) 34%,
              rgba(255, 255, 255, 0) 100%
            );
          }
        }

        img {
          width: auto;
          height: 100%;
          max-width: none;
        }
      }
    }
  }
}

// author mode styles
.profile-img-map__content,
.flippable-card *,
.img-map__main,
.img-map__main > .container {
  @include aem-editor-view {
    position: static !important;
    opacity: 1 !important;
    display: block !important;
    transform: none !important;
    width: 100% !important;
  }
}
