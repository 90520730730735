#main-header-wrapper {
  .main-header {
    .emu-navigation__content-wrapper {
      position: static; // reset the default
    }

    &__primary-links {
      padding-left: 20px;
      padding-top: var(--emu-common-spacing-xxs);
      padding-right: 20px;
      padding-bottom: 7px;
      background-color: var(--emu-common-colors-white);

      @include mq('large') {
        padding: var(--emu-common-spacing-none);
      }

      > ul {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 1px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
              margin-right: 5px;
            }

            @include mq('x-large') {
              margin-right: var(--emu-common-spacing-none);
            }

            @include mq('xx-large') {
              margin-right: 10px;
            }
          }
        }
      }

      a {
        padding-top: 15px;
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: 19px;
        padding-left: var(--emu-common-spacing-small);
        border-width: var(--emu-common-border-width-none);
        font-size: 16px;
        line-height: 26px;
        font-weight: var(--emu-semantic-font-weight-600);
        -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

        @include mq('large') {
          font-size: 12px;
          line-height: 1.1;
          padding-left: 10px;
          padding-top: 7px;
          padding-right: 10px;
          padding-bottom: 7px;
        }

        @include mq('x-large') {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 26px;
        }

        @include mq('xx-large') {
          padding-left: 15.85px;
          padding-right: 15.85px;
        }

        &,
        &:hover,
        &:focus,
        &:active,
        &:visited {
          background-color: inherit;
          color: var(--emu-semantic-colors-primary-dark);
        }

        &.scroll-section-active {
          color: var(--emu-semantic-colors-primary-500);
        }
      }
    }

    &__revealable-resource-links-container {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      background-color: var(--emu-common-colors-white);

      @include mq('large') {
        background-color: var(--emu-common-colors-transparent);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        position: relative;
      }
    }

    &__external-revealable-links {
      .button {
        @include mq('large') {
          line-height: 17px;
          top: -2px; // needed to match the design more appropriately
        }
      }
    }

    // all of the visibility toggle buttons
    // share some styles to maintain consistency
    &__resource-links-visibility-toggler,
    &__dfu-links-visibility-toggler,
    &__external-links-visibility-toggler {
      align-items: center;
      font-family: var(--emu-semantic-font-families-body);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      border-width: var(--emu-common-border-width-none);

      .cmp-button__text {
        margin-right: 10px;
      }

      > svg {
        // sizing values don't match the design
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        transition: transform;
        transition-duration: 300ms;
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }

      &.js-toggle-on {
        > svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__resource-links-visibility-toggler {
      padding-top: 12px;
      padding-bottom: var(--emu-common-spacing-small);
      padding-right: 6px;
      font-weight: var(--emu-semantic-font-weight-600);
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      justify-content: center;
      max-width: none;

      @include mq('large') {
        padding-top: 10px;
        padding-right: 23px;
        padding-bottom: 10px;
        padding-left: 23px;
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        width: auto;
        margin-top: var(--emu-common-spacing-xxs);
      }

      .cmp-button__text {
        margin-right: 15px;
        display: inline-block;

        @include mq('large') {
          margin-right: 9px;
        }
      }

      svg {
        margin-top: 6px;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-xs);
          width: 11px;
        }
      }
    }

    &__resource-links,
    #main-header__dfu-links,
    #main-header__external-links-container {
      display: none;

      &.js-toggle-on {
        display: revert;
      }
    }

    &__resource-links {
      @include mq('large') {
        position: absolute;
        top: 40px;
        right: 0;
        width: 270px;
        // -276px = -96px -160px -20px
        // -96px  - start point of this container
        max-height: calc(
          100vh - 96px - var(--isiBannerHeight, 0) -
            var(--isi-content-height, 0)
        );
        overflow-y: auto;
      }
    }

    &__resource-primary-links {
      background-color: var(--emu-common-colors-transparent);

      > ul {
        display: flex;
        flex-direction: column;
        color: var(--emu-common-colors-white);

        @include mq('large') {
          color: var(--emu-common-colors-transparent);
        }
      }

      li {
        @include mq('large') {
          margin-bottom: 1px;
        }
      }

      a {
        border-width: 0.5px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          font-size: 12px;
          line-height: 18px;
        }

        &,
        &:hover,
        &:focus,
        &:visited,
        &:active {
          background-color: var(--emu-common-colors-white);
          border-color: var(--emu-semantic-colors-primary-light);
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }

    // visually looks same as `.main-header__resource-primary-links a`
    &__dfu-links-visibility-toggler {
      background-color: var(--emu-common-colors-transparent);
      color: var(--emu-semantic-colors-primary-dark);
      border-width: 0.5px;
      border-color: var(--emu-semantic-colors-primary-light);
      width: 100%;
      justify-content: center;
      font-weight: var(--emu-semantic-font-weight-500);
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      max-width: none;

      @include mq('large') {
        background-color: var(--emu-common-colors-white);
        font-size: 12px;
        line-height: 19px;
        margin-top: 1px;
      }

      &.js-toggle-on {
        background-color: var(--emu-semantic-colors-primary-100);
      }
    }

    &__dfu-links {
      background-color: var(--emu-common-colors-transparent);

      > ul {
        padding-top: 1px;
        background-color: var(--emu-common-colors-white);
        display: flex;
        flex-direction: column;

        @include mq('large') {
          background-color: var(--emu-common-colors-transparent);
        }
      }

      li {
        margin-bottom: 1px;
      }

      a {
        border-width: var(--emu-common-border-width-none);
        padding-left: 34px;
        padding-top: 10px;
        padding-right: 34px;
        padding-bottom: 10px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        line-height: 20px;
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          white-space: wrap;
          padding-left: 18px;
          padding-top: 17px;
          padding-right: 18px;
          padding-bottom: 17px;
          font-size: 12px;
          line-height: 16px;
        }

        &,
        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: var(--emu-semantic-colors-primary-dark);
        }
      }

      a,
      a:active {
        background-color: var(--emu-semantic-colors-primary-light);
      }

      a:hover {
        background-color: var(--emu-semantic-colors-primary-100);
      }
    }

    &__non-resource-links {
      background-color: var(--emu-semantic-colors-primary-light);
      padding-left: 40px;
      padding-top: 6px;
      padding-right: 40px;
      padding-bottom: 38px;

      @include mq('large') {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding-left: var(--emu-common-spacing-large);
        padding-top: 10px;
        padding-right: 30px;
        padding-bottom: 6px;
      }

      &-inner {
        @include mq('large') {
          display: flex;
          align-items: center;
        }

        > div:last-child {
          @include mq('large') {
            margin-left: auto;
          }
        }
      }
    }

    &__external-links-visibility-toggler {
      background-color: var(--emu-common-colors-transparent);
      justify-content: space-between;
      color: var(--emu-semantic-colors-primary-dark);
      font-size: 18px;
      line-height: 24px;
      font-weight: var(--emu-common-font-weight-regular);
      font-family: var(--emu-semantic-font-families-body);
      padding-top: 13px;
      padding-bottom: 13px;
      width: 100%;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

      @include mq('large') {
        white-space: nowrap;
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: 17px;
        margin-right: 52px;
        width: auto;
        justify-content: flex-end;
      }

      .cmp-button__text {
        margin-right: var(--emu-common-spacing-xs);
      }

      > svg {
        margin-right: -4px;
        height: auto;
        width: var(--emu-common-sizing-small);
        margin-top: 10px;

        @include mq('large') {
          width: 12px;
          margin-top: 7px;
        }
      }
    }

    #main-header__external-links-container {
      background-color: var(--emu-common-colors-transparent);
      margin-top: 3px;
      margin-bottom: 18px;

      @include mq('large') {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background-color: var(--emu-semantic-colors-primary-light);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
      }

      > .button:last-child {
        // allow positioning the containing `button` element relative to the parent
        position: static;
      }
    }

    &__external-links {
      // keep `#main-header__external-links-container` element's bg color in sync
      background-color: var(--emu-common-colors-transparent);

      @include mq('large') {
        background-color: var(--emu-semantic-colors-primary-light);
        padding-top: var(--emu-common-spacing-xs);
      }

      > ul {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        }
      }

      li {
        @include mq('large') {
          padding-left: 12px;
          padding-right: 12px;
          margin-top: var(--emu-common-spacing-small);
          margin-bottom: var(--emu-common-spacing-small);
          border-right-width: var(--emu-common-border-width-thin);
          border-right-style: solid;
          border-right-color: var(--emu-semantic-colors-primary-dark);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:not(:last-child) {
          margin-bottom: 18px;

          @include mq('large') {
            margin-bottom: 12px;
          }
        }

        &:nth-child(4n) {
          @include mq('large') {
            border-right-width: var(--emu-common-border-width-none);
          }
        }
      }

      a {
        border-width: var(--emu-common-border-width-none);
        font-size: 18px;
        line-height: 1em;
        padding-left: 20px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          text-align: center;
          width: 100%;
          margin-top: auto;
          margin-bottom: auto;
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 16.8px;
        }

        &,
        &:hover,
        &:focus,
        &:active,
        &:visited {
          background-color: var(--emu-common-colors-transparent);
          color: var(--emu-semantic-colors-primary-dark);
        }

        i {
          font-style: normal;
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 14px;
          display: block;
          margin-top: 1px;

          @include mq('large') {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }

    &__close-external-links-btn {
      background-color: var(--emu-common-colors-transparent);
      padding: var(--emu-common-spacing-none);
      border-width: var(--emu-common-border-width-none);
      position: absolute;
      top: 12px;
      right: 32px;
      // place it above the external links
      z-index: var(--emu-common-other-z-index-header);
      display: none;

      @include mq('large') {
        display: revert;
      }

      img {
        width: 12px;
      }
    }

    &__internal-info-or-link {
      padding-bottom: 12px;
      border-bottom-width: var(--emu-common-border-width-thin);
      border-bottom-style: solid;
      border-bottom-color: var(--emu-semantic-colors-primary-dark);

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
        border-bottom-width: var(--emu-common-border-width-none);
      }

      > ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-left: var(--emu-common-spacing-none);

        @include mq('large') {
          flex-direction: row;
          margin: var(--emu-common-spacing-none);
        }

        li {
          &:first-child {
            margin-bottom: 15px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
              margin-right: 60px;
            }
          }
        }

        li {
          color: var(--emu-semantic-colors-primary-dark);
          font-size: 18px;
          line-height: 24px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: 17px;
          }
        }

        a,
        a:hover,
        a:active,
        a:visited {
          color: inherit;
          font: inherit;
          text-decoration: none;
        }
      }
    }

    &__social-links {
      display: flex;
      align-items: center;
      margin-top: 20px;
      min-width: max-content;

      @include mq('large') {
        // to center the social links vertically
        min-height: 100%;
        margin-top: var(--emu-common-spacing-none);
        position: relative;
        top: -2px;
      }

      > div {
        flex-shrink: 0;

        &:not(:first-child) {
          margin-left: 18px;
        }
      }

      .button {
        font-size: 0;
        line-height: 0;
      }

      button {
        background-color: var(--emu-common-colors-transparent);
        padding: var(--emu-common-spacing-none);
        border-width: var(--emu-common-border-width-none);

        svg {
          margin: var(--emu-common-spacing-none);
        }
      }
    }
  }
}
