// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// utility classes for text color
.u-text-color--white {
  color: var(--emu-common-colors-white);
}

[class*='u-text-color'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    color: inherit;
  }
}

// content related util classes. Used to center buttons mostly.
.u-content--centered {
  text-align: center;
}

// background color related classes
.u-bg--primary-light {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg--primary-dark {
  background-color: var(--emu-semantic-colors-primary-dark);
}

.u-bg--primary-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.u-bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--primary-200 {
  background-color: var(--emu-semantic-colors-primary-200);
}

.u-bg--primary-300 {
  background-color: var(--emu-semantic-colors-primary-300);
}

.u-bg--primary-400 {
  background-color: var(--emu-semantic-colors-primary-400);
}

.u-bg--primary-500 {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg--primary-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--primary-700 {
  background-color: var(--emu-semantic-colors-primary-700);
}

.u-bg--secondary-light {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg--secondary-dark {
  background-color: var(--emu-semantic-colors-secondary-dark);
}

.u-bg--secondary-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-bg--tertiary-light {
  background-color: var(--emu-semantic-colors-tertiary-light);
}

.u-bg--brandYellow-50 {
  background-color: var(--emu-semantic-colors-brandYellow-50);
}

// text color related classes
[data-component='title'],
[data-component='text'] {
  &.u-text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.u-text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.u-text--color-primary-light {
    color: var(--emu-semantic-colors-primary-light);
  }

  &.u-text--color-primary-dark {
    color: var(--emu-semantic-colors-primary-dark);
  }

  &.u-text--color-primary-50 {
    color: var(--emu-semantic-colors-primary-50);
  }

  &.u-text--color-primary-100 {
    color: var(--emu-semantic-colors-primary-100);
  }

  &.u-text--color-primary-200 {
    color: var(--emu-semantic-colors-primary-200);
  }

  &.u-text--color-primary-300 {
    color: var(--emu-semantic-colors-primary-300);
  }

  &.u-text--color-primary-400 {
    color: var(--emu-semantic-colors-primary-400);
  }

  &.u-text--color-primary-500 {
    color: var(--emu-semantic-colors-primary-500);
  }

  &.u-text--color-primary-600 {
    color: var(--emu-semantic-colors-primary-600);
  }

  &.u-text--color-primary-700 {
    color: var(--emu-semantic-colors-primary-700);
  }

  &.u-text--color-secondary-light {
    color: var(--emu-semantic-colors-secondary-light);
  }

  &.u-text--color-secondary-dark {
    color: var(--emu-semantic-colors-secondary-dark);
  }

  &.u-text--color-secondary-50 {
    color: var(--emu-semantic-colors-secondary-50);
  }

  &.u-text--color-tertiary-light {
    color: var(--emu-semantic-colors-tertiary-light);
  }

  &.u-text--color-brandYellow-50 {
    color: var(--emu-semantic-colors-brandYellow-50);
  }
}

.u-container-default {
  padding-left: 20px;
  padding-right: 20px;

  @include mq('large') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

// centers the contents present in an element
// used for centering button elements or any other elements that has max-width
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;

  // needed to increase specificity
  &.emu-button-v2.emu-button__primary-outline.emu-button {
    display: block;
  }
}

.u-hide {
  // making sure that this is still visible in author mode
  &:not(.cq-Editable-dom--container &) {
    display: none !important; // important needed to override any default styles
  }
}

// scroll to sections present in the page
.u-scroll-target-section {
  scroll-margin: var(--header-height, 114px);
}

.u-max-container {
  @include max-container;
}

.u-h1-typography {
  .cmp-title__text,
  h2,
  h3,
  h4,
  h5 {
    font: var(--emu-component-ingredients-title-h1-typography-narrow);

    @include mq('large') {
      font: var(--emu-component-ingredients-title-h1-typography-wide);
    }
  }
}

.u-hide-br-in-desktop {
  br {
    display: block;

    @include mq('large') {
      display: none;
    }
  }
}

.u-hide-br-in-mobile {
  br {
    display: none;

    @include mq('large') {
      display: block;
    }
  }
}
