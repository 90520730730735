.aaaem-accordion {
  border: var(--emu-common-border-width-thin) solid
    var(--emu-semantic-colors-none);
  transition: all var(--emu-common-other-time-transition-short) linear
    var(--emu-common-other-time-duration-instant);

  &.aaaem-accordion--has-expanded-item {
    border-color: var(--emu-semantic-colors-primary-500);
    transition: none;
  }

  .image {
    line-height: 0;
  }

  &__header {
    background-color: var(--emu-semantic-colors-primary-dark);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: var(--emu-semantic-colors-primary-500);
      opacity: 0;
      transition: all var(--emu-common-other-time-transition-short) linear;

      @include mq('large') {
        width: calc(100% - 70px);
        left: 40px;
      }
    }

    &.-ba-is-active {
      background-color: var(--emu-common-colors-white);

      &::after {
        opacity: 1;
        bottom: -3px;
      }

      .aaaem-accordion__title {
        color: var(--emu-semantic-colors-primary-dark);
      }

      .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
        border-color: var(
          --emu-component-lists-accordion-item-header-icon-color-fill-open-light
        );
      }
    }
  }

  &__button {
    padding-bottom: 10px;

    @include mq('large') {
      padding-bottom: 21px;
    }

    &.caret-icon {
      .aaaem-accordion__icon {
        border: var(--emu-common-border-width-thin) solid
          var(
            --emu-component-lists-accordion-item-header-icon-color-fill-closed-light
          );
        border-radius: 50%;
        flex-shrink: 0;

        &:before,
        &:after {
          height: 40%;
          margin-top: 25%;
        }
      }
    }
  }

  &__title {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    font-weight: var(--emu-common-font-weight-regular);
    color: var(--emu-common-colors-white);
    line-height: var(--emu-common-line-heights-wide-large);

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-xl);
      line-height: 33.6px;
      letter-spacing: -0.84px;
    }
  }
}
