.ns-references {
  padding-right: 20px;
  padding-bottom: 30px;
  padding-left: 20px;

  @include mq('large') {
    padding-right: 85.5px;
    padding-bottom: 70px;
    padding-left: 85.5px;
  }

  &__text {
    font-size: 18px;
    font-weight: var(--emu-common-font-weight-regular);
    line-height: 21.94px;

    @include mq('large') {
      font-size: 24px;
      line-height: 28px; //to match figma
    }

    p {
      margin-bottom: 10px;
    }

    p,
    ol,
    li {
      font-size: inherit;
      line-height: inherit;
      word-break: break-all;

      @include mq('large') {
        word-break: initial;
      }

      a {
        color: inherit;
        text-decoration: none;
        word-wrap: break-word;
      }

      .emphasis {
        display: inline-block;
      }
    }

    ol {
      padding-left: var(--emu-common-spacing-large);
    }
  }
}
