header.experiencefragment {
  position: sticky;
  top: 0;
  margin-bottom: 40px; //to match figma
  z-index: var(--emu-common-other-z-index-header);
  float: none !important; // float is added by aaaem code on aem-Grid classes. Making sure it is overridden

  @include mq('582px') {
    margin-bottom: 25px; //to match figma
  }

  @include mq('large') {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#main-header-wrapper {
  .main-header {
    // to position
    // - mobile navigation menu
    // - 'see full information' section
    position: relative;
    padding-left: 21px;
    padding-top: 20px;
    padding-right: 18px;
    padding-bottom: 20px;
    background-color: var(--emu-common-colors-white);

    @include mq('large') {
      padding-left: var(--emu-common-spacing-large);
      padding-top: 54px;
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: 5px;
      border-bottom: var(--emu-common-border-width-medium) solid #e5e5e5; //color used once
    }

    @include mq('x-large') {
      padding-bottom: 18px;
    }

    &__inner {
      @include mq('large') {
        display: flex;
        align-items: center;
      }

      // push the links to the right
      > .container:last-child {
        @include mq('large') {
          flex: 1;
        }
      }
    }

    &__logo {
      @include mq('large') {
        margin-right: 10px;
      }

      @include mq('xx-large') {
        margin-right: 35px;
      }

      img {
        height: 20px;

        @include mq('large') {
          height: 27px;
        }
      }
    }

    &__toggler-logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .button {
        font-size: 0;
        line-height: 0;
      }
    }

    &__mobile-nav-visibility-toggler {
      padding: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);
      width: 20px;
      height: 20px;
      border-width: var(--emu-common-border-width-none);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/images/hamburger_menu.svg');

      @include mq('large') {
        display: none;
      }

      &.js-toggle-on {
        background-image: url('../../assets/images/close_x.svg');
      }
    }

    &__links {
      display: none;
      // 60px  - height of `.main-header__toggler-logo-container`
      height: calc(
        100vh - 60px - var(--isiBannerHeight, 0) - var(--isi-content-height, 0)
      );
      overflow-y: auto;

      @include mq('large') {
        height: auto;
        display: flex;
        align-items: center;
        overflow-y: visible;
        justify-content: space-between;
      }

      // show the mobile navigation menu
      &.js-toggle-on {
        @include mq(1023px, 'max-width') {
          display: revert;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: var(--emu-semantic-colors-primary-light);
          // to place it above 'see full information' section
          z-index: var(--emu-common-other-z-index-layer);
        }
      }

      // container with resources button
      > .container:nth-child(2) {
        @include mq('large') {
          margin-left: auto;
        }
      }
    }

    &__see-full-information {
      position: absolute; // relative to `.main-header`
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--emu-semantic-colors-primary-dark);
      padding-left: var(--emu-common-spacing-medium);
      padding-top: 5px;
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 5px;

      @include mq('large') {
        left: 32px;
        padding-left: 35px;
        padding-top: 12px;
        padding-right: 35px;
        padding-bottom: 13px;
        max-width: fit-content;
      }

      @include mq('xx-large') {
        margin-left: calc(50vw - 720px);
      }

      > .text {
        margin-left: auto;

        p {
          margin-bottom: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 15px;

          a {
            font-weight: var(--emu-semantic-font-weight-600);

            &,
            &:hover,
            &:focus,
            &:active {
              color: inherit;
              text-decoration: underline;
              -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
            }
          }
        }
      }
    }
  }
}
