html {
  -webkit-text-size-adjust: 100%;
}

// Commenting out, makes fonts less bold
// :root {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
// }

html,
body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

p,
li {
  margin-top: var(--emu-common-spacing-none);
}

li {
  margin-bottom: var(--emu-common-spacing-none);
}

p {
  margin-bottom: 30px;
}

ul,
ol {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: 20px;
}

//to override in iphone
a[href^="tel:"] {
  color: inherit !important;
  text-decoration: none;
  pointer-events: none;
}

main {
  @include max-container;
}

// making sure that the ISI stays hidden when references or footer is in view
.references-in-view,
.footer-in-view {
  [data-component='container-isi-v2'] .isi-container {
    transform: translateY(100%);
  }
}

:root {
  --isi-content-height: 219px;

  &.isi-expanded-once {
      --isi-content-height: var(--emu-common-sizing-none);

      &.isi-expanded {
        --isi-content-height: var(
          --emu-component-containers-isi-open-content-size,
          140px
        );
      }
  }
}
