.emu-mapping-marker {
  color: var(--emu-semantic-colors-primary-500);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  width: auto;
  height: auto;

  &[aria-pressed='true'] {
    span {
      transform: rotate(-90deg);

      svg {
        filter: drop-shadow(-3px 0px 2px #00000040);
      }
    }
  }

  span {
    transition: all var(--emu-common-other-time-duration-instant);
    transform: rotate(0);

    &::before,
    &::after {
      display: none;
    }
  }

  svg {
    margin: var(--emu-common-spacing-none);
    filter: drop-shadow(0 4px 2px #00000040);
  }

  &--active {
    svg {
      color: var(--emu-common-colors-white);

      circle {
        fill: var(--emu-semantic-colors-primary-500);
        stroke: var(--emu-semantic-colors-primary-500);
      }
    }
  }
}

.img-map {
  &__main {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    > .container {
      height: 100%;
      position: absolute;
      top: 0;
    }

    .emu-image-map,
    img {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    z-index: var(--emu-common-other-z-index-layer);
    transition: opacity var(--emu-common-other-time-duration-instant)
      ease-in-out;
    min-width: 130px;

    @include mq('large') {
      transition-delay: var(--emu-common-other-time-delay-none);
      min-width: 309px;
    }

    @include aem-editor-view {
      opacity: 1;
      visibility: visible;
      position: static;
    }

    &--active {
      pointer-events: initial;
      opacity: 1;

      @include mq('large') {
        transition-delay: var(--emu-common-other-time-delay-long);
      }
    }

    &--site {
      transform: translateY(66px);

      @include mq('large') {
        min-width: 440px;
        transform: translateY(0);
      }

      .img-map__content-triangle--left-on-mobile {
        @include mq('1023px', 'max-width') {
          transform: translateX(-50%) translateY(-66px);
        }
      }
    }

    &--design {
      @include mq('large') {
        min-width: 211px;
        transform: translateY(77px);
      }

      .img-map__content-triangle--right-on-desktop {
        @include mq('large') {
          transform: translateX(-50%) translateY(-73px);
        }
      }
    }

    &--port {
      @include mq('large') {
        transform: translateY(132px);
      }

      .img-map__content-triangle--right-on-desktop {
        @include mq('large') {
          transform: translateX(-50%) translateY(-128px);
        }
      }
    }

    &-triangle {
      position: absolute;
      top: -18px;
      bottom: -0.5px;
      left: 50%;
      transform: translateX(-50%);

      &--left-on-mobile {
        @include mq('1023px', 'max-width') {
          position: absolute;
          top: 50%;
          right: -25px;
          bottom: unset;
          left: unset;
          transform: translateX(-50%) translateY(-50%);

          img {
            transform: rotate(-30deg);
          }
        }
      }

      &--right-on-desktop {
        @include mq('large') {
          position: absolute;
          top: 50%;
          bottom: unset;
          left: -4px;
          transform: translateX(-50%) translateY(-50%);

          img {
            transform: rotate(30deg);
          }
        }
      }

      &--bottom-on-desktop {
        @include mq('large') {
          position: absolute;
          top: unset;
          bottom: -13px;
          left: 50%;
          transform: translateX(-50%);

          img {
            transform: rotate(60deg);
          }
        }
      }
    }
  }

  &__card {
    .emu-card {
      &__wrapper {
        padding: var(--emu-common-spacing-none);
      }

      &__title-wrapper {
        display: none;
      }
    }

    &-img-text-container {
      @include mq('large') {
        display: flex;
      }
    }

    &-img {
      margin-bottom: 12.43px;

      //utility class
      &__mx-sm {
        margin-left: 7px;
        margin-right: var(--emu-common-spacing-small);
      }

      //utility class
      &__mx-lg {
        @include mq('large') {
          margin-left: 11px;
          margin-right: 11px;
        }
      }

      //utility class
      &__mb-lg {
        margin-bottom: 21px;
      }

      img {
        margin-left: auto;
        margin-right: auto;

        @include aem-editor-view {
          max-width: 200px;
        }
      }
    }

    &-title {
      margin-bottom: var(--emu-common-spacing-small);

      .cmp-title__text {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        font-weight: var(--emu-semantic-font-weight-500);
        line-height: 14.63px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: 30px;
        }
      }

      &--design {
        padding-left: 7px;
        padding-right: var(--emu-common-spacing-small);
      }

      &--site {
        padding-left: var(--emu-common-spacing-small);
        padding-right: 9px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          margin-bottom: 22px; //to match figma
        }
      }

      &--port {
        padding-left: var(--emu-common-spacing-medium); //to match figma
        padding-right: var(--emu-common-spacing-medium); //to match figma

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    &-desc {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        font-weight: var(--emu-semantic-font-weight-50);
        line-height: var(--emu-semantic-line-heights-narrow-xs);
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 17px; //to match figma
          line-height: 23px; //to match figma
        }
      }

      &--site {
        p {
          text-align: center;

          @include mq('large') {
            text-align: left;
            max-width: 208px;
            margin-right: 20px;
          }
        }
      }
    }

    &--surface {
      padding-top: 13px;
      padding-right: 13px;
      padding-bottom: 13px;
      padding-left: 12px;

      @include mq('large') {
        padding-top: 25px;
        padding-right: 27px; //to match figma
        padding-bottom: 25px; //to match figma
        padding-left: 27px; //to match figma
      }
    }

    &--secure {
      padding-top: 13px;
      padding-right: 7px;
      padding-bottom: 14px;
      padding-left: 7px;

      @include mq('large') {
        padding-top: 47px;
        padding-right: var(--emu-common-spacing-medium); //to match figma
        padding-bottom: 42px; //to match figma
        padding-left: var(--emu-common-spacing-medium); //to match figma
      }
    }

    &--site {
      padding-top: 13px;
      padding-right: 13px;
      padding-bottom: 13px;
      padding-left: 12px;

      @include mq('large') {
        padding-top: 30px;
        padding-right: 26px; //to match figma
        padding-bottom: 17px; //to match figma
        padding-left: 26px; //to match figma
      }
    }

    &--design {
      padding-top: 13px;
      padding-right: 13px;
      padding-bottom: 21px;
      padding-left: 12px;

      @include mq('large') {
        padding-top: 30px;
        padding-right: 26px; //to match figma
        padding-bottom: 30px; //to match figma
        padding-left: 26px; //to match figma
      }
    }

    &--line {
      padding-top: 14px; //to match figma
      padding-right: 11px;
      padding-bottom: 16px; //to match figma
      padding-left: 14px;

      @include mq('large') {
        padding-top: 41px;
        padding-right: 16px; //to match figma
        padding-bottom: 41px; //to match figma
        padding-left: 16px; //to match figma
      }
    }

    &--port {
      padding-top: 12px; //to match figma
      padding-right: 13px;
      padding-bottom: 22px; //to match figma
      padding-left: 12px;

      @include mq('large') {
        max-width: 211px;
        padding-top: 30px;
        padding-right: 18px; //to match figma
        padding-bottom: 27px; //to match figma
        padding-left: 18px; //to match figma
      }
    }
  }
}
