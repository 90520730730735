@font-face {
  font-family: 'Collier-Bold';
  src: url('./assets/fonts/Collier/Collier-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Collier-Book';
  src: url('./assets/fonts/Collier/Collier-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Collier-Extra-Condensed-Italic';
  src: url('./assets/fonts/Collier/Collier-Extra-Condensed-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./assets/fonts/Gotham/Gotham-Bold.otf') format('otf');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('./assets/fonts/Gotham/Gotham-Book.otf') format('otf');
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')
