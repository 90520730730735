#error-page {
  .error {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-medium);
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      padding-top: 140px;
      padding-bottom: 100px;
    }

    &__title {
      .cmp-title__text {
        font-family: var(--emu-semantic-font-families-body);
        color: var(--emu-semantic-colors-primary-400);
        font-size: 96px;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 89px;
        letter-spacing: -0.9px;
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          font-size: 124px;
          line-height: 111px;
          letter-spacing: -1.4px;
          margin-bottom: var(--emu-common-spacing-xl);
        }
      }
    }

    &__description {
      p {
        color: var(--emu-semantic-colors-primary-600);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 28px;
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-xl);
        }
      }

      a {
        color: inherit;
      }
    }

    .button {
      width: fit-content;
      margin-right: auto;
      margin-left: auto;
    }

    &__home-btn {
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);

      .cmp-button__text {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 13px;
      }
    }
  }
}
