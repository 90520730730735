.plyr {
  display: flex;
  justify-content: center;
  align-items: center;
  --plyr-color-main: var(--emu-semantic-colors-primary-500);

  &--stopped {
    .plyr__controls {
      visibility: hidden;
    }
  }

  &__control {
    --plyr-color-main: #00145099;
  }
}
