[data-component='container-isi-v2'] {
  position: relative;
  z-index: var(--emu-common-other-z-index-isi);

  .isi-container {
    border: none;

    .aaaem-isi-container {
      &__isi-content {
        background-color: var(--emu-common-colors-transparent);

        &,
        &.js-expand {
          height: var(--isi-content-height, 0);
        }

        .ns-isi__title {
          @include mq('large') {
            margin-bottom: 10px;
          }
        }

        .ns-isi__warnings-box ul {
          @include mq('large') {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.aaaem-isi-holder {
    //for sticky ISI
    .aaaem-isi-container__isi-content {
      & > div:first-child {
        padding-top: 21px;
        padding-right: 21px;
        padding-bottom: 21px;
        padding-left: 21px;
        @include max-container;

        @include mq('large') {
          padding-top: 24px;
          padding-right: 86px;
          padding-bottom: 24px;
          padding-left: 86px;
        }
      }
    }

    &.js-content-shown {
      //for static ISI
      .aaaem-isi-container__isi-content {
        & > div:first-child {
          padding-top: 30px;
          padding-right: 16.5px;
          padding-bottom: 30px;
          padding-left: 16.5px;

          @include mq('large') {
            padding-top: 100px;
            padding-right: 86px;
            padding-bottom: 100px;
            padding-left: 86px;
          }
        }
      }
    }
  }

  .ns-isi {
    &__container {
      .aaaem-isi-banner {
        padding: var(--emu-common-spacing-none);
        background-color: var(--emu-semantic-colors-primary-700);

        .aaaem-isi-container {
          &__banner-content {
            p {
              color: var(--emu-common-colors-white);
              font-family: var(--emu-semantic-font-families-body);
              font-size: 16px;
              font-weight: var(--emu-semantic-font-weight-600);
              line-height: 19.5px;
              padding-right: var(--emu-common-spacing-large); //to match figma
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('large') {
                font-size: 20px;
                line-height: 24.38px;
              }
            }
          }

          &__button-wrapper {
            width: 100%;
            -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
            @include max-container;

            .cmp-button {
              width: 100%;
              flex-direction: row-reverse;
              justify-content: space-between;
              text-align: left;

              padding-top: 10px;
              padding-right: 15px;
              padding-bottom: 10px;
              padding-left: 15px;

              @include mq('large') {
                padding-top: 9px;
                padding-right: 86px;
                padding-bottom: 9px;
                padding-left: 86px;
              }

              .aaaem-button__icon {
                width: 16px;
                height: 16px;
                position: relative;
                right: 4px;
                align-self: flex-start;
                margin-right: var(--emu-common-spacing-none);

                @include mq('large') {
                  right: -13px;
                  align-self: center;
                }

                &::before,
                &::after {
                  background-color: var(--emu-common-colors-white);
                  height: var(--emu-common-sizing-small);
                }
              }

              .cmp-button__text {
                display: block;
                color: var(--emu-common-colors-white);
                font-family: var(--emu-semantic-font-families-body);
                font-size: 16px;
                line-height: 19.5px;
                font-weight: var(--emu-semantic-font-weight-600);
                margin-right: 34px;
                margin-bottom: var(--emu-common-spacing-none);

                @include mq('large') {
                  font-size: 20px;
                  line-height: var(--emu-common-line-heights-wide-large);
                }
              }
            }
          }
        }
      }

      .aaaem-isi-container {
        &__isi-content {
          // hide in sticky ISI
          .ns-isi__title-main {
            display: none;
          }
        }
      }
    }

    &__content {
      margin-bottom: 15px;

      @include mq('large') {
        margin-bottom: 30px;
      }
    }

    &__title {
      margin-bottom: 15px;

      @include mq('large') {
        margin-bottom: 12px;
      }

      .emu-title__text {
        color: var(--emu-semantic-colors-primary-dark);
        font-family: var(--emu-semantic-font-families-body);
        font-size: 18px;
        font-weight: var(--emu-semantic-font-weight-600);
        line-height: 21.94px;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          letter-spacing: 1.2px; //to match figma
        }
      }

      &-main {
        margin-bottom: 15px;

        @include mq('large') {
          margin-bottom: 28px; //to match figma
        }
      }
    }

    &__info {
      p,
      li {
        font-family: var(--emu-semantic-font-families-body);
        font-size: 18px;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 21.94px;
      }

      p {
        padding-right: 7px;
        margin-bottom: var(--emu-common-spacing-none);
      }

      ul {
        margin-top: 5px;
        padding-left: var(--emu-common-spacing-medium);

        @include mq('large') {
          margin-top: 14px;
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: var(--emu-common-spacing-small);
        }

        b {
          font-weight: var(--emu-semantic-font-weight-600);
        }

        @include mq('large') {
          letter-spacing: 0.1px; //to match figma
        }
      }
    }

    &__warnings-box {
      padding-top: 18px; //to match figma
      padding-right: 9.38px;
      padding-bottom: 20px;
      padding-left: 9.38px;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('large') {
        padding-top: 36px;
        padding-right: 37px;
        padding-bottom: 36px;
        padding-left: 37px;
      }

      .cmp-title__text {
        letter-spacing: normal;
        color: var(--emu-common-colors-black);
      }

      ul {
        padding-left: 18px; //to match figma
      }

      li {
        letter-spacing: 0.3px; //to match figma

        &:not(:last-child) {
          margin-bottom: 11px;
        }

        @include mq('large') {
          letter-spacing: normal;
          margin-bottom: 11px;
        }
      }
    }

    &__contraindications {
      .cmp-title {
        @include mq('large') {
          margin-bottom: 20px;
        }
      }

      ul {
        @include mq('large') {
          margin-top: var(--emu-common-spacing-small); //to match figma
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 6px;

          @include mq('large') {
            margin-bottom: 11px;
          }
        }
      }
    }

    &__additional-warnings {
      li {
        &:not(:last-child) {
          margin-bottom: 6px;

          @include mq('large') {
            margin-bottom: 10px;
          }
        }

        @include mq('large') {
          letter-spacing: 0.05px; //to match figma
        }
      }
    }

    &__precautions {
      ul {
        @include mq('large') {
          margin-top: 15px;
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 5px;

          @include mq('large') {
            margin-bottom: 10px;
          }
        }
      }
    }

    &__adverse-events {
      p {
        &:not(:last-child) {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: 30px;
          }
        }

        a {
          color: var(--emu-semantic-colors-primary-dark);
        }

        .emphasis {
          letter-spacing: 0.9px; //to match figma
          line-height: 25px; //to match figma
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }

    &__indications {
      p {
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          padding-top: 6px; //to match figma
        }
      }

      ul {
        @include mq('large') {
          margin-top: 10px;
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 5px;

          @include mq('large') {
            margin-bottom: 10px;
          }
        }
      }
    }

    &__safety {
      .cmp-title__text {
        p {
          color: inherit;
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          font-weight: inherit;
          line-height: 24.38px;
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            color: var(--emu-semantic-colors-primary-dark);
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            letter-spacing: normal;
          }

          &:not(:last-child) {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 5px;

          @include mq('large') {
            margin-bottom: 10px;
          }
        }
      }
    }

    &__warnings {
      li {
        &:not(:last-child) {
          margin-bottom: 6px;

          @include mq('large') {
            margin-bottom: 10px;
          }
        }
      }

      @include mq('large') {
        margin-bottom: 40px;
      }
    }

    &__adverse-reactions {
      p {
        a {
          color: var(--emu-semantic-colors-primary-dark);
        }

        &:not(:last-child) {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-large);
          }
        }
      }
    }
  }
}

.js-modal-opened {
  .isi-scrollable {
    .isi-container {
      z-index: var(--emu-common-other-z-index-isi);
    }
  }
}
